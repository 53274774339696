<template>
  <!-- FAQ Section -->
  <section class="section section-blogs">
    <div class="container-fluid">
      <!-- Section Header -->
      <div class="section-header text-center">
        <h2>
          {{ $t("homePage.medicinesSection.title") }}
        </h2>
        <p class="sub-title">
          {{ $t("homePage.medicinesSection.description") }}
        </p>
      </div>
      <!-- /Section Header -->
      <div class="row text-center">
        <div v-for="(start, last) in [0, 1, 2, 3]" :key="last" class="col-12 col-md-3 col-sm-6 col-lg-3 m-0 p-0">
          <ul class="list-group">
            <li class="list-group-item"
              v-for="(medicine, index) in sortedMedicines.slice(start * (top_medicines.length / 4), (start + 1) * (top_medicines.length / 4))"
              :key="index">
              <router-link @click="moveUp" :to="medicine.route" class="ml-1">{{ medicine.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- /FAQ Section -->
</template>

<script>
export default {
  data() {
    const route_faq = '/faq-medicines'
    const top_medicines = [
      { route: route_faq + "/lisinopril", name: "Lisinopril (for high blood pressure)", component: 'Lisinopril' },
      { route: route_faq + "/levothyroxine", name: "Levothyroxine (for hypothyroidism)", component: 'Levothyroxine' },
      { route: route_faq + "/atorvastatin", name: "Atorvastatin (for cholesterol)", component: 'Atorvastatin' },
      { route: route_faq + "/metformin", name: "Metformin (for diabetes)", component: 'Metformin' },
      { route: route_faq + "/amlodipine", name: "Amlodipine (for high blood pressure)", component: 'Amlodipine' },
      { route: route_faq + "/omeprazole", name: "Omeprazole (for acid reflux)", component: 'Omeprazole' },
      { route: route_faq + "/simvastatin", name: "Simvastatin (for cholesterol)", component: 'Simvastatin' },
      { route: route_faq + "/hydrocodone-acetaminophen", name: "Hydrocodone/Acetaminophen (pain relief)", component: 'Hydrocodone-Acetaminophen' },
      { route: route_faq + "/losartan", name: "Losartan (for high blood pressure)", component: 'Losartan' },
      { route: route_faq + "/azithromycin", name: "Azithromycin (antibiotic)", component: 'Azithromycin' },
      { route: route_faq + "/albuterol", name: "Albuterol (for asthma)", component: 'Albuterol' },
      { route: route_faq + "/metoprolol", name: "Metoprolol (for high blood pressure)", component: 'Metoprolol' },
      { route: route_faq + "/hydrochlorothiazide", name: "Hydrochlorothiazide (diuretic)", component: 'Hydrochlorothiazide' },
      { route: route_faq + "/sertraline", name: "Sertraline (for depression)", component: 'Sertraline' },
      { route: route_faq + "/gabapentin", name: "Gabapentin (for nerve pain)", component: 'Gabapentin' },
      { route: route_faq + "/amoxicillin", name: "Amoxicillin (antibiotic)", component: 'Amoxicillin' },
      { route: route_faq + "/fluticasone", name: "Fluticasone (inhaled corticosteroid)", component: 'Fluticasone' },
      { route: route_faq + "/warfarin", name: "Warfarin (blood thinner)", component: 'Warfarin' },
      { route: route_faq + "/aspirin", name: "Aspirin (blood thinner, pain relief)", component: 'Aspirin' },
      { route: route_faq + "/citalopram", name: "Citalopram (for depression)", component: 'Citalopram' },
      { route: route_faq + "/duloxetine", name: "Duloxetine (for anxiety and depression)", component: 'Duloxetine' },
      { route: route_faq + "/crestor", name: "Crestor (for cholesterol)", component: 'Crestor' },
      { route: route_faq + "/escitalopram", name: "Escitalopram (for depression and anxiety)", component: 'Escitalopram' },
      { route: route_faq + "/prednisone", name: "Prednisone (steroid)", component: 'Prednisone' },
      { route: route_faq + "/pantoprazole", name: "Pantoprazole (for acid reflux)", component: 'Pantoprazole' },
      { route: route_faq + "/tramadol", name: "Tramadol (pain relief)", component: 'Tramadol' },
      { route: route_faq + "/ibuprofen", name: "Ibuprofen (pain relief)", component: 'Ibuprofen' },
      { route: route_faq + "/doxycycline", name: "Doxycycline (antibiotic)", component: 'Doxycycline' },
      { route: route_faq + "/tadalafil", name: "Tadalafil (for erectile dysfunction)", component: 'Tadalafil' },
      { route: route_faq + "/meloxicam", name: "Meloxicam (nonsteroidal anti-inflammatory drug)", component: 'Meloxicam' },
      { route: route_faq + "/venlafaxine", name: "Venlafaxine (for depression)", component: 'Venlafaxine' },
      { route: route_faq + "/metronidazole", name: "Metronidazole (antibiotic)", component: 'Metronidazole' },
      { route: route_faq + "/clopidogrel", name: "Clopidogrel (blood thinner)", component: 'Clopidogrel' },
      { route: route_faq + "/quetiapine", name: "Quetiapine (for bipolar disorder)", component: 'Quetiapine' },
      { route: route_faq + "/tamsulosin", name: "Tamsulosin (for enlarged prostate)", component: 'Tamsulosin' },
      { route: route_faq + "/carvedilol", name: "Carvedilol (for heart failure)", component: 'Carvedilol' },
      { route: route_faq + "/mirtazapine", name: "Mirtazapine (for depression)", component: 'Mirtazapine' },
      { route: route_faq + "/bupropion", name: "Bupropion (for depression and smoking cessation)", component: 'Bupropion' },
      { route: route_faq + "/lorazepam", name: "Lorazepam (anxiety)", component: 'Lorazepam' },
      { route: route_faq + "/cephalexin", name: "Cephalexin (antibiotic)", component: 'Cephalexin' },
      { route: route_faq + "/naproxen", name: "Naproxen (pain relief)", component: 'Naproxen' },
      { route: route_faq + "/oxycodone", name: "Oxycodone/Acetaminophen (pain relief)", component: 'Oxycodone-Acetaminophen' },
      { route: route_faq + "/furosemide", name: "Furosemide (diuretic)", component: 'Furosemide' },
      { route: route_faq + "/celecoxib", name: "Celecoxib (nonsteroidal anti-inflammatory drug)", component: 'Celecoxib' },
      { route: route_faq + "/montelukast", name: "Montelukast (for allergies and asthma)", component: 'Montelukast' },
      { route: route_faq + "/benzonatate", name: "Benzonatate (cough suppressant)", component: 'Benzonatate' },
      { route: route_faq + "/ranitidine", name: "Ranitidine (acid reducer)", component: 'Ranitidine' },
      { route: route_faq + "/esomeprazole", name: "Esomeprazole (for acid reflux)", component: 'Esomeprazole' },
      { route: route_faq + "/nexium", name: "Nexium (for acid reflux)", component: 'Nexium' },
      { route: route_faq + "/ezetimibe-simvastatin", name: "Ezetimibe/Simvastatin (for cholesterol)", component: 'Ezetimibe-Simvastatin' }
    ]

    return {
      top_medicines,
      medicines: [...top_medicines,
        // { route: route_faq + "/", name: "", component: '' }
      ]
    }
  },
  methods: {
    moveUp() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  computed: {
    sortedMedicines() {
      return this.top_medicines.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }
  }
};
</script>
<style scoped>
.btn-text {
  color: white;
}

.icon-text {
  font-size: 65px;
  margin-bottom: 20px;
}
</style>
