<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb30></breadcrumb30>
    <!-- Page Content -->
    <div
      class="content"
      style="min-height: 299.6px !important;"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <h5>Coming Soon</h5>
          </div>
        </div>
      </div>

    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>