<template>
  <!-- FAQ Section -->
  <section class="section section-blogs">
    <div class="container-fluid">
      <!-- Section Header -->
      <div class="section-header text-center">
        <h2>
          {{ $t("homePage.faqSection.title") }}
        </h2>
        <p class="sub-title">
          {{ $t("homePage.faqSection.description") }}
        </p>
      </div>
      <!-- /Section Header -->
      <div class="row blog-grid-row">
        <div class="col-md-6 col-lg-3 col-sm-12">
          <!-- FAQ Sector -->
          <router-link to="/faq-refill" @click="moveUp">
            <div class="blog grid-blog text-center">
              <div class="blog-content">
                <i class="fas fa-prescription icon-text"></i>
                <h3 class="blog-title">
                  {{ $t("homePage.faqSection.title1") }}
                </h3>
              </div>
            </div>
          </router-link>
          <!-- /FAQ Sector -->
        </div>
        <div class="col-md-6 col-lg-3 col-sm-12">
          <!-- FAQ Sector -->
          <router-link to="/faq-price" @click="moveUp">
            <div class="blog grid-blog text-center">
              <div class="blog-content">
                <i class="fas fa-dollar-sign icon-text"></i>
                <h3 class="blog-title">
                  {{ $t("homePage.faqSection.title2") }}
                </h3>
              </div>
            </div>
          </router-link>
          <!-- /FAQ Sector -->
        </div>
        <div class="col-md-6 col-lg-3 col-sm-12">
          <!-- FAQ Sector -->
          <router-link to="/faq-pharmacy" @click="moveUp">
            <div class="blog grid-blog text-center">
              <div class="blog-content">
                <i class="fas fa-store-alt icon-text"></i>
                <h3 class="blog-title">
                  {{ $t("homePage.faqSection.title3") }}
                </h3>
              </div>
            </div>
          </router-link>
          <!-- /FAQ Sector -->
        </div>
        <div class="col-md-6 col-lg-3 col-sm-12">
          <!-- FAQ Sector -->
          <router-link to="/faq-bill" @click="moveUp">
            <div class="blog grid-blog text-center">
              <div class="blog-content">
                <i class="fas fa-file-invoice-dollar icon-text"></i>
                <h3 class="blog-title">
                  {{ $t("homePage.faqSection.title4") }}
                </h3>
              </div>
            </div>
          </router-link>
          <!-- /FAQ Sector -->
        </div>
      </div>
    </div>
  </section>
  <!-- /FAQ Section -->
</template>

<script>
export default {
  methods: {
    moveUp() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style scoped>
.btn-text {
  color: white;
}

.icon-text {
  font-size: 65px;
  margin-bottom: 20px;
}
</style>
