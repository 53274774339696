<template>
  <div>
    <div class="card mb-2">
      <div class="card-body p-2" v-if="prescription.id">
        <div class="row">
          <div class="col-6">
            <h4>Rx Request ID: {{ prescription.id }}</h4>
            <span style="font-size: 12px"
              >Request Date:
              {{ new Date(prescription.created_at).toDateString() }}</span
            >
            <p style="font-size: 12px">
              Last Updated At:
              {{ new Date(prescription.created_at).toLocaleString() }}
            </p>
          </div>
          <div class="col-6 text-right">
            <h4 :class="getStatusColor(prescription.status)">
              Status:
              <span
                v-if="
                  prescription.status === 'Accepted' &&
                  prescription.is_payment_completed
                "
              >
                Provider Approval Pending
              </span>
              <span v-else>
                {{ prescription.status }}
              </span>
            </h4>
            <p style="font-size: 12px" v-if="prescription.payment">
              <span v-if="prescription.payment.status === 'COMPLETED'">
                Payment Status:
                <strong>{{ prescription.payment.status }}</strong></span
              >
              <a
                v-else
                class="btn btn-sm btn-info"
                href="javascript:void();"
                @click="$router.push(`/patient/billing/${prescription.id}`)"
                >Pay Fees</a
              >
              <br />
              <span v-if="prescription.payment.status === 'COMPLETED'">
                Transaction ID:
                <strong>{{ prescription.payment.trans_id }}</strong>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- REFILL DETAILS -->
    <div class="row" style="font-size: 12px">
      <div class="col-md-4 col-12" id="second-row">
        <div class="accordion" id="prescription-accordion">
          <!-- Patient Details -->
          <div class="card mb-0">
            <div class="card-header" id="patient-info-accordion">
              <h3 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#patient-info-collapse"
                  aria-expanded="true"
                  aria-controls="patient-info-collapse"
                >
                  <i class="fas fa-user mr-1"></i> Patient Information
                </button>
              </h3>
            </div>

            <div
              id="patient-info-collapse"
              class="collapse show"
              aria-labelledby="patient-info-accordion"
              data-parent="#prescription-accordion"
            >
              <div class="card-body">
                <p>
                  <span class="text-muted">Name: </span
                  >{{ $store.getters.user.first_name }}
                  {{ $store.getters.user.last_name }}
                </p>
                <p>
                  <span class="text-muted">Email:</span>
                  {{ $store.getters.user.email }}
                </p>
                <p>
                  <span class="text-muted">Mobile:</span>
                  {{ $store.getters.user.primary_phone }}
                </p>
                <p v-if="$store.getters.user.address1">
                  <span class="text-muted">Address: </span
                  >{{ $store.getters.user.address1 }},
                  {{ $store.getters.user.address2 }},
                  {{ $store.getters.user.city }},
                  {{ $store.getters.user.state }} -
                  {{ $store.getters.user.zip_code }}
                </p>
              </div>
            </div>
          </div>

          <!-- Pharmacy Details -->
          <div class="card mb-0" v-if="prescription.pharmacy">
            <div class="card-header" id="pharmacy-info-accordion">
              <h3 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#pharmacy-info-collapse"
                  aria-expanded="false"
                  aria-controls="pharmacy-info-collapse"
                >
                  <i class="fas fa-hospital mr-1"></i> Pharmacy Details
                </button>
              </h3>
            </div>
            <div
              id="pharmacy-info-collapse"
              class="collapse"
              aria-labelledby="pharmacy-info-accordion"
              data-parent="#prescription-accordion"
            >
              <div class="card-body">
                <p>
                  <span class="text-muted">Store ID:</span>
                  {{ prescription.pharmacy.pharmacy_id }}
                </p>
                <p>
                  <span class="text-muted">Store Name: </span
                  >{{ prescription.pharmacy.pharmacy_name }}
                </p>
                <p>
                  <span class="text-muted">Phone:</span>
                  {{ prescription.pharmacy.phone }}
                </p>
                <p v-if="prescription.pharmacy.address">
                  <span class="text-muted">Address:</span>
                  {{ prescription.pharmacy.address1 }},
                  {{ prescription.pharmacy.address2 }},
                  {{ prescription.pharmacy.address.addressLine3 }},
                  {{ prescription.pharmacy.city }},
                  {{ prescription.pharmacy.state }} -
                  {{ prescription.pharmacy.address.zipCode }}
                </p>
              </div>
            </div>
          </div>

          <!-- Observations and Symptoms -->
          <div class="card mb-0" v-if="prescription.observations">
            <div class="card-header" id="health-info-accordion">
              <h3 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#health-info-collapse"
                  aria-expanded="false"
                  aria-controls="health-info-collapse"
                >
                  <i class="fas fa-heart mr-1"></i> Health Information
                </button>
              </h3>
            </div>
            <div
              id="health-info-collapse"
              class="collapse"
              aria-labelledby="health-info-accordion"
              data-parent="#prescription-accordion"
            >
              <div class="card-body">
                <p>
                  <span class="text-muted">Medical History:</span>
                  {{ prescription.observations.history }}
                </p>
                <p>
                  <span class="text-muted">Known Allergies:</span>
                  {{ prescription.observations.allergies }}
                </p>
                <p>
                  <span class="text-muted">Symptoms / Reason :</span>
                  {{ prescription.observations.symptoms }}
                </p>
              </div>
            </div>
          </div>

          <!-- Assigned Provider Details -->
          <div class="card mb-0" v-if="prescription.provider">
            <div class="card-header" id="provider-info-accordion">
              <h3 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#provider-info-collapse"
                  aria-expanded="false"
                  aria-controls="provider-info-collapse"
                >
                  <i class="fas fa-id-card mr-1"></i> Provider Information
                </button>
              </h3>
            </div>
            <div
              id="provider-info-collapse"
              class="collapse"
              aria-labelledby="provider-info-accordion"
              data-parent="#prescription-accordion"
            >
              <div class="card-body">
                <p>
                  <span class="text-muted">Name:</span>
                  {{ prescription.provider.first_name }}
                  {{ prescription.provider.last_name }}
                </p>
                <p>
                  <span class="text-muted">Description: </span
                  >{{ prescription.provider.description }}
                </p>
              </div>
            </div>
          </div>

          <!-- Assigned Provider Details -->
          <div class="card mb-0" v-if="prescription.payment">
            <div class="card-header" id="payment-info-accordion">
              <h3 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#payment-info-collapse"
                  aria-expanded="false"
                  aria-controls="payment-info-collapse"
                >
                  <i class="fas fa-dollar mr-1"></i> Payment Information
                </button>
              </h3>
            </div>
            <div
              id="payment-info-collapse"
              class="collapse"
              aria-labelledby="payment-info-accordion"
              data-parent="#prescription-accordion"
            >
              <div class="card-body">
                <p>
                  <span class="text-muted">Provider Fees:</span>
                  ${{ prescription.payment.amount.toFixed(2) }}
                  {{ prescription.payment.currency }}
                </p>
                <p>
                  <span class="text-muted">Payment Status: </span
                  >{{ prescription.payment.status }}
                </p>
                <p>
                  <span class="text-muted">Transaction ID: </span
                  >{{ prescription.payment.trans_id }}
                </p>
                <p>
                  <span class="text-muted">Date / Time: </span
                  >{{
                    new Date(prescription.payment.created_at).toLocaleString()
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Medicines Prescribed -->
      <div class="col-md-8 col-12" id="first-row">
        <div v-if="prescription.medicines">
          <div
            class="card mb-2"
            v-for="(medicine, i) in prescription.medicines"
            :key="i"
          >
            <div class="card-header">
              <h5>{{ i + 1 }} . {{ medicine.medicine_name }}</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-2 col-lg-2 col-xl-2">
                  <h6>
                    <strong>Quantity:</strong> {{ medicine.quantity }}
                    {{ medicine.unit_abbreviation }}
                  </h6>
                </div>
                <div class="col-12 col-md-2 col-lg-2 col-xl-2">
                  <h6><strong>Refills:</strong> {{ medicine.refills }}</h6>
                </div>
                <div class="col-12 col-md-2 col-lg-2 col-xl-2">
                  <h6>
                    <strong>Supply Days:</strong> {{ medicine.supply_days }}
                  </h6>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                  <h6>
                    <strong>Directions:</strong> {{ medicine.directions }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="table-responsive">
                <table class="table table-hover table-center">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Rx Name</th>
                      <th>Qty</th>
                      <th>Unit</th>
                      <th>Refills</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(medicine, i) in prescription.medicines"
                      :key="i"
                    >
                      <td>1.</td>
                      <td>
                        <p style="width: 30px; word-wrap: break-word">
                          {{ medicine.medicine_name }}
                        </p>
                        <span style="font-size: 10px"
                          >({{ medicine.ndc }})</span
                        >
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          :value="medicine.quantity"
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          :value="medicine.unit_id"
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          :value="medicine.refills"
                          disabled
                        />
                      </td>
                      <td>
                        <a
                          href="#"
                          class="btn bg-danger-light trash"
                        ><i class="far fa-trash-alt"></i></a>
                      </td> -->
        <!-- </tr>
                  </tbody>
                </table>
              </div> -->

        <div class="m-5 text-center" v-else>
          <div class="card card-table">
            <div class="card-body">
              <img
                src="../../assets/img/waiting.png"
                alt="waiting"
                width="300"
              />
              <h5 class="mt-3">Waiting for provider's response...</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prescription: Object,
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case "Pending":
          return "btn bg-secondary-light ml-2";

        case "InProgress":
          return "btn bg-warning-light ml-2";

        case "Completed":
          return "btn bg-success-light ml-2";

        case "Accepted":
          return "btn bg-success-light ml-2";

        case "Cancelled":
          return "btn bg-danger-light ml-2";

        default:
          return "btn bg-primary-light ml-2";
      }
    },
  },
};
</script>

<style scoped>
#first-row {
  order: 1;
}

#second-row {
  order: 1;
}
</style>
