<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <breadcrumb40></breadcrumb40>

    <section class="section home-tile-section">
      <div class="container-fluid">
        <div class="section-header text-center">
          <h2>{{ $t("homePage.faqSection.faqPharmacy.title") }}</h2>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="bd-example" data-example-id="">
              <div id="accordion" role="tablist" aria-multiselectable="true">
                <div class="card">
                  <div class="card-header" role="tab" id="headingOne">
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                        <h3>
                          {{
                            $t("homePage.faqSection.faqPharmacy.pharmacyQue1")
                          }}
                        </h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    aria-expanded="false"
                    style=""
                  >
                    <div class="card-block">
                      {{ $t("homePage.faqSection.faqPharmacy.pharmacyAns1") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <layout-footer class="footer"></layout-footer>
  </div>
</template>
<style scoped>
.card-block {
  padding: 20px;
}
</style>
