<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb6></breadcrumb6>

    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickyside">
              <sidebar1></sidebar1>
            </div>
          </div>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <!-- Profile Settings Form -->
                <form @submit.prevent="saveProfileDetails">
                  <div class="row form-row">
                    <div class="col-12 col-md-12">
                      <div class="form-group">
                        <div class="change-avatar">
                          <div class="upload-img">
                            <div class="change-photo-btn">
                              <span
                                ><i class="fa fa-upload"></i>
                                {{ $t("patient.profileSetting.button1") }}</span
                              >
                              <input
                                type="file"
                                class="upload"
                                id="profile_image"
                              />
                            </div>
                            <small class="form-text text-muted">
                              {{
                                $t("patient.profileSetting.description1")
                              }}</small
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{
                            $t("patient.profileSetting.firstNameLabel")
                          }}</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="firstName"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{
                            $t("patient.profileSetting.lastNameLabel")
                          }}</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="lastName"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{
                            $t("patient.profileSetting.dateOfBirthLabel")
                          }}</label
                        >
                        <Datepicker
                          :lowerLimit="dateLowerLimit"
                          :upperLimit="dateUpperLimit"
                          inputFormat="MM-dd-yyyy"
                          class="form-control"
                          v-model="dob"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{ $t("patient.profileSetting.genderLabel") }}</label
                        >
                        <select
                          v-model="gender"
                          class="form-control select"
                          required
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Prefer not to answer</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{ $t("patient.profileSetting.weightLabel") }}</label
                        >
                        <input
                          type="number"
                          min="0"
                          max="800"
                          class="form-control"
                          name="weight"
                          v-model="weight"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{ $t("patient.profileSetting.heightLabel") }}</label
                        >
                        <input
                          type="number"
                          min="0"
                          max="500"
                          v-model="height"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{ $t("patient.profileSetting.emailIdLabel") }}</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          v-model="$store.getters.user.email"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{ $t("patient.profileSetting.mobileLabel") }}</label
                        >
                        <input
                          type="text"
                          v-model="$store.getters.user.primary_phone"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{
                            $t("patient.profileSetting.address1Label")
                          }}</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="addressLine1"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{
                            $t("patient.profileSetting.address2lLabel")
                          }}</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="addressLine2"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{ $t("patient.profileSetting.stateLabel") }}</label
                        >
                        <select
                          class="form-control select"
                          v-model="state"
                          required
                          @change="fetchCities()"
                        >
                          <option
                            v-for="(state, i) in statesData"
                            :key="i"
                            :value="state.iso2"
                          >
                            {{ state.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{ $t("patient.profileSetting.cityLabel") }}</label
                        >
                        <select
                          class="form-control select"
                          v-model="city"
                          required
                          tabindex="-1"
                          aria-hidden="true"
                        >
                          <option
                            v-for="(c, i) in citiesData"
                            :key="i"
                            :value="c.name"
                          >
                            {{ c.name }}
                          </option>
                        </select>
                        <!-- <input
                          class="form-control select"
                          tabindex="-1"
                          aria-hidden="true"
                          v-model="city"
                          required
                        /> -->
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{ $t("patient.profileSetting.postalLabel") }}</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="zipCode"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label>
                          {{ $t("patient.profileSetting.countryLabel") }}</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          value="United States"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button type="submit" class="btn btn-primary submit-btn">
                      {{ $t("patient.profileSetting.button2") }}
                    </button>
                  </div>
                </form>
                <!-- /Profile Settings Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import common from "../../mixins/common";
import Datepicker from "vue3-datepicker";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  data() {
    return {
      dateLowerLimit: new Date(1920, 0, 1),
      dateUpperLimit: new Date(),
      firstName: "",
      lastName: "",
      gender: "",
      dob: new Date(),
      height: "",
      weight: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      statesData: [],
      citiesData: [],
      photo: null,
    };
  },
  components: {
    Datepicker,
  },
  async mounted() {
    this.showLoader(true);
    this.statesData = await this.getStateList();

    // Personal details
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.gender = this.$store.getters.user.gender;
    this.dob = new Date(this.$store.getters.user.dob);
    this.height = this.$store.getters.user.height;
    this.weight = this.$store.getters.user.weight;

    // Address parsing
    this.addressLine1 = this.$store.getters.user.address1;
    this.addressLine2 = this.$store.getters.user.address2;
    this.zipCode = this.$store.getters.user.zip_code;

    this.profileImage = this.$store.getters.user.profile_image_url;
    this.state = this.$store.getters.user.state;

    if (this.state !== "") await this.fetchCities(this.state);
    this.city = this.$store.getters.user.city;

    let self = this;
    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = async function (e) {
          await self.savePhoto();
          self.profileImage = self.$store.getters.profile_image_url;
          self.$router.go();
        };

        reader.readAsDataURL(input.files[0]);
        self.photo = input.files[0];
      }
    }

    $("#profile_image").change(function () {
      readURL(this);
    });

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "saveUserProfilePhoto", "showLoader"]),
    async fetchCities() {
      this.showLoader(true);
      this.citiesData = await this.getCityList(this.state);
      this.showLoader(false);
    },

    async savePhoto() {
      try {
        this.showLoader(true);
        let formData = new FormData();
        formData.append("photo", this.photo);
        formData.append("id", this.$store.getters.user.id);
        formData.append("currentRegistrationStep", 0);
        await this.saveUserProfilePhoto(formData);
        Swal.fire("Profile Picture Updated!", "", "success");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Ooops!", e.response.data.message, "error");
        console.error(e);
      }
    },

    async saveProfileDetails() {
      try {
        this.showLoader(true);
        const formated = this.formatDate(this.dob);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          first_name: this.firstName,
          last_name: this.lastName,
          dob: formated,
          gender: this.gender,
          weight: this.weight,
          height: this.height,
          address1: this.addressLine1,
          address2: this.addressLine2,
          state: this.state,
          city: this.city,
          zip_code: this.zipCode,
        });

        Swal.fire("Profile Updated!", "", "success");

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Ooops!", e.response.data.message, "error");
        console.error(e);
      }
    },
  },
};
</script>
