<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4>Rx Request ID: {{ prescriptionDetails.id }} - Select Pharmacy</h4>
        </div>
      </div>
      <hr />
      <!-- SEARCH INPUT -->
      <div class="row" v-if="pharmacyResults.length === 0">
        <div class="col-12">
          <div class="banner-wrapper-pharmacy">
            <!-- Search -->
            <div class="mt-3">
              <!-- PHARMACY NAME SEARCH -->
              <div class="row">
                <div class="col-12">
                  <small>Search by Pharmacy Name</small>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      class="form-control form-control-xs"
                      placeholder="Ex: Shollenberger Pharmacy"
                      v-model="searchName"
                    />
                  </div>
                </div>
              </div>

              <!-- CITY SEARCH -->
              <div class="row">
                <div class="col-12">
                  <small>Search by City</small>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      class="form-control form-control-xs"
                      placeholder="Ex: Atlanta, New York"
                      v-model="searchCity"
                    />
                  </div>
                </div>
              </div>

              <!-- STATE SEARCH -->
              <div class="row">
                <div class="col-12">
                  <small>Search by State Code</small>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      class="form-control form-control-xs"
                      placeholder="Ex: GA, NY"
                      v-model="searchState"
                    />
                  </div>
                </div>
              </div>

              <!-- ZIP CODE SEARCH -->
              <div class="row">
                <div class="col-12">
                  <small>Search by Zip Code</small>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <input
                      type="number"
                      class="form-control form-control-xs"
                      placeholder="Ex: 60601, 85001"
                      v-model="searchZipCode"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12" v-show="specialtyFilter">
                <small>Select Specialty</small>
              </div>
              <div class="col-12" v-show="specialtyFilter">
                <div class="form-group mb-3">
                  <select v-model="searchSpecialty" class="form-control form-control-xs">
                    <option value="2048" selected>EPCS</option>
                    <option value="64">24 Hour Pharmacy</option>
                    <option value="32">Long Term Care</option>
                    <option value="1">Mail Order</option>
                    <option value="8">Retail</option>
                    <option value="16">Specialty Pharmacy</option>
                  </select>
                  <span class="form-text text-muted"
                    >Ex: EPCS, Mail Order, Retail, 24 Hour Pharmacy</span
                  >
                </div>
              </div>
              <div class="col-12 p-4 text-right">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    v-model="specialtyFilter"
                  />
                  <label class="form-check-label mt-1" for="flexCheckDefault">
                    Filter by Specialty
                  </label>
                </div>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-12">
                <button @click="startSearching" class="btn btn-primary mt-0 btn-block">
                  <i class="fas fa-search"></i> <span>Search Pharmacy</span>
                </button>
              </div>
            </div>
            <!-- /Search -->
          </div>
        </div>
      </div>

      <!-- SEARCH RESULTS -->
      <div v-else style="height: 600px; overflow: auto">
        <div class="row">
          <div class="col-md-10">
            <div class="banner-header">
              <h4>
                Great! We've Found
                {{ filteredPharmacyResults.length }} Pharmacies Near You
              </h4>
            </div>
          </div>
          <div class="col-md-2">
            <button @click="restartSearch" class="btn btn-sm btn-outline-info btn-block">
              Search Again
            </button>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12 text-center">
            <strong class="m-3">Filter by:</strong>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="specialtyFilter"
                v-model="specialtyCheckFilter"
              />
              <label class="form-check-label" for="specialtyFilter">Specialty</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="mailOrderFilter"
                v-model="mailOrderFilter"
              />
              <label class="form-check-label" for="mailOrderFilter">Mail Order</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="retailFilter"
                v-model="retailFilter"
              />
              <label class="form-check-label" for="retailFilter">Retail</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="epcsFilter"
                v-model="epcsFilter"
              />
              <label class="form-check-label" for="epcsFilter">EPCS</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="24HourFilter"
                v-model="twentyFourHourFilter"
              />
              <label class="form-check-label" for="24HourFilter">24 Hour</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="longTermFilter"
                v-model="longTermFilter"
              />
              <label class="form-check-label" for="longTermFilter">Long Term Care</label>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="order-2 col-md-12 order-md-2">
            <!-- Doctor Widget -->
            <div
              class="card"
              v-for="(pharmacy, index) in filteredPharmacyResults"
              :key="index"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="doctor-widget">
                      <div class="doc-info-left">
                        <div class="doctor-img1">
                          <img
                            :src="getImage('none')"
                            width="100"
                            class="img-fluid"
                            alt="Pharmacy Image"
                          />
                        </div>
                        <div class="doc-info-cont">
                          <h4 class="doc-name mb-2">
                            {{ pharmacy.StoreName }} - {{ pharmacy.PharmacyId }}
                          </h4>
                          <div class="rating mb-2 mapgridrating1">
                            <span class="d-inline-block average-rating">{{
                              getStoreAddress(pharmacy)
                            }}</span>
                          </div>
                          <div class="clinic-details">
                            <div class="clini-infos">
                              <p class="doc-location mb-2">
                                <i class="fas fa-phone-volume mr-1"></i>
                                Phone: {{ pharmacy.PrimaryPhone }}
                                <i class="fas fa-fax ml-3 mr-1"></i> Fax:
                                {{ pharmacy.PrimaryFax }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-3">
                    <h5>Supported Types:</h5>
                    <span
                      v-if="!pharmacy.PharmacySpecialties"
                      class="badge badge-dark mr-2"
                      >NONE</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf('Retail') >= 0
                      "
                      class="badge badge-info mr-2"
                      >RETAIL</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf('Mail Order') >= 0
                      "
                      class="badge badge-info mr-2"
                      >MAIL ORDER</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf('EPCS') >= 0
                      "
                      class="badge badge-info mr-2"
                      >EPCS</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf('Specialty Pharmacy') >= 0
                      "
                      class="badge badge-info mr-2"
                      >SPECIALTY</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf('Long-Term Care Pharmacy') >=
                          0
                      "
                      class="badge badge-info mr-2"
                      >LONG-TERM CARE</span
                    >
                  </div>
                  <div class="col-3">
                    <div class="clinic-booking">
                      <a
                        href="javascript:void();"
                        class="apt-btn"
                        @click="savePharmacy(pharmacy)"
                        >Select Pharmacy</a
                      >
                      <a
                        :href="`https://www.google.com/maps/@${pharmacy.Latitude},${pharmacy.Longitude},15z`"
                        target="blank"
                        class="view-pro-btn"
                        >See on Google Maps</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Doctor Widget -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "../../mixins/common";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
const images = require.context("../../assets/img/stores/", false, /\.png$|\.jpg$/);

export default {
  props: {
    prescriptionDetails: Object,
  },
  mixins: [commonMixin],
  data() {
    return {
      searchName: "",
      searchCity: "",
      searchState: "",
      searchZipCode: "",
      searchSpecialty: "",
      specialtyFilter: false,
      pharmacyResults: [],
      filteredPharmacyResults: [],
      searchOption: "name",
      specialtyCheckFilter: true,
      epcsFilter: true,
      mailOrderFilter: true,
      twentyFourHourFilter: true,
      longTermFilter: true,
      retailFilter: true,
    };
  },
  watch: {
    specialtyFilter: function () {
      if (!this.specialtyFilter) this.searchSpecialty = null;
    },
    specialtyCheckFilter: function () {
      this.filterData();
    },
    epcsFilter: function () {
      this.filterData();
    },
    mailOrderFilter: function () {
      this.filterData();
    },
    twentyFourHourFilter: function () {
      this.filterData();
    },
    longTermFilter: function () {
      this.filterData();
    },
    retailFilter: function () {
      this.filterData();
    },
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    savePharmacy(pharmacyDetails) {
      this.$emit(
        "submit",
        JSON.stringify({
          pharmacy_id: pharmacyDetails.PharmacyId,
          pharmacy_name: pharmacyDetails.StoreName,
          address1: pharmacyDetails.Address1,
          address2: pharmacyDetails.Address2,
          city: pharmacyDetails.City,
          state: pharmacyDetails.State,
          zip_code: pharmacyDetails.ZipCode,
          phone: pharmacyDetails.PrimaryPhone,
        })
      );
    },
    filterData() {
      const retails = this.pharmacyResults.filter(
        (p) => p.PharmacySpecialties && p.PharmacySpecialties.indexOf("Retail") > -1
      );
      const specialty = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties &&
          p.PharmacySpecialties.indexOf("Specialty Pharmacy") > -1
      );
      const mailOrders = this.pharmacyResults.filter(
        (p) => p.PharmacySpecialties && p.PharmacySpecialties.indexOf("Mail Order") > -1
      );
      const epcs = this.pharmacyResults.filter(
        (p) => p.PharmacySpecialties && p.PharmacySpecialties.indexOf("EPCS") > -1
      );
      const longTerm = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties &&
          p.PharmacySpecialties.indexOf("Long-Term Care Pharmacy") > -1
      );

      let filtered = [];
      if (this.specialtyCheckFilter) filtered = filtered.concat(specialty);
      if (this.retailFilter) filtered = filtered.concat(retails);
      if (this.mailOrderFilter) filtered = filtered.concat(mailOrders);
      if (this.epcsFilter) filtered = filtered.concat(epcs);
      if (this.longTermFilter) filtered = filtered.concat(longTerm);

      filtered = filtered.concat(
        this.pharmacyResults.filter((p) => !p.PharmacySpecialties)
      );

      this.filteredPharmacyResults = filtered.filter(
        (item, pos) => filtered.indexOf(item) === pos
      );
    },
    restartSearch() {
      this.searchName = "";
      this.searchCity = "";
      this.searchState = "";
      this.searchZipCode = "";
      this.pharmacyResults = [];
      this.filteredPharmacyResults = [];
    },
    async startSearching() {
      try {
        if (
          String(this.searchZipCode).trim().length === 0 &&
          String(this.searchCity).trim().length === 0 &&
          String(this.searchState).trim().length === 0 &&
          String(this.searchName).trim().length === 0 &&
          String(this.searchSpecialty).trim().length === 0
        ) {
          Swal.fire(
            "Oops!",
            "Please enter a name, city, state, zip or specialty to find the pharmacies",
            "error"
          );
          return;
        }

        this.showLoader(true);

        let query = "?";
        if (String(this.searchName).trim().length > 0)
          query += `name=${this.searchName}&`;
        if (String(this.searchCity).trim().length > 0)
          query += `city=${this.searchCity}&`;
        if (String(this.searchState).trim().length > 0)
          query += `state=${this.searchState}&`;
        if (String(this.searchZipCode).trim().length > 0)
          query += `zip=${this.searchZipCode}&`;
        if (String(this.searchSpecialty).trim().length > 0)
          query += `specialty[0]=${this.searchSpecialty}&`;

        query = query.slice(0, -1);

        this.pharmacyResults = await this.searchPharmacy(query);

        if (this.pharmacyResults.length === 0) {
          Swal.fire(
            "No Pharmacies Found",
            "We did not find any pharmacies in your search criteria.",
            "warning"
          );
        }

        this.filteredPharmacyResults = this.pharmacyResults;
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire("Ooops!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    getStoreAddress(addressObject) {
      return `${addressObject.Address1}, ${addressObject.Address2}, ${addressObject.City}, ${addressObject.State} - ${addressObject.ZipCode}`;
    },

    getImage(storeBrand) {
      switch (String(storeBrand).toLowerCase()) {
        case "walgreens":
          return images("./walgreens.jpg").default;

        default:
          return images("./notavailable.png").default;
      }
    },
  },
};
</script>
<style scoped>
.banner-wrapper-pharmacy {
  margin: 0 auto;
  max-width: 620px;
  width: 100%;
}

.section-search {
  min-height: 570px;
}

.pharmacy-search-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pharmacy-search-box .form-control {
  border: 1px solid #ccc;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  /* padding-left: 35px; */
}

.pharmacy-search-box .search-location {
  -ms-flex: 0 0 320px;
  flex: 0 0 320px;
  margin-right: 12px;
  position: relative;
  width: 320px;
}

.pharmacy-search-box .search-filter {
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
  margin-right: 12px;
  position: relative;
  width: 200px;
}

.pharmacy-search-box .search-info {
  -ms-flex: 0 0 450px;
  flex: 0 0 450px;
  margin-right: 12px;
  position: relative;
  width: 450px;
}

.pharmacy-search-box .search-btn {
  width: 46px;
  -ms-flex: 0 0 46px;
  flex: 0 0 46px;
  height: 46px;
}

.pharmacy-search-box .search-btn span {
  display: none;
  font-weight: 500;
}

.pharmacy-search-box .form-text {
  color: #757575;
  font-size: 13px;
}

.search-info .form-control {
  background: #fff;
}

.search-filter .form-control {
  background: #fff;
}

@media only screen and (max-width: 600px) {
  .pharmacy-search-box .search-info {
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    margin: auto;
    position: relative;
    width: 270px;
  }

  .pharmacy-search-box .search-filter {
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    margin: auto;
    position: relative;
    width: 270px;
  }
}
</style>
