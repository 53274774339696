<template>
  <div>
    <img
      id="loading-2"
      class="loading-2"
      src="../assets/img/loading-2.gif"
    />
    <img
      id="loading-1"
      class="loading-1"
      src="../assets/img/loading-1.gif"
    />
    <div id="loader-wrapper" />
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    loaderState: function() {
      return this.$store.getters.loader;
    }
  },
  watch: {
    loaderState() {
      !this.loaderState ? this.hide() : this.show();
    }
  },
  mounted() {
    this.hide();
  },
  methods: {
    show() {
      $("#loading-1")
        .delay(10)
        .fadeIn("slow");
      $("#loading-2")
        .delay(10)
        .fadeIn("slow");
      $("#loader-wrapper")
        .delay(10)
        .fadeIn("slow");
      $("body")
        .delay(10)
        .css({ overflow: "invisible" });
    },
    hide() {
      $("#loading-1")
        .delay(10)
        .fadeOut("slow");
      $("#loading-2")
        .delay(10)
        .fadeOut("slow");
      $("#loader-wrapper")
        .delay(10)
        .fadeOut("slow");
      $("body")
        .delay(10)
        .css({ overflow: "visible" });
    }
  }
};
</script>

<style scoped>

.loading-1 {
	position: absolute;
    top: 50%;
    left: 50%;
	width: 160px;
	transform: translate(-50%, -50%);
	z-index: 99999;
}

.loading-2 {
	position: absolute;
	z-index: 99999;
    top: 50%;
    left: 50%;
	transform: translate(-50%, -50%)
}

#loader-wrapper {
	background-color: #fff;
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
	    opacity: 0.8;
}
</style>