<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <breadcrumb40></breadcrumb40>

    <section class="section home-tile-section">
      <div class="container-fluid">
        <div class="section-header text-center">
          <h2>{{ $t("homePage.faqSection.faqBill.title") }}</h2>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="bd-example" data-example-id="">
              <div id="accordion" role="tablist" aria-multiselectable="true">
                <div class="card">
                  <div class="card-header" role="tab" id="headingOne">
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                        <h3>
                          {{ $t("homePage.faqSection.faqBill.billQue1") }}
                        </h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    aria-expanded="false"
                    style=""
                  >
                    <div class="card-block">
                      {{ $t("homePage.faqSection.faqBill.billAns1.ans1")
                      }}<br /><br />

                      <strong>{{
                        $t("homePage.faqSection.faqBill.billAns1.ans2")
                      }}</strong>

                      <ol>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns1.ans3") }}
                        </li>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns1.ans4") }}
                        </li>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns1.ans5") }}
                        </li>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns1.ans6") }}
                        </li>
                      </ol>

                      {{ $t("homePage.faqSection.faqBill.billAns1.ans7") }}
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" role="tab" id="headingTwo">
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                        <h3>
                          {{ $t("homePage.faqSection.faqBill.billQue2") }}
                        </h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      {{ $t("homePage.faqSection.faqBill.billAns2.ans1") }}
                      <br />
                      <br />{{
                        $t("homePage.faqSection.faqBill.billAns2.ans2")
                      }}

                      <ol>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns2.ans3") }}
                        </li>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns2.ans4") }}
                        </li>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns2.ans5") }}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" role="tab" id="headingThree">
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                        <h3>
                          {{ $t("homePage.faqSection.faqBill.billQue3") }}
                        </h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      {{ $t("homePage.faqSection.faqBill.billAns3.ans1")
                      }}<br /><br />

                      <ul>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns3.ans2") }}
                        </li>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns3.ans3") }}
                        </li>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns3.ans4") }}
                        </li>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns3.ans5") }}
                        </li>
                        <li>
                          {{ $t("homePage.faqSection.faqBill.billAns3.ans6") }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <layout-footer class="footer"></layout-footer>
  </div>
</template>
<style scoped>
.card-block {
  padding: 20px;
}
</style>
