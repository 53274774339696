<template>
	<div class="call-page">
		<div class="main-wrapper">

			<div class="call-main-row">
				<div class="call-main-wrapper">
					<div class="call-view">
						<div class="call-window">

							<!-- Call Header -->
							<div class="fixed-header">
								<div class="navbar">
									<div class="user-details" v-if="this.meetingDetails.patient">
										<div class="float-left user-img">
											<div class="avatar avatar-sm mr-2" title="Charlene Reed">
												<img :src="this.meetingDetails.patient.image" alt="User Image"
													class="rounded-circle">
												<span class="status online"></span>
											</div>
										</div>
										<div class="user-info float-left">
											<div><span>{{
												this.meetingDetails.patient.firstName }} {{
		this.meetingDetails.patient.lastName }}</span></div>
											<span class="last-seen">Prescription ID: {{
												this.meetingDetails.prescriptionId }}</span>
										</div>
									</div>
									<ul class="nav float-right custom-menu">
										<!-- <li class="nav-item dropdown dropdown-action">
														<a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown"
															aria-expanded="false"><i class="fa fa-cog"></i></a>
														<div class="dropdown-menu dropdown-menu-right">
															<a href="javascript:void(0)" class="dropdown-item">Settings</a>
														</div>
													</li> -->
									</ul>
								</div>
							</div>
							<!-- /Call Header -->

							<!-- Call Contents -->
							<div class="call-contents">
								<div class="call-content-wrap">
									<div class="user-video" v-if="this.meetingDetails.patient">
										<div v-if="Object.keys(peers).length === 0" class="text-center p-5">
											<div class="voice-call-avatar">
												<img src="../../assets/img/meet/user-screen.jpg" alt="Blank Image"
													class="call-avatar">
												<span class="username">Waiting for {{
													this.meetingDetails.patient.firstName }} to join...</span>
											</div>
										</div>
										<div style="height: 100%" v-else v-for="(peer, i) in Object.keys(peers)" :key="i">
											<video :style="videoStyle" v-if="peers[peer].isCamEnabled" :id="`${peer}-video`"
												autoplay></video>
											<div v-else class="text-center">
												<div class="request-loader" style="height: 120px">
													<img class="center-image" style="width: 120px"
														src="../../assets/img/meet/user-screen.jpg" />
												</div>
											</div>
											<audio :id="`${peer}-audio`" autoplay />
										</div>
										<!-- <img src="../../assets/img/video-call.jpg" alt="User Image"> -->
									</div>
									<div class="my-video">
										<ul>
											<li>
												<video id="own-video" autoplay muted class="img-fluid" />
											</li>
										</ul>
									</div>
								</div>
							</div>
							<!-- Call Contents -->

							<!-- Call Footer -->
							<div class="call-footer">
								<div class="call-icons">
									<span class="call-duration">{{ currentTime }}</span>
									<ul class="call-items">
										<li class="call-item" :title="meet.self.webcamInProgress ? 'Turn off camera' : 'Turn on camera'
											">
											<button @click="toggleWebcam"
												:class="`btn meet-control-btn ${meet.self.webcamInProgress ? 'btn-secondary' : 'btn-danger'}`"
												data-placement="top" data-toggle="tooltip" :disabled="camSwitchInProgress">
												<i v-if="meet.self.webcamInProgress" class="material-icons">videocam</i>
												<i v-else class="material-icons">videocam_off</i>
											</button>
										</li>
										<li class="call-item" :title="meet.self.micInProgress
											? 'Turn off microphone'
											: 'Turn on microphone'
											">
											<button @click="toggleMicrophone"
												:class="`btn meet-control-btn ${meet.self.micInProgress ? 'btn-secondary' : 'btn-danger'}`"
												data-placement="top" data-toggle="tooltip">
												<i v-if="meet.self.micInProgress" class="material-icons">mic</i>
												<i v-else class="material-icons">mic_off</i>
											</button>
										</li>
										<!-- <li class="call-item">
														<button @click="markChatRead"
															class="btn btn-secondary meet-control-btn">
															<i class="material-icons">comment</i>
														</button>
													</li> -->
										<li class="call-item">
											<button class="btn btn-secondary meet-control-btn" @click="toggleFullScreen"
												title="Full Screen" data-placement="top" data-toggle="tooltip">
												<i class="material-icons">fullscreen</i>
											</button>
										</li>
									</ul>
									<div class="end-call">
										<a href="javascript:void(0);" @click="endMeeting">
											<i class="material-icons">call_end</i>
										</a>
									</div>
								</div>
							</div>
							<!-- /Call Footer -->

						</div>
					</div>

				</div>
			</div>

		</div>

		<!-- Chat Box Modal -->
		<div data-backdrop="static" id="chat-modal" class="slideout">
			<Chat v-if="meetingDetails.patient" isCallingEnabled="false"
				:patientName="`${meetingDetails.patient.firstName} ${meetingDetails.patient.lastName}`"
				:patientImage="meetingDetails.patient.image" :prescriptionId="meetingDetails.prescriptionId"
				status="In Progress" @closechat="closeChat" />
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { RTCPLive } from "../../plugins/meet.js";
import { EmojiPicker } from "../../plugins/emoji.js";
import Swal from 'sweetalert2'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Chat from "./Chat.vue";
import config from '../../constants/config'

export default {
	name: "meet",
	props: {
		meetingCode: String,
		requestId: Number,
		callType: String
	},
	components: {
		Chat,
	},
	data () {
		return {
			currentTime: new Date(),
			loadingText: "",

			chatMessage: "",
			tabIndex: 0,

			// New Integration
			meetingDetails: {},
			confirmMeetingPassword: null,
			infoModal: {
				title: "",
				message: "",
			},
			pinnedParticipant: null,
			showFooterControls: true,
			footerTimeout: null,
			pollingInterval: null,
			refreshTimeout: null,
			inProgress: false,
			isPinned: false,
			selectedTab: "people",

			camSwitchInProgress: false,
			micSwitchInProgress: false,
			recordSwitchInProgress: false,
			screenSwitchInProgress: false,

			// Class obj
			liveSessionObj: new RTCPLive(),
			isChatOpen: false
		};
	},
	computed: {
		...mapGetters(["meet"]),
		currentUserName () {
			return `${this.currentUser.first_name} ${this.currentUser.last_name}`
		},
		currentUser () {
			return this.$store.getters.user
		},
		peers () {
			return this.meet.peers;
		},
		chatMessages () {
			return this.meet.chatMessages;
		},
		screenShareStatus: function () {
			return this.meet.self.shareInProgress;
		},
		recordingStatus: function () {
			return this.meet.self.recordingInProgress;
		},
		videoColumnClass () {
			if (
				Object.keys(this.peers).length >= 1 &&
				Object.keys(this.peers).length <= 3
			)
				return "col";
			else if (Object.keys(this.peers).length === 4) return "col-6 p-1";
			else if (
				Object.keys(this.peers).length === 5 ||
				Object.keys(this.peers).length === 6
			)
				return "col-sm-4 col-xs-6 col-sm-offset-2 p-1";
			else return "col-sm-4 col-xs-6 col-sm-offset-2 p-1";
		},
		videoColumnStyle () {
			if (
				Object.keys(this.peers).length >= 1 &&
				Object.keys(this.peers).length <= 3
			)
				return "height: calc(100vh - 35px);";
			else if (
				Object.keys(this.peers).length >= 4 &&
				Object.keys(this.peers).length <= 6
			)
				return "height: calc((100vh / 2) - 15px) ;";
			else if (
				Object.keys(this.peers).length >= 7 &&
				Object.keys(this.peers).length <= 9
			)
				return "height: calc((100vh / 3) - 15px);";
			else return "height: calc((100vh / 3) - 15px);";
		},
		videoStyle () {
			if (Object.keys(this.peers).length === 1) return `height: 100%;`;
			else return `height: 100%; width: 100%;`;
		},
	},
	watch: {
		peers: function () {
			if (this.liveSessionObj) {
				clearTimeout(this.refreshTimeout);
				this.refreshTimeout = setTimeout(() => {
					// this.liveSessionObj.ShowPeerThumbnail();
					this.liveSessionObj.RenderPeerVideos();

					// if (Object.keys(this.peers).length === 0)
					//   this.liveSessionObj.ShowMyVideoAudio("own-video");
				}, 500);
			}
		},
		screenShareStatus: function () {
			this.liveSessionObj.ShowMyVideoAudio("own-video");
		},
		chatMessages: function () {
			const latestMessage = this.chatMessages[this.chatMessages.length - 1];
			if (!latestMessage) return;

			if (latestMessage.Username !== this.currentUserName) {
				const audio = new Audio("https://sounds.adgoogly.com/message.ogg");
				audio.play();

				toast.info(`${latestMessage.Username} sent a message`, {
					autoClose: 4000,
					position: "top-left",
				});
			}
		},
	},
	async mounted () {
		this.showLoader(true)

		await this.$store.dispatch("RESET_RTCP");
		this.startMeetingClock();
		try {
			// Check user is valid
			if (!this.currentUser) {
				Swal.fire('Ooops!', "Invalid user. Please reconnect.", 'error').then(() => {
					this.$router.push("/");
				})

				return;
			}

			let meetingDetails = await this.$meet.GetMeetingInfo({
				meetingCode: this.meetingCode,
				userId: this.currentUser.id,
			});

			this.meetingDetails = meetingDetails;

			// Check if data is available and user is valid
			if (!this.meetingDetails) {
				Swal.fire('Oops!', "Connection error. Try again.", 'error').then(() => {
					this.$router.push("/");
				})
				return;
			}

			// Check password
			// if (
			// 	this.meetingDetails.isPasswordEnabled &&
			// 	(!this.password || this.password === "")
			// ) {
			// 	this.$bvModal.show("verify-password-modal");
			// 	return;
			// }

			// Set password
			this.confirmMeetingPassword = this.password;

			// Remove if more that two users
			if (Object.keys(this.peers).length >= 2) {
				this.$router.push('/provider/index')
				return
			}

			// Join Meeting
			this.joinMeeting();

			// Show meeting details
			// if (this.meetingDetails.isSessionHost)
			// 	this.$bvModal.show("meeting-details-modal");
		} catch (e) {
			console.error(e);
			Swal.fire('Oops!', "Unexpected error while joining the call", 'error').then(() => {
				window.history.back()
			})
		}

		new EmojiPicker({
			trigger: [
				{
					selector: ".emoji",
					insertInto: ".chat-input",
				},
			],
			closeButton: true,
			//specialButtons: green
		});
	},
	methods: {
		...mapActions(["showLoader"]),

		markChatRead () {
			this.$socket.emit("REQUEST", {
				command: "POST:CHAT_MESSAGES_MARK_READ",
				userId: this.$store.getters.user.id,
				prescriptionId: this.meetingDetails.prescriptionId,
			});

			this.isChatOpen = true;
			$('.slideout').addClass('on');
		},

		closeChat () {
			this.isChatOpen = false;
			$('.slideout').removeClass('on');
		},

		async joinMeeting () {
			try {
				// Set session host
				this.$store.dispatch("SET_ROOM_HOST_ID", {
					sessionHostId: this.meetingDetails.hostId,
				});

				// Set room/session information in store
				this.$store.dispatch("SET_ROOM_URL", {
					isSessionHost: this.meetingDetails.isSessionHost,
					sessionId: this.currentUser.id,
					sessionName: this.meetingDetails.meetingName,
					meetingCode: this.meetingCode,
					sessionURL: window.location.href,
					isPasswordProtected: this.meetingDetails.isPasswordEnabled,
				});

				// Set self information in store
				this.$store.dispatch("SET_ME", {
					peerId: this.currentUser.id,
					userName: this.currentUserName,
				});

				await this.liveSessionObj.StartVideoBroadcast(
					this.meetingCode,
					this.meetingDetails.meetingName,
					this.currentUserName,
					this.meetingDetails.serverUrl
				);

				await this.liveSessionObj.ShowMyVideoAudio("own-video");
				// this.toggleSidebar();

				// Mark session as active if host
				// if (this.meetingDetails.isSessionHost)
				//     await this.$meet.ReopenMeeting({
				//         meetingCode: this.meetingCode,
				//         userId: this.currentUser.userId,
				//     });

				// Start polling
				// this.startPollingTheUserPresence();

				// const audio = new Audio("https://sounds.adgoogly.com/join.mp3");
				// audio.play();

				toast.success("Connected", {
					autoClose: 2000,
					position: "bottom-left",
					theme: "auto",
				});

				// if (this.meet.self.webcamInProgress) this.enableDraggableVideo();

				this.showLoader(false)

			} catch (e) {
				console.error(e);
				this.showLoader(false)
				Swal.fire('Oops!', "Unexpected error while joining the call", 'error').then(() => {
					this.$router.push(`/patient/request/${this.meetingDetails.prescriptionId}`)
				})
			}
		},


		startMeetingClock () {
			this.currentTime = this.formatAMPM(new Date());
			setInterval(() => {
				this.currentTime = this.formatAMPM(new Date());
			}, 1000);
		},

		async endMeeting () {
			// Stop recording if active
			if (this.recordingStatus) {
				this.showLoader(true)
				this.liveSessionObj.StopRecorder();
			}

			await this.liveSessionObj.StopVideoBroadcast();
			await this.$meet.EndMeeting();

			await axios.post(`${config.BASE_URL}${config.HTTP_API.endCallRequest}`, {
				apiKey: '1234567890',
				meetingId: this.meetingCode,
				requestId: this.requestId,
			})

			this.showLoader(false)

			clearInterval(this.pollingInterval);
			this.$router.push(`/provider/request/${this.meetingDetails.prescriptionId}`);
		},

		async toggleWebcam () {
			try {
				const hardware = await this.liveSessionObj.GetHardwareSettings();
				if (!hardware.settings.isCameraAvailable) {
					toast.error('Camera Not Available', {
						autoClose: 2000,
						position: "top-center",
					})
					return;
				}

				this.camSwitchInProgress = true;
				if (this.meet.self.webcamInProgress) {
					await this.liveSessionObj.DisableWebcam();
					toast.info('Camera Disabled', {
						autoClose: 1000,
						position: "top-center",
					})
				} else {
					await this.liveSessionObj.EnableWebcam();
					toast.info('Camera Enabled', {
						autoClose: 1000,
						position: "top-center",
					})
				}
				this.camSwitchInProgress = false;
			} catch (e) {
				toast.error(e, {
					autoClose: 2000,
				})
				this.camSwitchInProgress = false;
			}
		},

		async toggleMicrophone () {
			try {
				const hardware = await this.liveSessionObj.GetHardwareSettings();
				if (!hardware.settings.isMicrophoneAvailable) {
					toast.error("Microphone Not Available", {
						autoClose: 2000,
						position: "top-center",
					});
					return;
				}

				this.micSwitchInProgress = true;
				if (this.meet.self.micInProgress) {
					await this.liveSessionObj.DisableMicrophone();
					toast.info("Microphone Disabled", {
						autoClose: 1000,
						position: "top-center",
					});
				} else {
					await this.liveSessionObj.EnableMicrophone();
					toast.info("Microphone Enabled", {
						autoClose: 1000,
						position: "top-center",
					});
				}
				this.micSwitchInProgress = false;
			} catch (e) {
				this.micSwitchInProgress = false;
				toast.error("Microphone disable error", {
					autoClose: 2000,
				});
			}
		},

		formatAMPM (date) {
			date = new Date(date);
			let hours = date.getHours();
			let minutes = date.getMinutes();
			let ampm = hours >= 12 ? "PM" : "AM";
			hours = hours % 12;
			hours = hours ? hours : 12;
			hours = hours < 10 ? "0" + hours : hours;
			minutes = minutes < 10 ? "0" + minutes : minutes;
			return hours + ":" + minutes + " " + ampm;
		},

		isInFullScreen () {
			return (
				(document.fullscreenElement && document.fullscreenElement !== null) ||
				(document.webkitFullscreenElement &&
					document.webkitFullscreenElement !== null) ||
				(document.mozFullScreenElement &&
					document.mozFullScreenElement !== null) ||
				(document.msFullscreenElement && document.msFullscreenElement !== null)
			);
		},

		toggleFullScreen () {
			const isInFullScreen = this.isInFullScreen();

			var docElm = document.documentElement;
			if (!isInFullScreen) {
				if (docElm.requestFullscreen) {
					docElm.requestFullscreen();
				} else if (docElm.mozRequestFullScreen) {
					docElm.mozRequestFullScreen();
				} else if (docElm.webkitRequestFullScreen) {
					docElm.webkitRequestFullScreen();
				} else if (docElm.msRequestFullscreen) {
					docElm.msRequestFullscreen();
				}
			} else {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			}
			this.fullScreen = !isInFullScreen;
		},

	}
}
</script>
<style>
.btn-red {
	background-color: #f06060;
}

.meet-control-btn {
	border-radius: 50% !important;
	height: 60px !important;
	width: 60px !important;
	font-size: 30px !important;
	color: white !important;
}

.slideout {
	background: linear-gradient(to bottom, #15558d 0%, #15558d 100%);
	color: #fff;
	height: 100%;
	padding-left: 2px;
	position: fixed;
	right: -500px;
	top: 0;
	transition-duration: 0.2s;
	width: 500px;
	z-index: 9999;
}

.slideout.on {
	right: 0;
}
</style>