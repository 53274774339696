import { createStore } from "vuex";
import user from './modules/user'
import meet from './modules/meet'
import socket from './modules/socket'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    loader: false,
    alert: {
      state: false,
      message: '',
      type: '',
      time: 0
    }
  },
  getters: {
    loader: state => state.loader,
    alert: state => state.alert
  },
  mutations: {
    setLoader (state, payload) {
      state.loader = payload
    },
    setAlert (state, payload) {
      state.alert = payload
    }
  },
  actions: {
    showLoader ({ commit }, payload) {
      commit('setLoader', payload)
    },
    showAlert ({ commit }, payload) {
      commit('setAlert', { ...payload, state: true })
      if (payload.time != 0) {
        setTimeout(() => {
          commit('setAlert', {
            state: false,
            message: '',
            type: '',
            time: 0
          })
        }, payload.time);
      }
    }
  },
  modules: {
    user,
    socket,
    meet
  },
  plugins: [
    createPersistedState({ storage: window.sessionStorage })
  ]
})
