<template>
    <!-- FAQ Section -->
    <section class="section section-blogs">
        <div class="container-fluid">
            <!-- Section Header -->
            <div class="section-header text-center">
                <h2>
                    {{ $t("homePage.conditionsSections.title") }}
                </h2>
                <p class="sub-title">
                    {{ $t("homePage.conditionsSections.description") }}
                </p>
            </div>
            <!-- /Section Header -->
            <div class="row text-center">
                <div v-for="(start, last) in [0, 1, 2, 3]" :key="last" class="col-12 col-md-3 col-sm-6 col-lg-3 m-0 p-0">
                    <ul class="list-group">
                        <li class="list-group-item"
                            v-for="(condition, index) in sortedConditions.slice(start * (top_conditions.length / 4), (start + 1) * (top_conditions.length / 4))"
                            :key="index">
                            <router-link @click="moveUp" :to="condition.route" class="ml-1">{{ condition.name
                            }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!-- /FAQ Section -->
</template>

<script>
export default {
    data() {
        const route_faq = '/faq-med-conditions'
        const top_conditions = [
            { route: route_faq + "/sleep-disorders", name: "Sleep Disorders", component: 'Sleep-Disorders' },
            { route: route_faq + "/migraines-headaches", name: "Migraines/Headaches", component: 'Migraines-Headaches' },
            { route: route_faq + "/hearing-loss", name: "Hearing Loss", component: 'Hearing_Loss' },
            { route: route_faq + "/vision-problems", name: "Vision Problems", component: 'Vision_Problems' },
            { route: route_faq + "/anxiety", name: "Anxiety", component: 'Anxiety' },
            { route: route_faq + "/upper-respiratory-infections", name: "Upper Respiratory infections (colds)", component: 'Colds' },
            { route: route_faq + "/weight-loss", name: "Weight Loss", component: 'Weight_Loss' },
            { route: route_faq + "/fever", name: "Fever", component: 'Fever' },
            { route: route_faq + "/fatigue", name: "Fatigue", component: 'Fatigue' },
            { route: route_faq + "/cough", name: "Cough", component: 'Cough' },
            { route: route_faq + "/diabetes", name: "Diabetes", component: 'Diabetes' },
            { route: route_faq + "/asthma", name: "Asthma", component: 'Asthma' },
            { route: route_faq + "/chest-pain", name: "Chest pain", component: 'Chest-Pain' },
            { route: route_faq + "/cognitive-changes", name: "Cognitive Changes (memory loss, confusion)", component: 'Cognitive_Changes' },
            { route: route_faq + "/depression", name: "Depression", component: 'Depression' },
            { route: route_faq + "/insomnia", name: "Insomnia", component: 'Insomnia' },
            { route: route_faq + "/abdominal-pain", name: "Abdominal Pain", component: 'Abdominal-Pain' },
            { route: route_faq + "/nausea", name: "Nausea", component: 'Nausea' },
            { route: route_faq + "/diarrhea", name: "Diarrhea", component: 'Diarrhea' },
            { route: route_faq + "/dizziness", name: "Dizziness", component: 'Dizziness' },
            { route: route_faq + "/sprains-and-strains", name: "Sprains and Strains", component: 'Sprains_Strains' },
            { route: route_faq + "/changes-in-bowel-habits", name: "Changes in Bowel Habits", component: 'Bowel_Change' },
            { route: route_faq + "/constipation", name: "Constipation", component: 'Constipation' },
            { route: route_faq + "/back-pain", name: "Back Pain", component: 'Back-Pain' },
            { route: route_faq + "/muscle-aches-or-pain", name: "Muscle Aches or Pain", component: 'Muscle-aches' },
            { route: route_faq + "/joint-pain", name: "Joint Pain", component: 'Joint-Pain' },
            { route: route_faq + "/skin-rashes", name: "Skin Rashes", component: 'Skin-Rashes' },
            { route: route_faq + "/swelling", name: "Swelling", component: 'Swelling' },
            { route: route_faq + "/sore-throat", name: "Sore Throat (Pharyngitis)", component: 'Sore-throat' },
            { route: route_faq + "/high-cholesterol", name: "High Cholesterol", component: 'High_Cholesterol' },
            { route: route_faq + "/obesity", name: "Obesity", component: 'Obesity' },
            { route: route_faq + "/dermatitis", name: "Dermatitis (skin inflammation)", component: 'Dermatitis' },
            { route: route_faq + "/vertigo", name: "Vertigo", component: 'Vertigo' },
            { route: route_faq + "/hypertension", name: "Hypertension (High Blood Pressure)", component: 'Hypertension' },
            { route: route_faq + "/menstrual-disorders", name: "Menstrual Disorders", component: 'Menstral_Disorders' },
        ];

        return {
            top_conditions,
            conditions: [...top_conditions,
            { route: route_faq + "/strep-throat", name: "Strep Throat", component: 'Strep-Throat' }
                // { route: route_faq + "/", name: "", component: '' 
            ],
        }
    },
    methods: {
        moveUp() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
    computed: {
        sortedConditions() {
            return this.top_conditions.slice().sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        }
    }
};
</script>
<style scoped>
.btn-text {
    color: white;
}

.icon-text {
    font-size: 65px;
    margin-bottom: 20px;
}
</style>
