<template>
  <div class="chat-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <div class="chat-window">
                <!-- Chat Right -->
                <div class="chat-cont-right">
                  <div class="chat-header">
                    <a id="back_user_list" href="javascript:void(0)" class="back-user-list">
                      <i class="material-icons">chevron_left</i>
                    </a>
                    <div class="media">
                      <div class="media-img-wrap">
                        <div class="avatar">
                          <img :src="patientImage" alt="User Image" class="avatar-img rounded-circle" />
                        </div>
                      </div>
                      <div class="media-body">
                        <div class="user-name">{{ patientName }}</div>
                        <div class="user-status">
                          Prescription ID: {{ prescriptionId }}
                        </div>
                      </div>
                    </div>
                    <div class="chat-options">
                      <!-- <button v-if="chatEnabled && isCallingEnabled == 'true'" title="Audio Call"
                        class="btn btn-sm bg-info-light mr-2" @click="initiateCalling('audio')">
                        <i class="material-icons">local_phone</i>
                      </button>
                      <button v-if="chatEnabled && isCallingEnabled == 'true'" title="Video Call"
                        class="btn btn-sm bg-info-light mr-2" @click="initiateCalling('video')">
                        <i class="material-icons">videocam</i>
                      </button> -->
                      <button @click="$emit('closechat')" class="btn btn-sm bg-danger-light ml-3">
                        <i class="material-icons">close</i>
                      </button>
                      <!-- <a href="javascript:void(0)">
                        <i class="material-icons">more_vert</i>
                      </a> -->
                    </div>
                  </div>
                  <div class="chat-body">
                    <div id="chatbox" class="chat-scroll">
                      <ul class="list-unstyled">
                        <div v-for="(msgObj, i) in chatMessages" :key="i">
                          <li :class="`mb-2 media ${msgObj.user_id === this.$store.getters.user.id
                            ? 'sent'
                            : 'received'
                            }`">
                            <div class="avatar" v-if="msgObj.user_id !== this.$store.getters.user.id
                            ">
                              <img :src="patientImage" :alt="patientName" class="avatar-img rounded-circle" />
                            </div>
                            <div class="media-body">
                              <div class="msg-box">
                                <div>
                                  <p>{{ msgObj.message }}</p>
                                  <ul class="chat-msg-info">
                                    <li>
                                      <div class="chat-time">
                                        <span style="font-size: 10px">{{
                            new Date(
                              msgObj.created_at
                            ).toLocaleString()
                          }}</span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>

                        <!-- <li class="media received">
                          <div class="avatar">
                            <img
                              src="../../assets/img/doctors/doctor-thumb-02.jpg"
                              alt="User Image"
                              class="avatar-img rounded-circle"
                            />
                          </div>
                          <div class="media-body">
                            <div class="msg-box">
                              <div>
                                <p>I'm just looking around.</p>
                                <p>
                                  Will you tell me something about yourself?
                                </p>
                                <ul class="chat-msg-info">
                                  <li>
                                    <div class="chat-time">
                                      <span>8:35 AM</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="msg-box">
                              <div>
                                <p>Are you there? That time!</p>
                                <ul class="chat-msg-info">
                                  <li>
                                    <div class="chat-time">
                                      <span>8:40 AM</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="msg-box">
                              <div>
                                <div class="chat-msg-attachments">
                                  <div class="chat-attachment">
                                    <img
                                      src="../../assets/img/img-02.jpg"
                                      alt="Attachment"
                                    />
                                    <div class="chat-attach-caption">
                                      placeholder.jpg
                                    </div>
                                    <a href="" class="chat-attach-download">
                                      <i class="fas fa-download"></i>
                                    </a>
                                  </div>
                                  <div class="chat-attachment">
                                    <img
                                      src="../../assets/img/img-03.jpg"
                                      alt="Attachment"
                                    />
                                    <div class="chat-attach-caption">
                                      placeholder.jpg
                                    </div>
                                    <a href="" class="chat-attach-download">
                                      <i class="fas fa-download"></i>
                                    </a>
                                  </div>
                                </div>
                                <ul class="chat-msg-info">
                                  <li>
                                    <div class="chat-time">
                                      <span>8:41 AM</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                  <div class="chat-footer">
                    <form @submit.prevent="sendMessage" v-if="chatEnabled">
                      <div class="input-group">
                        <!-- <div class="input-group-prepend">
                        <div class="btn-file btn">
                          <i class="fa fa-paperclip"></i>
                          <input type="file" />
                        </div>
                      </div> -->
                        <input type="text" class="input-msg-send form-control" placeholder="Write a message"
                          v-model="message" required />
                        <div class="input-group-append">
                          <button type="submit" class="btn msg-send-btn">
                            <i class="fab fa-telegram-plane"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div class="text-center" v-else>
                      <h5 class="mt-2">The chat has been closed</h5>
                    </div>
                  </div>
                </div>
                <!-- /Chat Right -->
              </div>
            </div>
          </div>
          <!-- /Row -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <div class="modal fade call-modal" data-backdrop="static" data-keyboard="false" id="video_call">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body" style="padding: 20px;">

            <!-- Incoming Call -->
            <div class="call-box incoming-box">
              <div class="call-wrapper">
                <div class="call-inner">
                  <div class="call-user">
                    <video id="preview-video" class="video-poster" autoplay />
                    <hr />
                    <div class="text-left">
                      <div class="row">
                        <div class="col-9">
                          <div class="form-group">
                            <label>Camera</label>
                            <vue-select class="form-control w-100" v-model="selectedDefaultCamera"
                              :options="cameraDevices" placeholder="Select Camera" label-by="label"
                              @selected="changeCamera()" close-on-select
                              :disabled="cameraDevices.length === 0 || !isCameraEnabled"></vue-select>
                          </div>
                        </div>
                        <div class="col-3 text-center">
                          <button v-if="callType === 'video'" @click="ToggleCamera"
                            :class="`mt-3 btn meet-control-btn ${isCameraEnabled ? 'btn-secondary' : 'btn-danger'}`">
                            <i v-if="isCameraEnabled" class="material-icons">videocam</i>
                            <i v-else class="material-icons">videocam_off</i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-9">
                          <label>Microphone</label>
                          <vue-select class="form-control w-100" v-model="selectedDefaultMicrophone"
                            :options="microphoneDevices" placeholder="Select Microphone" label-by="label"
                            close-on-select @selected="changeMicrophone()"
                            :disabled="microphoneDevices.length === 0 || !isMicrophoneEnabled"></vue-select>
                        </div>
                        <div class="col-3">
                          <button @click="ToggleMicrophone"
                            :class="`mt-3 btn meet-control-btn ${isMicrophoneEnabled ? 'btn-secondary' : 'btn-danger'}`">
                            <i v-if="isMicrophoneEnabled" class="material-icons">mic</i>
                            <i v-else class="material-icons">mic_off</i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Audio Output</label>
                      <vue-select class="form-control w-100" v-model="selectedDefaultSpeaker" :options="speakerDevices"
                        placeholder="Select Audio Output" label-by="label" @selected="changeSpeaker()" close-on-select
                        :disabled="speakerDevices.length === 0"></vue-select>
                    </div>
                  </div>
                  <!-- <img class="call-avatar" src="../../assets/img/doctors/doctor-thumb-02.jpg" alt="User Image">
                    <h4>Darren Elder</h4>
                    <span>Calling ...</span> -->
                </div>
                <div class="row">
                  <div class="col-4">
                    <button @click="cancelCalling" type="button" class="btn bg-danger text-light w-100 h-100">
                      <i class="fa fa-close mr-2"></i>
                      <span v-if="!isCallRequested">Cancel</span>
                      <span v-else>Hangup</span>
                    </button>
                  </div>
                  <div class="col-8">
                    <button :disabled="isCallRequested" @click="createCallRequest" type="button"
                      class="btn bg-info text-light w-100 h-100">
                      <i class="fa fa-phone mr-2"></i>
                      <span v-if="!isCallRequested">Start Call</span>
                      <span v-else>{{ callingStatus }}... ({{ this.waitTimer }})</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Incoming Call -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Swal from 'sweetalert2';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from 'axios'
import config from '../../constants/config'
import msgTone from '../../assets/sounds/message.mp3'

export default {
  props: {
    prescriptionId: String,
    patientName: String,
    patientImage: String,
    status: String,
    isCallingEnabled: Boolean,
  },
  data() {
    return {
      interval: null,
      message: "",

      storage: {},
      isCameraEnabled: true,
      isMicrophoneEnabled: true,

      // Default hardware devices
      selectedDefaultCamera: "default",
      selectedDefaultMicrophone: "default",
      selectedDefaultSpeaker: "default",
      cameraDevices: [],
      microphoneDevices: [],
      speakerDevices: [],

      callType: 'video',
      callId: '',
      isSessionHost: false,
      meetingName: '',
      requestId: null,
      isCallRequested: false,
      waitTimer: 0,
      waitInterval: null,
      callingStatus: 'Connecting',
      messageAudio: new Audio(msgTone),
      isMessageAudioPlaying: false
    };
  },
  computed: {
    chatMessages() {
      return this.$store.getters.chatMessages;
    },
    route() {
      return this.$route.name;
    },
    chatEnabled() {
      return this.status === "Completed" || this.status === "Cancelled"
        ? false
        : true;
    },
    connectionId() {
      return this.$store.getters.connectionId
    }
  },
  watch: {
    chatMessages: function (newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        var elem = document.getElementById("chatbox");
        elem.scrollTop = elem.scrollHeight;
        const message = newVal[newVal.length - 1]
        if (message && message.user_id !== this.$store.getters.user.id) {
          Swal.fire({
            position: "top-end",
            title: "New message alert!",
            html: 'patient message' + " : " + message.message,
            icon: 'info',
            showConfirmButton: false,
            timer: 5000,
            customClass: {
              popup: 'my-custom-popup-class',
              title: 'my-custom-title-class',
              content: 'my-custom-content-class'
            }
          });
          this.playMessageTone()
        }
      }
    },
    route: function () {
      if (this.route !== "provider-prescription-details") {
        if (this.interval) clearInterval(this.interval);
        this.interval = null;
        this.$socket.emit("REQUEST", {
          command: "GET:CLEAR_CHAT",
          userId: this.$store.getters.user.id,
        });
      }
    },
  },
  async mounted() {

    this.$socket.emit("REQUEST", {
      command: "GET:CHAT_MESSAGES",
      userId: this.$store.getters.user.id,
      prescriptionId: this.prescriptionId,
    });

    this.sockets.subscribe('MEET:REQUEST_CALL_RESPONSE', (data) => {
      this.requestId = data.requestId
      this.startCallWaitTimer()
      this.callingStatus = 'Calling'
    })

    this.sockets.subscribe('MEET:ACCEPT_CALL_RESPONSE', async (data) => {
      await this.$meet.JoinMeeting({
        meetingCode: this.callId,
        userName: `${this.$store.getters.user.first_name} ${this.$store.getters.user.last_name}`,
        userId: this.$store.getters.user.id,
        userEmail: this.$store.getters.user.email,
        isSessionHost: this.isSessionHost,
        meetingName: this.meetingName
      });

      $('#video_call').modal('hide');
      if (this.waitInterval) clearInterval(this.waitInterval)
      this.waitInterval = null
      this.$router.push(`/provider/call/${this.requestId}/${this.callId}`);
      this.isCallRequested = false
    })

    this.sockets.subscribe('MEET:DECLINE_CALL_RESPONSE', (data) => {
      this.$meet.LeaveMeeting();
      this.requestId = null
      this.isCallRequested = false
      this.waitTimer = 0
      this.callingStatus = 'Connecting'
      if (this.waitInterval) clearInterval(this.waitInterval)
      this.waitInterval = null
      $('#video_call').modal('hide');
      Swal.fire('Call Declined', 'Patient is busy right now. Please try again later.', 'warning')
    })

    if (this.chatEnabled) {
      this.interval = setInterval(() => {
        if (!this.chatEnabled) {
          if (this.interval) clearInterval(this.interval);
          this.interval = null;
          return;
        }

        this.$socket.emit("REQUEST", {
          command: "GET:CHAT_MESSAGES",
          userId: this.$store.getters.user.id,
          prescriptionId: this.prescriptionId,
        });
      }, 2000);
    } else {
      if (this.interval) clearInterval(this.interval);
      this.interval = null;
    }
  },
  methods: {
    ...mapActions(['showLoader']),

    playMessageTone() {
      if (!this.isMessageAudioPlaying) {
        this.isMessageAudioPlaying = true
        this.messageAudio.play()
        setTimeout(() => { this.isMessageAudioPlaying = false }, 5000)
      }
    },

    async cancelCalling() {
      this.$meet.LeaveMeeting();

      if (this.requestId) {
        this.$socket.emit('REQUEST', {
          command: 'MEET:CANCEL_REQUEST',
          userId: this.$store.getters.user.id,
          requestId: this.requestId
        })
      }

      this.requestId = null
      this.isCallRequested = false
      this.waitTimer = 0
      if (this.waitInterval) clearInterval(this.waitInterval)
      this.waitInterval = null
      $('#video_call').modal('hide');
    },

    async startCallWaitTimer() {
      this.waitTimer = 0
      if (this.waitInterval) clearInterval(this.waitInterval)

      this.waitInterval = setInterval(() => {
        if (this.waitTimer >= 60) {
          this.cancelCalling()
          clearInterval(this.waitInterval)
          this.waitTimer = 0
          this.waitInterval = null
          Swal.fire('Call Unanswered', 'Patient is not available at the moment. Please try again later.', 'warning')
          return
        }

        this.waitTimer += 1
      }, 1000)
    },

    async initiateCalling(callType) {

      const camPermissions = await this.$meet.CheckCameraPermissions()
      const micPermissions = await this.$meet.CheckMicrophonePermissions()
      if (camPermissions != 'granted' || micPermissions != 'granted') {
        this.$meet.AskCameraMicPermissions()
        Swal.fire('Requesting Permissions', 'We would need your permission to access the camera and microphone', 'warning')
        return
      }

      this.callType = callType
      this.waitTimer = 0

      // Get meeting storage
      this.storage = this.$meet.getMeetingStore();

      // Get available hardware devices
      await this.LoadHardwareDevices();

      // Get meeting code
      const res = await axios.post(`${config.BASE_URL}${config.HTTP_API.getCallDetailsByPrescId}`, {
        prescriptionId: this.prescriptionId,
        userId: this.$store.getters.user.id
      })

      this.callId = res.data.id
      this.isSessionHost = res.data.isSessionHost
      this.meetingName = res.data.meetingName

      $('#video_call').modal('show');

      this.$emit('closechat')

      // Init camera preview
      if (this.callType === 'video') {
        await this.$meet.StartCameraPreview({
          videoTagId: "preview-video",
          deviceId: this.selectedDefaultCamera.deviceId
        });

        this.isCameraEnabled = true

      } else {
        this.$meet.StopCameraPreview()
        this.isCameraEnabled = false
      }

      // Init microphone preview
      await this.$meet.StartMicrophonePreview({
        deviceId: this.selectedDefaultMicrophone.deviceId
      });

      this.showLoader(false)
    },

    async createCallRequest() {
      try {
        this.isCallRequested = true

        this.$socket.emit('REQUEST', {
          command: 'MEET:REQUEST_CALL',
          meetingId: this.callId,
          userId: this.$store.getters.user.id,
          connectionId: this.connectionId,
          userImage: this.$store.getters.user.profile_image_url,
          userName: `${this.$store.getters.user.first_name} ${this.$store.getters.user.last_name}`,
          callType: this.callType
        })
      } catch (e) {
        console.error(e.message)
        this.isCallRequested = false
        $('#video_call').modal('hide');
        Swal.fire('Call Failed!', e.message, 'error')
      }
    },

    // Toggle function on checkbox button
    async ToggleCamera() {
      this.isCameraEnabled ? this.$meet.StopCameraPreview() : this.$meet.StartCameraPreview(this.selectedDefaultCamera)
      this.isCameraEnabled = !this.isCameraEnabled
    },

    // Toggle function on checkbox button
    async ToggleMicrophone() {
      this.isMicrophoneEnabled ? this.$meet.StopMicrophonePreview() : this.$meet.StartMicrophonePreview(this.selectedDefaultMicrophone)
      this.isMicrophoneEnabled = !this.isMicrophoneEnabled
    },

    async changeCamera() {
      try {
        await this.$meet.SetDefaultCamera(this.selectedDefaultCamera.deviceId);
      } catch (e) {
        toast.error('Failed to change camera', {
          duration: 2000,
          position: "top-right",
          theme: "auto",
        });
      }
    },

    async changeMicrophone() {
      try {
        await this.$meet.SetDefaultMicrophone(this.selectedDefaultMicrophone.deviceId);
      } catch (e) {
        toast.error('Failed to change microphone', {
          duration: 2000,
          position: "top-right",
          theme: "auto",
        });
      }
    },

    async changeSpeaker() {
      try {
        await this.$meet.SetDefaultSpeaker(this.selectedDefaultSpeaker.deviceId);
      } catch (e) {
        toast.error('Failed to change audio output', {
          duration: 2000,
          position: "top-right",
          theme: "auto",
        });
      }
    },

    async LoadHardwareDevices() {
      this.cameraDevices = [];
      this.microphoneDevices = [];
      this.speakerDevices = [];

      try {

        if (this.callType === 'video') this.cameraDevices = await this.$meet.GetAvailableCameras();
        this.microphoneDevices = await this.$meet.GetAvailableMicrophones();
        this.speakerDevices = await this.$meet.GetAvailableSpeakers();

        if (this.callType === 'video' && this.cameraDevices.length > 0) this.selectedDefaultCamera = this.cameraDevices[0];
        if (this.microphoneDevices.length > 0) this.selectedDefaultMicrophone = this.microphoneDevices[0];
        if (this.speakerDevices.length > 0) this.selectedDefaultSpeaker = this.speakerDevices[0];

      } catch (e) {
        console.log("Load device error : ", e);
      }
    },

    sendMessage() {
      this.$socket.emit("REQUEST", {
        command: "POST:WRITE_MESSAGE",
        userId: this.$store.getters.user.id,
        prescriptionId: this.prescriptionId,
        message: this.message,
      });

      this.message = "";
    },
  },
  beforeDestroy() {
    // console.log("clearInterval");
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    if (this.waitInterval) {
      clearInterval(this.waitInterval)
      this.waitInterval = null
    }

    this.sockets.unsubscribe('MEET:REQUEST_CALL_RESPONSE')
  },
};
</script>
<style scoped>
.chat-page {
  height: 100%;
}

.chat-window .chat-scroll {
  max-height: 100vh;
  /* height: calc(100vh - 400px); */
}

.chat-body {
  height: 93vh;
}

.video-poster {
  width: 100%;
  height: 350px;
  background-repeat: no-repeat;
  background-image: url('../../assets/img/meet/user-screen.jpg');
  background-size: cover;
}

.meet-control-btn {
  border-radius: 50% !important;
  height: 60px !important;
  width: 60px !important;
  font-size: 30px !important;
  color: white !important;
}
</style>