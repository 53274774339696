<template>
  <div id="app">
    <!-- Generic loader -->
    <loader></loader>

    <!-- Generic alert box -->
    <alert-modal />

    <!-- Incoming call modal -->
    <div class="modal fade call-modal" data-backdrop="static" data-keyboard="false" id="incoming_call">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body" v-if="incomingCallDetails.prescriptionId" style="padding: 20px;">
            <IncomingCall :prescriptionId="incomingCallDetails.prescriptionId" :userImage="incomingCallDetails.userImage"
              :userName="incomingCallDetails.userName" :requestId="incomingCallDetails.requestId"
              :callId="incomingCallDetails.callId" :callType="incomingCallDetails.callType" />
          </div>
        </div>
      </div>
    </div>

    <!-- Router view -->
    <router-view :key="$route.fullPath"> </router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import commonMixin from "./mixins/common";
import IncomingCall from "./components/IncomingCall.vue";

export default {
  name: "App",
  mixins: [commonMixin],
  components: {
    IncomingCall
  },
  data () {
    return {
      incomingCallDetails: {}
    }
  },
  methods: {
    ...mapActions(["validateSession", "getUserDetails"]),
  },
  async mounted () {

    // console.log(this.$socket)
    this.sockets.subscribe('MEET:INCOMING_CALL_REQUEST', (data) => {
      this.incomingCallDetails = data
      $('#incoming_call').modal('show')
    });

    try {
      // Validate local token first
      let token = localStorage.getItem("user-token");

      if (token) {
        await this.validateSession(token);
        const decodeToken = this.parseJwt(token);
        if (decodeToken) {
          const user = await this.getUserDetails(decodeToken.sub);
          switch (user.role) {
            case "patient":

              this.$socket.emit("REQUEST", {
                command: "SET:USER_ID",
                userId: this.$store.getters.user.id,
              });

              if (!user.is_registration_complete) {
                this.$router.push(
                  `/patient/step-${user.current_registration_step}`
                );
              }
              break;
            case "provider":
              this.$socket.emit("REQUEST", {
                command: "SET:USER_ID",
                userId: this.$store.getters.user.id,
              });

              if (!user.is_registration_complete) {
                this.$router.push(
                  `/provider/step-${user.current_registration_step}`
                );
              }

              break;
          }
        }
      }
    } catch (e) {
      localStorage.removeItem("user-token");
      this.$router.push("/");
    }
  },
};
</script>
<style>
body {
  -moz-transform: scale(0.9, 0.9);
  /* Moz-browsers */
  zoom: 0.9;
  /* Other non-webkit browsers */
  zoom: 90%;
  /* Webkit browsers */
}

.modal-backdrop.show {
  width: 100%;
  height: 100%;
}
</style>