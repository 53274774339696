<template>
  <!-- Latest Posts -->
  <div class="card post-widget">
    <div class="card-header">
      <h4 class="card-title">Latest Posts</h4>
    </div>
    <div class="card-body">
      <ul class="latest-posts">
        <li>
          <div class="post-thumb">
            <router-link to="/provider/blog-details">
              <img
                class="img-fluid"
                src="../../assets/img/blog/blog-thumb-01.jpg"
                alt=""
              >
            </router-link>
          </div>
          <div class="post-info">
            <h4>
              <router-link to="/provider/blog-details">Doccure – Making your clinic painless visit?</router-link>
            </h4>
            <p>4 Dec 2019</p>
          </div>
        </li>
        <li>
          <div class="post-thumb">
            <router-link to="/provider/blog-details">
              <img
                class="img-fluid"
                src="../../assets/img/blog/blog-thumb-02.jpg"
                alt=""
              >
            </router-link>
          </div>
          <div class="post-info">
            <h4>
              <router-link to="/provider/blog-details">What are the benefits of Online Doctor Booking?</router-link>
            </h4>
            <p>3 Dec 2019</p>
          </div>
        </li>
        <li>
          <div class="post-thumb">
            <router-link to="/provider/blog-details">
              <img
                class="img-fluid"
                src="../../assets/img/blog/blog-thumb-03.jpg"
                alt=""
              >
            </router-link>
          </div>
          <div class="post-info">
            <h4>
              <router-link to="/provider/blog-details">Benefits of consulting with an Online Doctor</router-link>
            </h4>
            <p>3 Dec 2019</p>
          </div>
        </li>
        <li>
          <div class="post-thumb">
            <router-link to="/provider/blog-details">
              <img
                class="img-fluid"
                src="../../assets/img/blog/blog-thumb-04.jpg"
                alt=""
              >
            </router-link>
          </div>
          <div class="post-info">
            <h4>
              <router-link to="/provider/blog-details">5 Great reasons to use an Online Doctor</router-link>
            </h4>
            <p>2 Dec 2019</p>
          </div>
        </li>
        <li>
          <div class="post-thumb">
            <router-link to="/provider/blog-details">
              <img
                class="img-fluid"
                src="../../assets/img/blog/blog-thumb-05.jpg"
                alt=""
              >
            </router-link>
          </div>
          <div class="post-info">
            <h4>
              <router-link to="/provider/blog-details">Online Doctor Appointment Scheduling</router-link>
            </h4>
            <p>1 Dec 2019</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- /Latest Posts -->
</template>