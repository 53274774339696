<template>
  <div class="main-wrapper">
    <!-- Home Banner -->
    <section class="section section-search" style="height: 100vh">
      <div class="container-fluid">
        <div class="banner-wrapper text-center">
          <router-link to="/">
            <img src="../assets/img/logo.png" class="img-fluid mb-5" alt="StepaheadHealth" width="300" />
          </router-link>

          <div class="banner-header text-center">
            <h1>404</h1>
            <p class="mb-3">OOPS! PAGE NOT FOUND.</p>
            <router-link to="/">
              <button class="btn btn-info">Go to main page</button>
            </router-link>
          </div>

          <!-- Search -->
          <div class="text-center">
            <p>© 2023. StepAhead Health Inc. All Rights Reserved.</p>
          </div>
          <!-- /Search -->
        </div>
      </div>
    </section>
    <!-- /Home Banner -->
  </div>
</template>

<script>
</script>