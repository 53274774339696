/* eslint-disable */
import 'regenerator-runtime/runtime'
import protooClient from 'protoo-client'
import bowser from 'bowser'
import { v4 as uuidv4 } from 'uuid'
import * as mediasoupClient from 'mediasoup-client'
import store from '../store'
import broadcastConfiguration from './meet.config'

//  Global Variables
let mediaStreamTracks = []

class StorageHelper {
  constructor() {

    // Storage Keys
    this.STORAGE_HARDWARE_SETTINGS_KEY = 'hardwareSettings'

    // In-memory data stores
    this.STORAGE_MEDIASTREAMS = new Map()

    // Save default hardware settings
    this.hardwareSettings = null
  }

  saveHardwareSettings (settings) {
    const obj = {
      ...this.getHardwareSettings(),
      ...settings
    }
    localStorage.setItem(this.STORAGE_HARDWARE_SETTINGS_KEY, JSON.stringify(obj))
    return obj
  }

  getHardwareSettings () {
    return JSON.parse(localStorage.getItem(this.STORAGE_HARDWARE_SETTINGS_KEY))
  }
}


class MediasoupWrapper {
  constructor({
    broadcastConfiguration,
    sessionId,
    sessionName,
    sessionRole,
    userName,
    useSimulcast,
    useSharingSimulcast,
    forceTcp,
    produce,
    consume,
    forceH264,
    forceVP9,
    svc,
    datachannel,
    isHlsBroadcast,
    serverUrl
  }) {

    this.storageHelper = new StorageHelper()

    // Set video configuration
    this.VIDEO_CONSTRAINTS =
    {
      mvga: (broadcastConfiguration.video) ? broadcastConfiguration.video : { width: { ideal: 480 }, height: { ideal: 360 }, frameRate: { ideal: 10, max: 15 } }
    }

    // Set audio configuration
    this.AUDIO_CONSTRANTS = (broadcastConfiguration.audio) ? broadcastConfiguration.audio : {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: false,
      googEchoCancellation: true,
      googAutoGainControl: false,
      googExperimentalAutoGainControl: false,
      googNoiseSuppression: true,
      googExperimentalNoiseSuppression: true,
      googHighpassFilter: true,
      googTypingNoiseDetection: true,
      googBeamforming: false,
      googArrayGeometry: false,
      googAudioMirroring: false,
      googNoiseReduction: true,
      mozNoiseSuppression: true,
      mozAutoGainControl: false
    }

    this.PC_PROPRIETARY_CONSTRAINTS =
    {
      optional: [{ googDscp: true }]
    }

    // Used for simulcast webcam video.
    this.WEBCAM_SIMULCAST_ENCODINGS =
      (broadcastConfiguration.videoSimulcastEncodings) ? broadcastConfiguration.videoSimulcastEncodings
        : [
          // { scaleResolutionDownBy: 4, maxBitrate: 128000, maxFramerate: 15 },
          { scaleResolutionDownBy: 2, maxBitrate: 200000, maxFramerate: 15 }
          // { scaleResolutionDownBy: 1, maxBitrate: 300000, maxFramerate: 15 }
        ]

    // Used for VP9 webcam video.
    this.WEBCAM_KSVC_ENCODINGS =
      [
        { scalabilityMode: 'S3T3_KEY' }
      ]

    // Used for simulcast screen sharing.
    this.SCREEN_SHARING_SIMULCAST_ENCODINGS =
      (broadcastConfiguration.screenSimulcastEncodings) ? broadcastConfiguration.screenSimulcastEncodings
        : [
          { scaleResolutionDownBy: 1, dtx: true, maxBitrate: 256000 }
          // { scaleResolutionDownBy: 1, dtx: true, maxBitrate: 512000 }
        ]

    // Used for VP9 screen sharing.
    this.SCREEN_SHARING_SVC_ENCODINGS =
      [
        { scalabilityMode: 'S3T3', dtx: true }
      ]

    // HLS parameter
    this._isHlsBroadcast = isHlsBroadcast

    // Create new peer ID
    this._peerId = uuidv4()

    // Closed flag.
    // @type {Boolean}
    this._closed = false

    // Display name.
    // @type {String}
    this._userName = userName

    // Session ID
    this._sessionId = sessionId

    // Session Name
    this._sessionName = sessionName

    // Device info.
    // @type {Object}
    this._device = this._getBrowserInfo()

    // Whether we want to force RTC over TCP.
    // @type {Boolean}
    this._forceTcp = forceTcp

    // Whether we want to produce audio/video.
    // @type {Boolean}
    this._produce = produce

    // Whether we should consume.
    // @type {Boolean}
    this._consume = consume

    // Whether we want DataChannels.
    // @type {Boolean}
    this._useDataChannel = datachannel

    // Force H264 codec for sending.
    this._forceH264 = Boolean(forceH264)

    // Force VP9 codec for sending.
    this._forceVP9 = Boolean(forceVP9)

    // MediaStream of the external video.
    // @type {MediaStream}
    this._externalVideoStream = null

    // Next expected dataChannel test number.
    // @type {Number}
    this._nextDataChannelTestNumber = 0

    // Whether simulcast should be used.
    // @type {Boolean}
    this._useSimulcast = useSimulcast

    // Whether simulcast should be used in desktop sharing.
    // @type {Boolean}
    this._useSharingSimulcast = useSharingSimulcast

    // Protoo URL.
    // @type {String}
    this._protooUrl = `wss://${serverUrl}/meeting/?roomId=${this._sessionId}&peerId=${this._peerId}`

    // protoo-client Peer instance.
    // @type {protooClient.Peer}
    this._protoo = null

    // mediasoup-client Device instance.
    // @type {mediasoupClient.Device}
    this._mediasoupDevice = null

    // mediasoup Transport for sending.
    // @type {mediasoupClient.Transport}
    this._sendTransport = null

    // mediasoup Transport for receiving.
    // @type {mediasoupClient.Transport}
    this._recvTransport = null

    // Local mic mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._micProducer = null

    // Local webcam mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._webcamProducer = null

    // Local share mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._shareProducer = null

    // Commanding DataProducer
    // @type {mediasoupClient.DataProducer}
    this._commandDataProducer = null

    // mediasoup Consumers.
    // @type {Map<String, mediasoupClient.Consumer>}
    this._consumers = new Map()

    // mediasoup DataConsumers.
    // @type {Map<String, mediasoupClient.DataConsumer>}
    this._dataConsumers = new Map()

    // Map of webcam MediaDeviceInfos indexed by deviceId.
    // @type {Map<String, MediaDeviceInfos>}
    this._webcams = new Map()

    // Local Webcam.
    // @type {Object} with:
    // - {MediaDeviceInfo} [device]
    // - {String} [resolution] - 'qvga' / 'vga' / 'hd'.
    this._webcam = {
      device: null,
      resolution: 'mvga'
    }

    // Media recorder instance
    this._mediaRecorder = null

    // Set custom SVC scalability mode.
    if (svc) {
      this.WEBCAM_KSVC_ENCODINGS[0].scalabilityMode = `${svc}_KEY`
      this.SCREEN_SHARING_SVC_ENCODINGS[0].scalabilityMode = svc
    }

    // Set session host
    if (sessionRole === 'start') store.dispatch('SET_ROOM_HOST_ID', { sessionHostId: this._peerId })

    // Set self information in store
    store.dispatch('SET_ME', {
      peerId: this._peerId,
      userName,
      device: this._getBrowserInfo()
    })
  }

  join () {

    return new Promise((resolve, reject) => {

      // Create connection to cluster
      const protooTransport = new protooClient.WebSocketTransport(this._protooUrl);

      this._protoo = new protooClient.Peer(protooTransport)

      store.dispatch('SET_ROOM_STATE', 'connecting')



      // On open connection
      this._protoo.on('open', async () => {

        await this._startConnections()

        return resolve(true)
      })



      // On connection failure
      this._protoo.on('failed', () => {
        return reject(new Error('WebSocket connection failed'))
      })

      // On connection disconnect
      this._protoo.on('disconnected', () => {
        // Close mediasoup Transports.
        if (this._sendTransport) {
          this._sendTransport.close()
          this._sendTransport = null
        }

        if (this._recvTransport) {
          this._recvTransport.close()
          this._recvTransport = null
        }

        store.dispatch('SET_ROOM_STATE', 'closed')
      })

      // On connection close
      this._protoo.on('close', () => {
        if (this._closed) { return }
        this.close()
      })

      // eslint-disable-next-line no-unused-vars
      this._protoo.on('request', async (request, accept, reject) => {

        switch (request.method) {
          case 'newConsumer':
            {
              if (!this._consume) {
                console.warn('Refused to consume new consumer')
                break
              }

              const {
                peerId,
                producerId,
                id,
                kind,
                rtpParameters,
                type,
                appData,
                producerPaused
              } = request.data

              try {
                const consumer = await this._recvTransport.consume(
                  {
                    id,
                    producerId,
                    kind,
                    rtpParameters,
                    appData: { ...appData, peerId } // Trick.
                  })

                // Set session Host ID
                if (!store.getters.meet.session.sessionHostId) store.dispatch('SET_ROOM_HOST_ID', { sessionHostId: appData.hostId })

                // Store in local map.
                this._consumers.set(consumer.id, consumer)

                consumer.on('transportclose', () => {
                  this._consumers.delete(consumer.id)
                })

                const { spatialLayers, temporalLayers } =
                  mediasoupClient.parseScalabilityMode(
                    consumer.rtpParameters.encodings[0].scalabilityMode)

                // Set Peer can speak
                if (kind === 'audio' && peerId !== this._peerId) {
                  store.dispatch('SET_PEER_CAN_SPEAK', {
                    peerId: peerId,
                    value: true
                  })
                }

                // Set Peer can speak
                if (kind === 'video' && peerId !== this._peerId) {
                  store.dispatch('SET_PEER_CAM_ENABLED', {
                    peerId: peerId,
                    value: true
                  })
                }

                // Set session mute all state if session host
                if (store.getters.meet.session.sessionHostId === store.getters.meet.self.id && consumer.track.kind === 'audio') {
                  store.dispatch('SET_SESSION_MUTED', false)
                }

                await store.dispatch('ADD_CONSUMER', {
                  consumer: {
                    id: consumer.id,
                    type: type,
                    locallyPaused: false,
                    remotelyPaused: producerPaused,
                    rtpParameters: consumer.rtpParameters,
                    spatialLayers: spatialLayers,
                    temporalLayers: temporalLayers,
                    preferredSpatialLayer: spatialLayers - 1,
                    preferredTemporalLayer: temporalLayers - 1,
                    priority: 1,
                    codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
                    track: consumer.track,
                    peerId: peerId,
                    appData: appData
                  },
                  peerId: peerId
                })

                // We are ready. Answer the protoo request so the server will
                // resume this Consumer (which was paused for now if video).
                accept()

                // If audio-only mode is enabled, pause it.
                if (consumer.kind === 'video' && store.getters.meet.self.audioOnly) { this._pauseConsumer(consumer) }
              } catch (error) {
                console.error('New consumer error: ', error)
              }

              break
            }

          case 'newDataConsumer':
            {
              if (!this._consume) {
                console.warn('Refused to consume new data consumer')
                break
              }

              if (!this._useDataChannel) {
                console.warn('Refused to consume new data consumer')
                break
              }

              const {
                peerId, // NOTE: Null if bot.
                dataProducerId,
                id,
                sctpStreamParameters,
                label,
                protocol,
                appData
              } = request.data

              try {
                const dataConsumer = await this._recvTransport.consumeData(
                  {
                    id,
                    dataProducerId,
                    sctpStreamParameters,
                    label,
                    protocol,
                    appData: { ...appData, peerId } // Trick.
                  })

                // Store in the map.
                this._dataConsumers.set(dataConsumer.id, dataConsumer)

                dataConsumer.on('transportclose', () => {
                  this._dataConsumers.delete(dataConsumer.id)
                })

                dataConsumer.on('close', () => {
                  this._dataConsumers.delete(dataConsumer.id)
                })

                dataConsumer.on('error', (error) => {
                  console.error('Data consumer error: ', error)
                })

                dataConsumer.on('message', async (message) => {
                  switch (dataConsumer.label) {
                    case 'command':
                      try {
                        let remoteCommandMessage = JSON.parse(message)

                        if (remoteCommandMessage.command === 'chatMessage') {
                          let parseMessage = JSON.parse(remoteCommandMessage.peerId)
                          store.dispatch('INSERT_CHATMESSAGE', {
                            ID: `${this._userName}-${uuidv4()}`,
                            Time: new Date().toISOString(),
                            Username: parseMessage.userName,
                            Message: parseMessage.message,
                            Extra: parseMessage.extra,
                            isRead: false
                          })
                        }

                        // Enable Camera
                        if (remoteCommandMessage.command === 'enableCam' && remoteCommandMessage.peerId === store.getters.meet.self.id) {
                          await this.enableWebcam()
                        }

                        // Disable Camera
                        if (remoteCommandMessage.command === 'disableCam' && remoteCommandMessage.peerId === store.getters.meet.self.id) {
                          await this.disableWebcam()
                        }

                        // Enable Microphone
                        if (remoteCommandMessage.command === 'enableMic' && remoteCommandMessage.peerId === store.getters.meet.self.id) {
                          await this.enableMic()
                        }

                        // Disable Microphone
                        if (remoteCommandMessage.command === 'disableMic' && remoteCommandMessage.peerId === store.getters.meet.self.id) {
                          await this.disableMic()
                        }

                        // Mute All
                        if (remoteCommandMessage.command === 'muteAll' && remoteCommandMessage.peerId === store.getters.meet.self.id) {
                          await this.disableMic()
                          store.dispatch('SET_SESSION_MUTED', true)
                        }

                        // Unmute All
                        if (remoteCommandMessage.command === 'unmuteAll' && remoteCommandMessage.peerId === store.getters.meet.self.id) {
                          await this.enableMic()
                          store.dispatch('SET_SESSION_MUTED', false)
                        }

                        // Set Current typing user
                        if (remoteCommandMessage.command === 'isTyping' && remoteCommandMessage.peerId !== store.getters.meet.self.id) {
                          store.dispatch('SET_ACTIVE_USER_TYPING', remoteCommandMessage.peerId)
                          setTimeout(() => {
                            store.dispatch('SET_ACTIVE_USER_TYPING', null)
                          }, 1000)
                        }

                        // Raise Hand
                        if (remoteCommandMessage.command === 'raiseQuestion' && store.getters.meet.session.sessionHostId === this._peerId) {
                          store.dispatch('SET_PEER_HAND_RAISED', { peerId: remoteCommandMessage.peerId, value: true })
                        }
                      } catch {
                        // None
                      }
                      break
                  }
                })

                // Save data consumer in store
                await store.dispatch('ADD_DATA_CONSUMER', {
                  dataConsumer: {
                    id: dataConsumer.id,
                    sctpStreamParameters: dataConsumer.sctpStreamParameters,
                    label: dataConsumer.label,
                    protocol: dataConsumer.protocol
                  },
                  peerId: peerId
                })

                // We are ready. Answer the protoo request.
                accept()
              } catch (error) {
                console.error(`Error creating a DataConsumer: ${error}`)
                throw error
              }

              break
            }
        }
      })

      this._protoo.on('notification', (notification) => {
        switch (notification.method) {
          case 'producerScore':
            {
              const { producerId, score } = notification.data
              store.dispatch('SET_PRODUCER_SCORE', {
                producerId, score
              })
              break
            }

          case 'newPeer':
            {
              const peer = notification.data
              if (peer.userName !== 'stepaheadhealth-bot-recorder')
                store.dispatch('ADD_PEER', { peer: { ...peer, consumers: [], dataConsumers: [], raiseHand: false, isMicEnabled: false, isCamEnabled: false } })
              break
            }

          case 'peerClosed':
            {
              const { peerId } = notification.data
              store.dispatch('REMOVE_PEER', { peerId })
              // removeElement(peerId)
              break
            }

          case 'peerDisplayNameChanged':
            {
              const { peerId, userName } = notification.data
              store.dispatch('SET_PEER_DISPLAY_NAME', { userName, peerId })
              break
            }

          case 'consumerClosed':
            {
              const { consumerId } = notification.data
              const consumer = this._consumers.get(consumerId)

              if (!consumer) break

              consumer.close()
              this._consumers.delete(consumerId)

              const { peerId } = consumer.appData
              store.dispatch('REMOVE_CONSUMER', { consumerId, peerId })

              // if (consumer.track.kind === 'video') removeElement(`${peerId}-video`)
              // else removeElement(`${peerId}-audio`)

              // Set Peer can speak
              if (consumer.track.kind === 'audio' && peerId !== this._peerId) {
                store.dispatch('SET_PEER_CAN_SPEAK', {
                  peerId: peerId,
                  value: false
                })
              }

              // Set Peer camera status
              if (consumer.track.kind === 'video' && peerId !== this._peerId) {
                store.dispatch('SET_PEER_CAM_ENABLED', {
                  peerId: peerId,
                  value: false
                })
              }

              break
            }

          case 'consumerPaused':
            {
              const { consumerId } = notification.data
              const consumer = this._consumers.get(consumerId)

              if (!consumer) { break }
              consumer.pause()

              store.dispatch('SET_CONSUMER_PAUSED', { consumerId, originator: 'remote' })

              // Set Peer can speak
              if (consumer.track.kind === 'audio' && consumer.appData.peerId !== this._peerId) {
                store.dispatch('SET_PEER_CAN_SPEAK', {
                  peerId: consumer.appData.peerId,
                  value: false
                })
              }

              // Set Peer camera status
              if (consumer.track.kind === 'video' && consumer.appData.peerId !== this._peerId) {
                store.dispatch('SET_PEER_CAM_ENABLED', {
                  peerId: consumer.appData.peerId,
                  value: false
                })
              }
              break
            }

          case 'consumerResumed':
            {
              const { consumerId } = notification.data
              const consumer = this._consumers.get(consumerId)

              if (!consumer) { break }
              consumer.resume()

              store.dispatch('SET_CONSUMER_RESUMED', { consumerId, originator: 'remote' })

              // Set Peer can speak
              if (consumer.track.kind === 'audio' && consumer.appData.peerId !== this._peerId) {
                store.dispatch('SET_PEER_CAN_SPEAK', {
                  peerId: consumer.appData.peerId,
                  value: true
                })
              }

              // Set Peer camera status
              if (consumer.track.kind === 'video' && consumer.appData.peerId !== this._peerId) {
                store.dispatch('SET_PEER_CAM_ENABLED', {
                  peerId: consumer.appData.peerId,
                  value: true
                })
              }

              break
            }

          case 'consumerLayersChanged':
            {
              const { consumerId, spatialLayer, temporalLayer } = notification.data
              const consumer = this._consumers.get(consumerId)

              if (!consumer) { break }

              store.dispatch('SET_CONSUMER_CURRENT_LAYERS', { consumerId, spatialLayer, temporalLayer })
              break
            }

          case 'consumerScore':
            {
              const { consumerId, score } = notification.data
              store.dispatch('SET_CONSUMER_SCORE', { consumerId, score })
              break
            }

          case 'dataConsumerClosed':
            {
              const { dataConsumerId } = notification.data
              const dataConsumer = this._dataConsumers.get(dataConsumerId)

              if (!dataConsumer) { break }

              dataConsumer.close()
              this._dataConsumers.delete(dataConsumerId)

              const { peerId } = dataConsumer.appData
              store.dispatch('REMOVE_DATA_CONSUMER', { dataConsumerId, peerId })
              break
            }

          case 'activeSpeaker':
            {
              const { peerId } = notification.data
              store.dispatch('SET_ROOM_ACTIVE_SPEAKER', { peerId })
              break
            }

          default:
        }
      })
    })
  }

  async _startConnections () {

    try {
      this._mediasoupDevice = new mediasoupClient.Device()

      const routerRtpCapabilities = await this._protoo.request('getRouterRtpCapabilities')

      await this._mediasoupDevice.load({ routerRtpCapabilities })

      // NOTE: Stuff to play remote audios due to browsers' new autoplay policy.
      // Just get access to the mic and DO NOT close the mic track for a while.
      // Super hack!
      {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: this.AUDIO_CONSTRANTS
        })

        mediaStreamTracks.push(stream)
        const audioTrack = stream.getAudioTracks()[0]
        audioTrack.enabled = false
        setTimeout(() => audioTrack.stop(), 120000)
      }

      // Create producer transport
      if (this._produce) {
        const transportInfo = await this._protoo.request(
          'createWebRtcTransport',
          {
            forceTcp: this._forceTcp,
            producing: true,
            consuming: false,
            sctpCapabilities: this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined
          })

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters
        } = transportInfo

        this._sendTransport = this._mediasoupDevice.createSendTransport(
          {
            id,
            iceParameters,
            iceCandidates,
            dtlsParameters,
            sctpParameters,
            iceServers: [],
            proprietaryConstraints: this.PC_PROPRIETARY_CONSTRAINTS
          })

        this._sendTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
          this._protoo.request(
            'connectWebRtcTransport',
            {
              transportId: this._sendTransport.id,
              dtlsParameters
            })
            .then(callback)
            .catch(errback)
        })

        this._sendTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
          try {
            // eslint-disable-next-line no-shadow
            const { id } = await this._protoo.request(
              'produce',
              {
                transportId: this._sendTransport.id,
                kind,
                rtpParameters,
                appData: (store.getters.meet.session.sessionHostId === this._peerId) ? { ...appData, hostId: this._peerId } : appData
              })

            callback({ id })
          } catch (error) {
            errback(error)
          }
        })

        this._sendTransport.on('producedata', async (
          {
            sctpStreamParameters,
            label,
            protocol,
            appData
          },
          callback,
          errback
        ) => {
          try {
            // eslint-disable-next-line no-shadow
            const { id } = await this._protoo.request('produceData', {
              transportId: this._sendTransport.id,
              sctpStreamParameters,
              label,
              protocol,
              appData
            })

            callback({ id })
          } catch (error) {
            errback(error)
          }
        })
      }

      // Create consumer transport
      if (this._consume) {
        const transportInfo = await this._protoo.request(
          'createWebRtcTransport',
          {
            forceTcp: this._forceTcp,
            producing: false,
            consuming: true,
            sctpCapabilities: this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined
          })

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters
        } = transportInfo

        this._recvTransport = this._mediasoupDevice.createRecvTransport(
          {
            id,
            iceParameters,
            iceCandidates,
            dtlsParameters,
            sctpParameters,
            iceServers: []
          })

        this._recvTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
          this._protoo.request(
            'connectWebRtcTransport',
            {
              transportId: this._recvTransport.id,
              dtlsParameters
            })
            .then(callback)
            .catch(errback)
        })
      }

      // Join now into the room
      const { peers } = await this._protoo.request(
        'join',
        {
          userName: this._userName,
          device: this._device,
          rtpCapabilities: this._consume
            ? this._mediasoupDevice.rtpCapabilities
            : undefined,
          sctpCapabilities: this._useDataChannel && this._consume
            ? this._mediasoupDevice.sctpCapabilities
            : undefined
        })

      // Set connection state
      store.dispatch('SET_ROOM_STATE', 'connected')

      // Set Peers
      for (const peer of peers) {
        if (peer.userName !== 'stepaheadhealth-bot-recorder')
          store.dispatch('ADD_PEER', { peer: { ...peer, consumers: [], dataConsumers: [], raiseHand: false, isMicEnabled: false, isCamEnabled: false } })
      }

      // If you are a producer, start the camera and microphone
      if (this._produce) {
        // Set our media capabilities.
        store.dispatch('SET_MEDIA_CAPABILITIES', {
          canSendMic: this._mediasoupDevice.canProduce('audio'),
          canSendWebcam: this._mediasoupDevice.canProduce('video')
        })

        // Start microphone
        // if (store.getters.meet.self.deviceInitSettings.enableMicrophone) this.enableMic()
        if (this.GET_HARDWARE_SETTINGS().settings.microphone) this.enableMic()

        // Start webcamera
        // if (store.getters.meet.self.deviceInitSettings.enableCamera) this.enableWebcam()
        if (this.GET_HARDWARE_SETTINGS().settings.camera) this.enableWebcam()

        // Start command producer if not host
        // if (store.getters.meet.session.sessionHostId !== this._peerId) this.enableCommandDataProducer()

        // For default producer,  On connected, start chat channels
        this._sendTransport.on('connectionstatechange', (connectionState) => {
          if (connectionState === 'connected') {
            this.enableCommandDataProducer()
          }
        })
      }
    } catch (error) {
      console.error(error)
      this.close()
      throw new Error('Error connecting to the session: ', error)
    }
  }

  getLocalStorage () {
    const data = JSON.parse(localStorage.getItem('stepaheadhealth'))
    return data == null ? undefined : data
  }

  // Get combined store results
  GET_HARDWARE_SETTINGS () {
    const local = this.getLocalStorage()
    return {
      ...(local) ? local : {}
    }
  }

  close () {
    if (this._closed) { return }

    this._closed = true

    // Close protoo Peer
    this._protoo.close()

    // Close mediasoup Transports.
    if (this._sendTransport) { this._sendTransport.close() }
    if (this._recvTransport) { this._recvTransport.close() }

    this._protoo = null
    this._sendTransport = null
    this._recvTransport = null
    store.dispatch('RESET_RTCP')
  }

  async enableWebcam () {
    if (this._webcamProducer) { return } else if (this._shareProducer) { await this.disableScreenShare() }

    if (!this._mediasoupDevice.canProduce('video')) {
      throw new Error('Video device error')
    }

    let track
    let device

    try {
      await this._updateWebcams()
      device = this._webcam.device

      const { resolution } = this._webcam

      if (!device) { throw new Error('no webcam devices') }

      const stream = await navigator.mediaDevices.getUserMedia(
        {
          video:
          {
            deviceId: { ideal: device.deviceId },
            ...this.VIDEO_CONSTRAINTS[resolution]
          }
        })

      mediaStreamTracks.push(stream)
      track = stream.getVideoTracks()[0]

      let encodings
      let codec
      const codecOptions =
      {
        videoGoogleStartBitrate: 1000
      }

      if (this._forceH264) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs
          .find((c) => c.mimeType.toLowerCase() === 'video/h264')

        if (!codec) {
          throw new Error('desired H264 codec+configuration is not supported')
        }
      } else if (this._forceVP9) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs
          .find((c) => c.mimeType.toLowerCase() === 'video/vp9')

        if (!codec) {
          throw new Error('desired VP9 codec+configuration is not supported')
        }
      }

      if (this._useSimulcast) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec = this._mediasoupDevice
          .rtpCapabilities
          .codecs
          .find((c) => c.kind === 'video')

        if (
          (this._forceVP9 && codec) ||
          firstVideoCodec.mimeType.toLowerCase() === 'video/vp9'
        ) {
          encodings = this.WEBCAM_KSVC_ENCODINGS
        } else {
          encodings = this.WEBCAM_SIMULCAST_ENCODINGS
        }
      }

      this._webcamProducer = await this._sendTransport.produce({
        track,
        encodings,
        codecOptions,
        codec
      })

      await store.dispatch('ADD_PRODUCER', {
        producer: {
          id: this._webcamProducer.id,
          mediaType: 'video',
          deviceLabel: device.label,
          type: this._getWebcamType(device),
          paused: this._webcamProducer.paused,
          track: this._webcamProducer.track,
          rtpParameters: this._webcamProducer.rtpParameters,
          codec: this._webcamProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
        }
      })

      store.dispatch('SET_DEVICE_INIT_SETTINGS', {
        ...store.getters.meet.self.deviceInitSettings,
        enableCamera: true
      })

      store.dispatch('SET_DEFAULT_CAMERA_ID', { deviceId: device.deviceId })

      this._webcamProducer.on('transportclose', () => {
        this._webcamProducer = null
      })

      this._webcamProducer.on('trackended', () => {
        this.disableWebcam()
          .catch(() => { })
      })

      store.dispatch('SET_WEBCAM_IN_PROGRESS', true)
    } catch (error) {
      console.error(`Error enabling webcam: ${error}`)
      this.storageHelper.saveHardwareSettings({
        isCameraAvailable: false,
      })

      if (track) { track.stop() }
    }
  }

  async pauseVideoProducer () {
    try {
      if (this._webcamProducer && !this._webcamProducer.paused) {
        await this._protoo.request('pauseProducer', { producerId: this._webcamProducer.id })
        this._webcamProducer.pause()
        store.dispatch('SET_PRODUCER_PAUSED', { producerId: this._webcamProducer.id })
      }

      if (this._shareProducer && !this._shareProducer.paused) {
        await this._protoo.request('pauseProducer', { producerId: this._shareProducer.id })
        this._shareProducer.pause()
        store.dispatch('SET_PRODUCER_PAUSED', { producerId: this._shareProducer.id })
      }

      store.dispatch('SET_VIDEO_TRANSMISSION_PAUSE_STATE', true)
    } catch (e) {
      throw e
    }
  }

  async resumeVideoProducer () {
    try {
      if (this._webcamProducer && this._webcamProducer.paused) {
        await this._protoo.request('resumeProducer', { producerId: this._webcamProducer.id })
        this._webcamProducer.resume()
        store.dispatch('SET_PRODUCER_RESUMED', { producerId: this._webcamProducer.id })
      }

      if (this._shareProducer && this._shareProducer.paused) {
        await this._protoo.request('resumeProducer', { producerId: this._shareProducer.id })
        this._shareProducer.resume()
        store.dispatch('SET_PRODUCER_RESUMED', { producerId: this._shareProducer.id })
      }

      store.dispatch('SET_VIDEO_TRANSMISSION_PAUSE_STATE', false)
    } catch (e) {
      throw e
    }
  }

  async disableWebcam () {
    if (!this._webcamProducer) { return }
    try {
      this._webcamProducer.close()

      await store.dispatch('REMOVE_PRODUCER', { producerId: this._webcamProducer.id })

      await this._protoo.request('closeProducer', { producerId: this._webcamProducer.id })

      this._webcamProducer = null
    } catch (error) {
      console.error(`Error closing server-side webcam Producer: ${error}`)
    }

    store.dispatch('SET_DEVICE_INIT_SETTINGS', {
      ...store.getters.meet.self.deviceInitSettings,
      enableCamera: false
    })

    store.dispatch('SET_WEBCAM_IN_PROGRESS', false)
  }

  async enableMic () {
    if (this._micProducer && this._micProducer.paused) {
      await this.unmuteMicrophone()
      return
    }

    if (!this._mediasoupDevice.canProduce('audio')) {
      throw new Error('Audio device error')
    }

    let track

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: this.AUDIO_CONSTRANTS
      })
      mediaStreamTracks.push(stream)
      track = stream.getAudioTracks()[0]
      this._micProducer = await this._sendTransport.produce(
        {
          track,
          codecOptions:
          {
            opusStereo: 1,
            opusDtx: 1
          }
        })

      await store.dispatch('ADD_PRODUCER', {
        producer: {
          id: this._micProducer.id,
          mediaType: 'audio',
          paused: this._micProducer.paused,
          track: this._micProducer.track,
          rtpParameters: this._micProducer.rtpParameters,
          codec: this._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
        }
      })

      this._micProducer.on('transportclose', () => {
        this._micProducer = null
      })

      this._micProducer.on('trackended', () => {
        console.warn('Microphone disconnected!')
        this.disableMic()
          .catch(() => { })
      })

      store.dispatch('SET_DEVICE_INIT_SETTINGS', {
        ...store.getters.meet.self.deviceInitSettings,
        enableMicrophone: true
      })

      store.dispatch('SET_MICROPHONE_IN_PROGRESS', true)
    } catch (error) {
      console.error(`Error enabling microphone: ${error}`)
      this.storageHelper.saveHardwareSettings({
        isMicrophoneAvailable: false,
      })

      if (track) { track.stop() }
    }
  }

  async disableMic () {
    if (!this._micProducer) { return }
    try {
      this._micProducer.close()
      await store.dispatch('REMOVE_PRODUCER', { producerId: this._micProducer.id })
      await this._protoo.request('closeProducer', { producerId: this._micProducer.id })
      this._micProducer = null
    } catch (error) {
      console.error(`Error closing server-side mic Producer: ${error}`)
    }

    store.dispatch('SET_DEVICE_INIT_SETTINGS', {
      ...store.getters.meet.self.deviceInitSettings,
      enableMicrophone: false
    })

    store.dispatch('SET_MICROPHONE_IN_PROGRESS', false)
  }

  async enableCommandDataProducer () {
    if (!this._useDataChannel) { return }

    try {
      // Create chat DataProducer.
      this._commandDataProducer = await this._sendTransport.produceData(
        {
          ordered: true,
          label: 'command',
          priority: 'high',
          appData: { info: 'remote-procedure-commands' }
        })

      await store.dispatch('ADD_DATA_PRODUCER', {
        dataProducer: {
          id: this._commandDataProducer.id,
          sctpStreamParameters: this._commandDataProducer.sctpStreamParameters,
          label: this._commandDataProducer.label,
          protocol: this._commandDataProducer.protocol
        }
      })

      this._commandDataProducer.on('transportclose', () => {
        this._commandDataProducer = null
      })

      this._commandDataProducer.on('close', () => {
        this._commandDataProducer = null
      })

      this._commandDataProducer.on('error', (error) => {
        console.error(`Remote command data producer error: ${error}`)
      })
    } catch (error) {
      console.error(`Remote command data producer error: ${error}`)
      throw error
    }
  }

  async sendChatMessage (text, extra) {
    if (!this._commandDataProducer) {
      console.error('No Data Producer Found')
      return
    }

    let msgObj = {
      message: text,
      userName: this._userName,
      extra: {}
    }

    // Prepare extra data
    if (extra) {
      try {
        msgObj.extra = extra
      } catch {
        // Ignore error
      }
    }

    try {
      this.sendRemoteCommand('chatMessage', JSON.stringify(msgObj))
      store.dispatch('INSERT_CHATMESSAGE', {
        ID: `${this._userName}-${uuidv4()}`,
        Time: new Date().toISOString(),
        Username: this._userName,
        Message: text,
        Extra: msgObj.extra,
        isRead: false
      })
    } catch (error) {
      console.error(`chat DataProducer.send() failed: ${error}`)
    }
  }

  async sendRemoteCommand (command, peerId) {
    try {
      this._commandDataProducer.send(JSON.stringify({ command, peerId }))
    } catch (error) {
      console.error(`sendRemoteCommand failed: ${error}`)
    }
  }

  async enableScreenShare () {
    if (this._shareProducer) return
    else if (this._webcamProducer) { await this.disableWebcam() }

    if (!this._mediasoupDevice.canProduce('video')) {
      throw new Error('Cannot share screen at the moment')
    }

    let track
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia(
        {
          audio: false,
          video:
          {
            displaySurface: 'monitor',
            logicalSurface: true,
            cursor: true,
            width: { max: 1280 },
            height: { max: 720 },
            frameRate: { max: 20 }
          }
        })

      mediaStreamTracks.push(stream)
      track = stream.getVideoTracks()[0]

      let encodings
      let codec
      const codecOptions =
      {
        videoGoogleStartBitrate: 1000
      }

      if (this._forceH264) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs
          .find((c) => c.mimeType.toLowerCase() === 'video/h264')

        if (!codec) {
          throw new Error('desired H264 codec+configuration is not supported')
        }
      } else if (this._forceVP9) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs
          .find((c) => c.mimeType.toLowerCase() === 'video/vp9')

        if (!codec) {
          throw new Error('desired VP9 codec+configuration is not supported')
        }
      }

      if (this._useSharingSimulcast) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec = this._mediasoupDevice
          .rtpCapabilities
          .codecs
          .find((c) => c.kind === 'video')

        if (
          (this._forceVP9 && codec) ||
          firstVideoCodec.mimeType.toLowerCase() === 'video/vp9'
        ) {
          encodings = this.SCREEN_SHARING_SVC_ENCODINGS
        } else {
          encodings = this.SCREEN_SHARING_SIMULCAST_ENCODINGS
            .map((encoding) => ({ ...encoding, dtx: true }))
        }
      }

      this._shareProducer = await this._sendTransport.produce(
        {
          track,
          encodings,
          codecOptions,
          codec,
          appData:
          {
            share: true
          }
        })

      // Set Producer for screen
      await store.dispatch('ADD_PRODUCER', {
        producer: {
          id: this._shareProducer.id,
          mediaType: 'video',
          type: 'share',
          paused: this._shareProducer.paused,
          track: this._shareProducer.track,
          rtpParameters: this._shareProducer.rtpParameters,
          codec: this._shareProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
        }
      })

      this._shareProducer.on('transportclose', () => {
        this._shareProducer = null
      })

      this._shareProducer.on('trackended', () => {
        this.disableScreenShare()
          .catch(() => { })
      })

      store.dispatch('SET_SHARE_IN_PROGRESS', true)
    } catch (error) {
      console.error('Screen sharing failed: ', error)

      if (error.name !== 'NotAllowedError') {
        throw new Error('Permissions denied for screen sharing')
      }

      if (track) { track.stop() }
      throw new Error('Cannot share screen at the moment')
    }
  }

  async disableScreenShare () {
    if (!this._shareProducer) { return }

    try {
      this._shareProducer.close()

      await store.dispatch('REMOVE_PRODUCER', { producerId: this._shareProducer.id })

      await this._protoo.request('closeProducer', { producerId: this._shareProducer.id })

      this._shareProducer = null

      await this.enableWebcam()
    } catch (error) {
      console.error(`Error closing server-side share Producer: ${error}`)
    }

    store.dispatch('SET_SHARE_IN_PROGRESS', false)
  }

  async muteMicrophone () {
    try {
      this._micProducer.pause()
      await this._protoo.request('pauseProducer', { producerId: this._micProducer.id })
      store.dispatch('SET_PRODUCER_PAUSED', { producerId: this._micProducer.id })
      store.dispatch('SET_MICROPHONE_IN_PROGRESS', false)
      store.dispatch('SET_DEVICE_INIT_SETTINGS', {
        ...store.getters.meet.self.deviceInitSettings,
        enableMicrophone: false
      })
    } catch (error) {
      console.error(error)
      throw new Error(`Error disabling microphone`)
    }
  }

  async unmuteMicrophone () {
    try {
      if (!this._micProducer) await this.enableMic()
      else {
        this._micProducer.resume()
        await this._protoo.request('resumeProducer', { producerId: this._micProducer.id })
        store.dispatch('SET_PRODUCER_PAUSED', { producerId: this._micProducer.id })
        store.dispatch('SET_MICROPHONE_IN_PROGRESS', true)
        store.dispatch('SET_DEVICE_INIT_SETTINGS', {
          ...store.getters.meet.self.deviceInitSettings,
          enableMicrophone: true
        })
      }
    } catch (error) {
      console.error(error)
      throw new Error(`Error enabling microphone`)
    }
  }

  async changeDefaultCamera (deviceId) {
    if (store.getters.meet.self.defaultCameraId === deviceId) return

    if (!this._webcamProducer) throw new Error('Webcam is disabled')

    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const device = devices.find(device => device.deviceId === deviceId)
      if (!device) throw new Error('Invalid Device ID')

      this._webcam.device = device

      // Closing the current video track before asking for a new one (mobiles do not like
      // having both front/back cameras open at the same time).
      this._webcamProducer.track.stop()

      const stream = await navigator.mediaDevices.getUserMedia(
        {
          video:
          {
            deviceId: { exact: this._webcam.device.deviceId },
            ...this.VIDEO_CONSTRAINTS[this._webcam.resolution]
          }
        })

      mediaStreamTracks.push(stream)
      const track = stream.getVideoTracks()[0]

      await this._webcamProducer.replaceTrack({ track })

      store.dispatch('SET_PRODUCER_TRACK', {
        producerId: this._webcamProducer.id,
        track: track
      })

      store.dispatch('SET_DEFAULT_CAMERA_ID', { deviceId: this._webcam.device.deviceId })
    } catch (error) {
      throw error
    }
  }

  async startRecorder () {
    await this._protoo.request('startRecording', { meetingCode: store.getters.meet.session.meetingCode, hostEmail: store.getters.currentUser.email })
    store.dispatch('SET_RECORDING_IN_PROGRESS', true)
  }

  stopRecorder () {
    store.dispatch('SET_RECORDING_IN_PROGRESS', false)
    this._protoo.request('stopRecording', { meetingCode: store.getters.meet.session.meetingCode })
  }

  async disableAllVideoConsumers () {
    const videoConsumers = Array.from(this._consumers.values()).filter(e => e.track.kind === 'video')
    videoConsumers.forEach(consumer => consumer.pause())
    store.dispatch('SET_CONSUMER_SETTINGS', {
      ...store.getters.meet.self.consumerSettings,
      receiveVideo: false
    })
  }

  async enableAllVideoConsumers () {
    const videoConsumers = Array.from(this._consumers.values()).filter(e => e.track.kind === 'video')
    videoConsumers.forEach(consumer => consumer.resume())
    store.dispatch('SET_CONSUMER_SETTINGS', {
      ...store.getters.meet.self.consumerSettings,
      receiveVideo: true
    })
  }

  async disableAllAudioConsumers () {
    const audioConsumers = Array.from(this._consumers.values()).filter(e => e.track.kind === 'audio')
    audioConsumers.forEach(consumer => consumer.pause())
    store.dispatch('SET_CONSUMER_SETTINGS', {
      ...store.getters.meet.self.consumerSettings,
      receiveAudio: false
    })
  }

  async enableAllAudioConsumers () {
    const audioConsumers = Array.from(this._consumers.values()).filter(e => e.track.kind === 'audio')
    audioConsumers.forEach(consumer => consumer.resume())
    store.dispatch('SET_CONSUMER_SETTINGS', {
      ...store.getters.meet.self.consumerSettings,
      receiveAudio: true
    })
  }

  async changeDefaultMicrophone (deviceId) {
    if (store.getters.meet.self.defaultMicrophoneId === deviceId) return

    if (!this._micProducer) throw new Error('Microphone is disabled')

    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const device = devices.find(device => device.deviceId === deviceId)
      if (!device) throw new Error('Invalid Device ID')

      const stream = await navigator.mediaDevices.getUserMedia(
        {
          audio:
          {
            deviceId: { exact: device.deviceId },
            ...this.AUDIO_CONSTRANTS
          }
        })

      mediaStreamTracks.push(stream)
      const track = stream.getAudioTracks()[0]

      await this._micProducer.replaceTrack({ track })

      store.dispatch('SET_PRODUCER_TRACK', {
        producerId: this._micProducer.id,
        track: track
      })

      store.dispatch('SET_DEFAULT_MICROPHONE_ID', { deviceId: device.deviceId })
    } catch (error) {
      throw error
    }
  }

  async changeDefaultSpeakers (deviceId) {
    try {
      if (!this._consume || Object.values(store.getters.meet.consumers).length === 0) return

      // For each consumer in consumers
      Object.values(store.getters.meet.consumers).forEach(consumerObj => {
        // Get consumer from memory
        const consumer = this._consumers.get(consumerObj.id)
        if (consumer && consumer.track) {
          let audioElement = document.getElementById(consumerObj.peerId + '-audio')
          if (audioElement) {
            audioElement.setSinkId(deviceId)
          }
        }
      })

      store.dispatch('SET_DEFAULT_SPEAKER_ID', { deviceId: deviceId })
    } catch (e) {
      console.error(e)
    }
  }

  // TODO: Wait for browsers to implement mediarecorder replaceTrack method
  // Below recording method contains business login for replacing tracks
  async recordLocalHostSession () {
    if (!store.getters.meet.self.webcamInProgress && !store.getters.meet.self.shareInProgress && !store.getters.meet.self.micInProgress) {
      throw new Error('No media available for recording')
    }

    // Assign producers
    let videoTrack = this._webcamProducer
    let audioTrack = this._micProducer
    let streamWatcherInterval = null

    // Create stream from tracks
    const stream = new MediaStream()
    stream.addTrack(videoTrack.track)
    stream.addTrack(audioTrack.track)

    // Start media recorder
    this._mediaRecorder = new MediaRecorder(stream)
    let data = []
    this._mediaRecorder.ondataavailable = event => data.push(event.data)

    this._mediaRecorder.onstop = () => {
      clearInterval(streamWatcherInterval)
      store.dispatch('SET_RECORDER_STATUS', false)
      let blob = URL.createObjectURL(new Blob(data))
      const a = document.createElement('a')
      a.href = blob
      a.download = new Date().toDateString() + '_' + store.getters.meet.session.sessionId + '_' + store.getters.meet.self.userName + '.webm'
      a.click()
      window.URL.revokeObjectURL(blob)
      this._mediaRecorder = null
    }

    this._mediaRecorder.onerror = (e) => {
      console.error(e)
    }

    // Start watching stream devices and set streams if changed
    this._mediaRecorder.onstart = () => {
      // streamWatcherInterval = setInterval(() => {
      //   // Remove track if id mismatches the producers
      //   if (this._webcamProducer && this._webcamProducer.id !== videoTrack.id) {
      //     stream.removeTrack(videoTrack.track)
      //     videoTrack = null
      //   }

      //   if (this._micProducer.id && this._micProducer.id !== audioTrack.id) {
      //     stream.removeTrack(audioTrack.track)
      //     audioTrack = null
      //   }

      //   // If producer is available and current video track is null
      //   if (this._webcamProducer && !videoTrack) {
      //     videoTrack = this._webcamProducer
      //     stream.addTrack(videoTrack.track)
      //   }

      //   // If producer is available and current audio track is null
      //   if (this._micProducer && !audioTrack) {
      //     audioTrack = this._micProducer
      //     stream.addTrack(audioTrack.track)
      //   }
      // }, 2000)

      store.dispatch('SET_RECORDER_STATUS', true)
    }

    this._mediaRecorder.start()
  }

  async stopHostSessionRecorder () {
    if (this._mediaRecorder) this._mediaRecorder.stop()
  }

  async restartIce () {
    try {
      if (this._sendTransport) {
        const iceParameters = await this._protoo.request('restartIce', { transportId: this._sendTransport.id })
        await this._sendTransport.restartIce({ iceParameters })
      }

      if (this._recvTransport) {
        const iceParameters = await this._protoo.request('restartIce', { transportId: this._recvTransport.id })
        await this._recvTransport.restartIce({ iceParameters })
      }
    } catch (error) {
      console.error(error)
      throw new Error('Failed to refresh connection')
    }
  }

  async muteAllPeers () {
    try {
      Object.keys(store.getters.meet.peers).forEach(async peerId => {
        await this.sendRemoteCommand('muteAll', peerId)
      })

      store.dispatch('SET_SESSION_MUTED', true)
    } catch (error) {
      console.error(error)
      throw new Error('Error with Mute All')
    }
  }

  async unmuteAllPeers () {
    try {
      Object.keys(store.getters.meet.peers).forEach(async peerId => {
        await this.sendRemoteCommand('unmuteAll', peerId)
      })
      store.dispatch('SET_SESSION_MUTED', false)
    } catch (error) {
      console.error(error)
      throw new Error('Error with Unmute All')
    }
  }

  // Local functions start with _
  async _pauseConsumer (consumer) {
    if (consumer.paused) { return }

    try {
      await this._protoo.request('pauseConsumer', { consumerId: consumer.id })
      consumer.pause()

      store.dispatch('SET_CONSUMER_PAUSED', {
        consumerId: consumer.id,
        originator: 'local'
      })
    } catch (error) {
      console.error('Failed to pause consumer: ', error)
    }
  }

  async _resumeConsumer (consumer) {
    if (!consumer.paused) { return }

    try {
      await this._protoo.request('resumeConsumer', { consumerId: consumer.id })
      consumer.resume()

      store.dispatch('SET_CONSUMER_RESUMED', {
        consumerId: consumer.id,
        originator: 'local'
      })
    } catch (error) {
      console.error('Failed to resume consumer: ', error)
    }
  }

  async _updateWebcams () {
    // Reset the list.
    this._webcams = new Map()

    const devices = await navigator.mediaDevices.enumerateDevices()

    for (const device of devices) {
      if (device.kind !== 'videoinput') continue
      this._webcams.set(device.deviceId, device)
    }

    const array = Array.from(this._webcams.values())
    const currentWebcamId = this._webcam.device ? this._webcam.device.deviceId : undefined

    if (array.length === 0) { this._webcam.device = null } else if (!this._webcams.has(currentWebcamId)) { this._webcam.device = array[0] }
    store.dispatch('SET_CAN_CHANGE_WEBCAM', this._webcams.size > 1)
  }

  _getWebcamType (device) {
    if (/(back|rear)/i.test(device.label)) {
      return 'back'
    } else {
      return 'front'
    }
  }

  _getBrowserInfo () {
    {
      const ua = navigator.userAgent
      const browser = bowser.getParser(ua)
      let flag

      if (browser.satisfies({ chrome: '>=0', chromium: '>=0' })) { flag = 'chrome' } else if (browser.satisfies({ firefox: '>=0' })) { flag = 'firefox' } else if (browser.satisfies({ safari: '>=0' })) { flag = 'safari' } else if (browser.satisfies({ opera: '>=0' })) { flag = 'opera' } else if (browser.satisfies({ 'microsoft edge': '>=0' })) { flag = 'edge' } else { flag = 'unknown' }

      return {
        flag,
        name: browser.getBrowserName(),
        version: browser.getBrowserVersion()
      }
    }
  }
}

class RTCPLive {
  constructor() {

    // Session object
    this.sessionRoom = null
    this.sessionSettings = null

    // Client data
    this.currentBroadcastId = null
    this.selfVideoElementId = null
    this.lazyChatTypingNotifier = null
  }

  StartVideoBroadcast (sessionId, sessionName, userName, serverUrl) {
    return new Promise((resolve, reject) => {
      try {
        // Check if socket is active
        if (this.sessionRoom) return reject(new Error('Broadcast Error: Please stop previous broadcasting sessions'))

        // Lets not loose class context
        let self = this

        // Set broadcast ID
        self.currentBroadcastId = sessionId

        // Set session options
        this.sessionSettings = {
          broadcastConfiguration: broadcastConfiguration,
          sessionId: self.currentBroadcastId,
          sessionName: sessionName,
          sessionRole: 'start',
          userName: userName,
          useSimulcast: true,
          useSharingSimulcast: true,
          forceTcp: false,
          produce: true,
          consume: true,
          forceH264: false,
          forceVP9: false,
          svc: undefined,
          datachannel: true,
          isHlsBroadcast: false,
          serverUrl: serverUrl
        }


        setTimeout(async () => {
          // Create new room and join
          this.sessionRoom = new MediasoupWrapper(this.sessionSettings)
          try {
            //Mzian
            await this.sessionRoom.join()
            // Return control
            return resolve({
              status: true,
            })
          } catch (e) {
            return reject(e)
          }
        }, 1000)
      } catch (e) {
        console.error(e)
        return reject(new Error('Server not available at the moment'))
      }
    })
  }

  StopVideoBroadcast () {
    return new Promise((resolve, reject) => {
      // Stop local connections
      this.CleanBroadcast()

      // Return control
      return resolve({ status: true })
    })
  }

  ShowMyVideoAudio (videoElementId) {
    return new Promise((resolve, reject) => {

      if (!this.GET_HARDWARE_SETTINGS().settings.camera) return resolve()

      if (this.sessionSettings && this.sessionSettings.produce) {
        try {

          if (!this.selfVideoElementId) this.selfVideoElementId = videoElementId

          // Create new stream to attach
          let isVideoSet = null
          let stream = new MediaStream()

          // Fetch own video from the store
          let waitForProducingStreams = setInterval(() => {
            if (this.sessionRoom) {
              let videoElement = document.getElementById(this.selfVideoElementId)

              // Attach video track
              if ((this.sessionRoom._webcamProducer || this.sessionRoom._shareProducer) && !isVideoSet) {
                if (this.sessionRoom._webcamProducer) { stream.addTrack(this.sessionRoom._webcamProducer.track); isVideoSet = true } else if (this.sessionRoom._shareProducer) { stream.addTrack(this.sessionRoom._shareProducer.track); isVideoSet = true }
              }

              // If audio video available
              if (isVideoSet && videoElement) {
                videoElement.srcObject = stream
                videoElement.muted = true
                videoElement.autoplay = true
                clearInterval(waitForProducingStreams)
                return resolve()
              }
            }
          }, 1000)
        } catch (e) {
          console.error(e)
          return reject(e)
        }
      } else {
        return resolve()
      }
    })
  }

  ShowPeerThumbnail () {
    try {
      // For each consumer in consumers
      Object.values(store.getters.meet.consumers).forEach(consumerObj => {
        // Get consumer from memory
        const consumer = this.sessionRoom._consumers.get(consumerObj.id)
        if (consumer && consumer.track) {
          // If current track is video
          if (consumer.track.kind === 'video') {
            // Search for existing element, if not found, create new and attach stream
            let videoElement = document.getElementById(consumerObj.peerId + '-video-thumbnail')
            if (videoElement) {
              const stream = new MediaStream()
              stream.addTrack(consumer.track)
              videoElement.srcObject = stream
              videoElement.autoplay = true
            }
          } else if (consumer.track.kind === 'audio') {
            // Search for existing element, if not found, create new and attach stream
            let audioElement = document.getElementById(consumerObj.peerId + '-audio-thumbnail')
            if (audioElement) {
              // Create media stream
              const stream = new MediaStream()
              stream.addTrack(consumer.track)
              audioElement.srcObject = stream
              audioElement.autoplay = true
            }
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  RenderPeerVideos () {
    try {
      // For each consumer in consumers
      Object.values(store.getters.meet.consumers).forEach(consumerObj => {
        // Get consumer from memory
        const consumer = this.sessionRoom._consumers.get(consumerObj.id)
        if (consumer && consumer.track) {
          // If current track is video
          if (consumer.track.kind === 'video') {
            // Search for existing element, if not found, create new and attach stream
            let videoElement = document.getElementById(consumerObj.peerId + '-video')
            if (videoElement) {
              const stream = new MediaStream()
              stream.addTrack(consumer.track)
              videoElement.autoplay = true
              videoElement.srcObject = stream
            }
          } else if (consumer.track.kind === 'audio') {
            // Search for existing element, if not found, create new and attach stream
            let audioElement = document.getElementById(consumerObj.peerId + '-audio')
            if (audioElement) {
              // Create media stream
              const stream = new MediaStream()
              stream.addTrack(consumer.track)
              audioElement.autoplay = true
              audioElement.srcObject = stream
            }
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  RenderPinnedVideos ({ peerId }) {
    try {
      for (let consumerObj of Object.values(store.getters.meet.consumers)) {
        if (peerId === consumerObj.peerId) {
          const consumer = this.sessionRoom._consumers.get(consumerObj.id)
          if (consumer && consumer.track) {
            // If current track is video
            if (consumer.track.kind === 'video') {
              // Search for existing element, if not found, create new and attach stream
              let videoElement = document.getElementById(peerId + '-pinned-video')
              if (videoElement) {
                const stream = new MediaStream()
                stream.addTrack(consumer.track)
                videoElement.srcObject = stream
                videoElement.autoplay = true
              }
            }
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  RemovePinnedVideos ({ peerId }) {
    try {
      // Search for existing element, if not found, create new and attach stream
      let videoElement = document.getElementById(peerId + '-pinned-video')
      if (videoElement) {
        videoElement.srcObject = null
      }
    } catch (e) {
      console.error(e)
    }
  }

  async RaiseQuestionToHost (peerId) {
    if (store.getters.meet.session.sessionHostId) {
      await this.sessionRoom.sendRemoteCommand('raiseQuestion', peerId)
    } else {
      throw new Error('Host is not available')
    }
  }

  getLocalStorage () {
    const data = JSON.parse(localStorage.getItem('stepaheadhealth'))
    return data == null ? undefined : data
  }

  // Get combined store results
  GET_HARDWARE_SETTINGS () {
    const local = this.getLocalStorage()
    return {
      ...(local) ? local : {}
    }
  }

  async SetHardwareSettings (settings) {
    const obj = {
      settings: settings
    }

    localStorage.setItem('stepaheadhealth', JSON.stringify(obj));
    return obj.settings
  }

  async StartRecorder () {
    await this.sessionRoom.startRecorder()
  }

  StopRecorder () {
    this.sessionRoom.stopRecorder()
  }

  async DisableAllVideos () {
    await this.sessionRoom.disableAllVideoConsumers()
  }

  async EnableAllVideos () {
    await this.sessionRoom.enableAllVideoConsumers()
  }

  async DisableAllAudios () {
    await this.sessionRoom.disableAllAudioConsumers()
  }

  async EnableAllAudios () {
    await this.sessionRoom.enableAllAudioConsumers()
  }

  SendChatMessage (text, extra) {
    if (this.sessionRoom) this.sessionRoom.sendChatMessage(text, extra)
  }

  NotifyUserIsTyping (userName) {
    try {
      if (!this.lazyChatTypingNotifier) {
        this.sessionRoom.sendRemoteCommand('isTyping', userName)
        this.lazyChatTypingNotifier = setTimeout(() => {
          this.lazyChatTypingNotifier = null
        }, 1000)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  async EnableScreenSharing () {
    await this.sessionRoom.enableScreenShare()
  }

  async DisableScreenSharing () {
    await this.sessionRoom.disableScreenShare()
  }

  async EnablePeerMicrophone (peerId) {
    if (store.getters.meet.peers[peerId] && !store.getters.meet.peers[peerId].isMicEnabled) {
      await this.sessionRoom.sendRemoteCommand('enableMic', peerId)
      // Set raise hand to false
      if (store.getters.meet.peers[peerId] && store.getters.meet.peers[peerId].handRaised) store.dispatch('SET_PEER_HAND_RAISED', { peerId: peerId, value: false })
    } else {
      throw new Error('User microphone is already enabled')
    }
  }

  async DisablePeerMicrophone (peerId) {
    if (store.getters.meet.peers[peerId] && store.getters.meet.peers[peerId].isMicEnabled) {
      await this.sessionRoom.sendRemoteCommand('disableMic', peerId)
    } else {
      throw new Error('User microphone is already disabled')
    }
  }

  async EnablePeerCamera (peerId) {
    if (store.getters.meet.peers[peerId] && !store.getters.meet.peers[peerId].isCamEnabled) {
      await this.sessionRoom.sendRemoteCommand('enableCam', peerId)
      // Set raise hand to false
      if (store.getters.meet.peers[peerId] && store.getters.meet.peers[peerId].handRaised) store.dispatch('SET_PEER_HAND_RAISED', { peerId: peerId, value: false })
    } else {
      throw new Error('User microphone is already enabled')
    }
  }

  async DisablePeerCamera (peerId) {
    if (store.getters.meet.peers[peerId] && store.getters.meet.peers[peerId].isMicEnabled) {
      await this.sessionRoom.sendRemoteCommand('disableCam', peerId)
    } else {
      throw new Error('User microphone is already disabled')
    }
  }

  async MuteAllPeers () {
    try {
      if (store.getters.meet.session.sessionHostId !== store.getters.meet.self.id) {
        throw new Error('You do not have enough permissions for this operation')
      }

      await this.sessionRoom.muteAllPeers()
    } catch (e) {
      throw e
    }
  }

  async UnmuteAllPeers () {
    try {
      if (store.getters.meet.session.sessionHostId !== store.getters.meet.self.id) {
        throw new Error('You do not have enough permissions for this operation')
      }

      await this.sessionRoom.unmuteAllPeers()
    } catch (e) {
      throw e
    }
  }

  async DisableWebcam () {
    await this.sessionRoom.disableWebcam()
  }

  async EnableWebcam () {
    await this.sessionRoom.enableWebcam()
    if (this.selfVideoElementId) await this.ShowMyVideoAudio(this.selfVideoElementId)
  }

  async DisableMicrophone () {
    await this.sessionRoom.disableMic()
  }

  async EnableMicrophone () {
    await this.sessionRoom.enableMic()
  }

  async GetHardwareSettings () {
    return this.sessionRoom.GET_HARDWARE_SETTINGS()
  }

  async GetAvailableMicrophones () {
    try {
      if (navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        let devices = await navigator.mediaDevices.enumerateDevices()
        return devices.filter(d => d.kind === 'audioinput')
      } else {
        throw new Error('Unsupported Browser')
      }
    } catch (e) {
      throw e
    }
  }

  async GetAvailableCameras () {
    try {
      if (navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        let devices = await navigator.mediaDevices.enumerateDevices()
        return devices.filter(d => d.kind === 'videoinput')
      } else {
        throw new Error('Unsupported Browser')
      }
    } catch (e) {
      throw e
    }
  }

  async GetAvailableSpeakers () {
    try {
      if (navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        let devices = await navigator.mediaDevices.enumerateDevices()
        return devices.filter(d => d.kind === 'audiooutput')
      } else {
        throw new Error('Unsupported Browser')
      }
    } catch (e) {
      throw e
    }
  }

  async PauseVideoTransmission () {
    await this.sessionRoom.pauseVideoProducer()
  }

  async ResumeVideoTransmission () {
    await this.sessionRoom.resumeVideoProducer()
  }

  async SetDefaultCamera (deviceId) {
    await this.sessionRoom.changeDefaultCamera(deviceId)
  }

  async SetDefaultMicrophone (deviceId) {
    await this.sessionRoom.changeDefaultMicrophone(deviceId)
  }

  async SetDefaultSpeaker (deviceId) {
    await this.sessionRoom.changeDefaultSpeakers(deviceId)
  }

  async RefreshConnection () {
    await this.sessionRoom.restartIce()
  }

  CleanBroadcast () {
    // Close connection
    if (this.sessionRoom) this.sessionRoom.close()
    // Stop all media tracks
    _stopAllMediaTracks()
    // Empty variable
    this.currentBroadcastVideoElementId = null
    this.currentBroadcastId = null
    this.sessionRoom = null
  }
}

function _stopAllMediaTracks () {
  // Close all streams
  mediaStreamTracks.forEach(track => {
    try {
      track.getTracks().forEach(t => { t.stop() })
    } catch (e) {
      console.error(e)
    }
  })

  mediaStreamTracks = []
}

export {
  RTCPLive,
  StorageHelper
}
