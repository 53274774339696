<template>
  <div class="row">
    <div class="col-5">
      <div class="card">
        <div class="card-header">
          <h4>
            {{ $t("provider.sideBar.accountSetting.paymentSettings.title") }}
          </h4>
        </div>
        <div class="card-body">
          <!-- Profile Settings Form -->
          <form @submit.prevent="saveProfileDetails">
            <div class="row form-row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{
                    $t(
                      "provider.sideBar.accountSetting.paymentSettings.payPalEmail.email"
                    )
                  }}</label>
                  <input
                    type="email"
                    class="form-control mb-3"
                    v-model="paypalEmail"
                    required
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="submit-section text-center">
              <button type="submit" class="btn btn-sm btn-primary">
                {{
                  $t(
                    "provider.sideBar.accountSetting.paymentSettings.payPalEmail.button"
                  )
                }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-7">
      <div class="card">
        <div class="card-header">
          <h4>
            {{
              $t(
                "provider.sideBar.accountSetting.paymentSettings.consultingCharges.title"
              )
            }}
          </h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="saveProfileDetails">
            <div class="row form-row">
              <div class="col-6">
                <div class="form-group">
                  <label>{{
                    $t(
                      "provider.sideBar.accountSetting.paymentSettings.consultingCharges.prescriptionFees"
                    )
                  }}</label>
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="prescriptionFees"
                    required
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>{{
                    $t(
                      "provider.sideBar.accountSetting.paymentSettings.consultingCharges.consultationFees"
                    )
                  }}</label>
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="appointmentFees"
                    required
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="submit-section text-center">
              <button type="submit" class="btn btn-sm btn-primary">
                {{
                  $t(
                    "provider.sideBar.accountSetting.paymentSettings.consultingCharges.button"
                  )
                }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      prescriptionFees: "",
      appointmentFees: "",
      paypalEmail: "",
    };
  },
  async mounted() {
    this.showLoader(true);
    let data = JSON.parse(JSON.stringify(this.$store.getters.user));
    this.prescriptionFees = data.prescription_fee;
    this.appointmentFees = data.appointment_fee;
    this.paypalEmail = data.paypal_email;
    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "showLoader", "showAlert"]),

    async saveProfileDetails() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          professional: {
            prescription_fee: this.prescriptionFees,
            appointment_fee: this.appointmentFees,
            paypal_email: this.paypalEmail,
          },
        });

        Swal.fire(
          "Payment Settings Updated",
          "Payment information updated successfully",
          "success"
        );

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Ooops!", e.response.data.message, "error");
      }
    },
  },
};
</script>
