<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <!-- Select Sections-->
    <section class="patient-select mb-5 mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="select-box">
              <div class="d-flex align-items-center">
                <div class="image-holder">
                  <span>
                    <img src="../assets/img/icons/doct-01.png" alt="" />
                  </span>
                </div>
                <div>
                  <h2>{{ $t('signUpEntryPage.providerTitle') }}</h2>
                  <p>{{ $t('signUpEntryPage.providerDesc') }}</p>
                  <router-link to="/provider/register" class="book-now w-50 text-center">{{
                    $t('signUpEntryPage.providerBtn') }}</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="select-box theme">
              <div class="d-flex align-items-center">
                <div class="image-holder">
                  <span>
                    <img src="../assets/img/icons/doct-02.png" alt="" />
                  </span>
                </div>
                <div>
                  <h2>{{ $t('signUpEntryPage.patientTitle') }}</h2>
                  <p>{{ $t('signUpEntryPage.patientDesc') }}</p>
                  <router-link to="/patient/register" class="book-now w-50 text-center">{{
                    $t('signUpEntryPage.patientBtn')
                  }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Select Sections-->

    <layout-footer></layout-footer>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  mounted () {
    if (this.$store.getters.user) {
      switch (this.$store.getters.user.role) {
        case "patient":
          if (!this.$store.getters.user.isRegistrationComplete) {
            this.$router.push(
              `/patient/step-${this.$store.getters.user.current_registration_step}`
            );
          } else this.$router.push("/patient/index");
          break;
        case "doctor":
          this.$router.push("/provider/index");
          break;
      }
    }
  },
};
</script>