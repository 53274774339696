<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb8></breadcrumb8>
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickyside">
              <sidebar1></sidebar1>
            </div>
          </div>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-header">
                <h3>{{ $t("patient.changePharmacy.title") }}</h3>
              </div>
              <div class="card-body">
                <div class="p-2" v-if="pharmacy && pharmacy.pharmacyName">
                  <h5>
                    {{ $t("patient.changePharmacy.name") }}:
                    {{ pharmacy.pharmacyName }}
                  </h5>
                  <h5>
                    {{ $t("patient.changePharmacy.phn") }}:
                    {{ pharmacy.phone }}
                  </h5>
                  <h5>
                    {{ $t("patient.changePharmacy.address") }}:
                    {{ getStoreAddress(pharmacy.address) }}
                  </h5>
                  <hr />
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                  >
                    <i class="fa fa-clinic-medical mr-2"></i>
                    {{ $t("patient.changePharmacy.btn1") }}
                  </button>
                  <button
                    @click="saveProfileDetails"
                    type="button"
                    class="btn btn-sm btn-primary ml-2"
                  >
                    <i class="fa fa-check mr-2"></i>
                    {{ $t("patient.changePharmacy.btn2") }}
                  </button>
                </div>
                <div v-else>
                  <div class="p-2">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target=".bd-example-modal-xl"
                    >
                      Select Pharmacy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Select Pharmacy Modal -->
    <div
      class="modal fade bd-example-modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("patient.changePharmacy.searchPharmacyNearByYou") }}
            </h5>
            <button
              type="button"
              class="btn btn-info"
              data-dismiss="modal"
              id="close"
            >
              {{ $t("patient.changePharmacy.btn3") }}
            </button>
          </div>

          <div class="modal-body">
            <ChoosePharmacy @select="selectPharmacy" />
          </div>
        </div>
      </div>
    </div>

    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ChoosePharmacy from "./ChoosePharmacy.vue";
import Swal from "sweetalert2";

export default {
  components: { ChoosePharmacy },
  data() {
    return {
      pharmacy: null,
    };
  },
  mounted() {
    this.pharmacy = this.$store.getters.user.pharmacy;
  },
  methods: {
    ...mapActions(["showLoader", "saveUserDetails"]),
    getStoreAddress(addressObject) {
      return `${addressObject.addressLine1}, ${addressObject.addressLine2}, ${addressObject.city}, ${addressObject.state} - ${addressObject.zipCode}`;
    },
    selectPharmacy(data) {
      this.pharmacy = JSON.parse(data);
      document.getElementById("close").click();
    },
    async saveProfileDetails() {
      try {
        if (!this.pharmacy || !this.pharmacy.pharmacyName) {
          Swal.fire("Select Pharmacy", "Please select the pharmacy", "error");
          return;
        }

        this.showLoader(true);

        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          pharmacy: this.pharmacy,
        });

        Swal.fire("Pharmacy Updated!", "", "success");

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Ooops!", e.response.data.message, "error");
        console.error(e);
      }
    },
  },
};
</script>
