<template>
  <div class="main-wrapper" v-if="prescriptionDetails">
    <layout-header></layout-header>
    <div class="breadcrumb-bar">
      <div class="container-fluid">
        <div
          class="row align-items-center text-light"
          v-if="prescriptionDetails.id"
        >
          <div class="col-sm-12 col-md-8 col-xl-10">
            <h2 class="breadcrumb-title">
              Rx Request ID: {{ prescriptionDetails.id }}
            </h2>
            <span style="font-size: 12px"
              >Request Date:
              {{
                new Date(prescriptionDetails.created_at).toDateString()
              }}</span
            >
            <p style="font-size: 12px">
              Last Updated At:
              {{ new Date(prescriptionDetails.created_at).toLocaleString() }}
            </p>
          </div>
          <div class="col-sm-12 col-md-4 col-xl-2 text-right">
            <p
              style="font-size: 14px"
              :class="getStatusColor(prescriptionDetails.status)"
            >
              Status:
              <span
                v-if="
                  prescriptionDetails.status === 'Accepted' &&
                  prescriptionDetails.is_payment_completed
                "
              >
                Provider Approval Pending
              </span>
              <span v-else>
                {{ pharmacyStatus(prescriptionDetails.status) }}
              </span>
            </p>
            <p style="font-size: 12px" v-if="prescriptionDetails.payment">
              <span v-if="prescriptionDetails.payment.status === 'COMPLETED'">
                Payment Status:
                <strong>{{ prescriptionDetails.payment.status }}</strong></span
              >
              <a
                v-else
                class="btn btn-sm btn-info"
                href="javascript:void();"
                @click="
                  $router.push(`/patient/billing/${prescriptionDetails.id}`)
                "
                >Pay Fees</a
              >
              <br />
              <span v-if="prescriptionDetails.payment.status === 'COMPLETED'">
                Transaction ID:
                <strong>{{ prescriptionDetails.payment.trans_id }}</strong>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <!-- REFILL DETAILS -->
        <div class="row" style="font-size: 12px">
          <div class="col-md-3 col-3 mb-4" id="third-row">
            <div class="accordion" id="prescription-accordion">
              <!-- Assigned Provider Details -->
              <div class="card mb-0" v-if="prescriptionDetails.provider">
                <div class="card-header" id="provider-info-accordion">
                  <h3 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#provider-info-collapse"
                      aria-expanded="false"
                      aria-controls="provider-info-collapse"
                    >
                      <i class="fas fa-id-card mr-1"></i> Provider Information
                    </button>
                  </h3>
                </div>
                <div
                  id="provider-info-collapse"
                  class="collapse"
                  aria-labelledby="provider-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    <p>
                      <span class="text-muted">Name:</span>
                      {{ prescriptionDetails.provider.first_name }}
                      {{ prescriptionDetails.provider.last_name }}
                    </p>
                    <p>
                      <span class="text-muted">Description: </span
                      >{{ prescriptionDetails.provider.description }}
                    </p>
                    <p>
                      <span class="text-muted">About:</span><br />
                      <span style="white-space: pre-wrap">{{
                        prescriptionDetails.provider.about_me
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <!-- Pharmacy Details -->
              <div class="card mb-0" v-if="prescriptionDetails.pharmacy">
                <div class="card-header" id="pharmacy-info-accordion">
                  <h3 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#pharmacy-info-collapse"
                      aria-expanded="false"
                      aria-controls="pharmacy-info-collapse"
                    >
                      <i class="fas fa-hospital mr-1"></i> Pharmacy Details
                    </button>
                  </h3>
                </div>
                <div
                  id="pharmacy-info-collapse"
                  class="collapse"
                  aria-labelledby="pharmacy-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    <p>
                      <span class="text-muted">Store ID:</span>
                      {{ prescriptionDetails.pharmacy.pharmacy_id }}
                    </p>
                    <p>
                      <span class="text-muted">Store Name: </span
                      >{{ prescriptionDetails.pharmacy.pharmacy_name }}
                    </p>
                    <p>
                      <span class="text-muted">Phone:</span>
                      {{ prescriptionDetails.pharmacy.phone }}
                    </p>
                    <p v-if="prescriptionDetails.pharmacy.address">
                      <span class="text-muted">Address:</span>
                      {{ prescriptionDetails.pharmacy.address1 }},
                      {{ prescriptionDetails.pharmacy.address2 }},
                      {{ prescriptionDetails.pharmacy.address.addressLine3 }},
                      {{ prescriptionDetails.pharmacy.city }},
                      {{ prescriptionDetails.pharmacy.state }} -
                      {{ prescriptionDetails.pharmacy.address.zipCode }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- Observations and Symptoms -->
              <div class="card mb-0" v-if="prescriptionDetails.observations">
                <div class="card-header" id="health-info-accordion">
                  <h3 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#health-info-collapse"
                      aria-expanded="false"
                      aria-controls="health-info-collapse"
                    >
                      <i class="fas fa-heart mr-1"></i> Health Information
                    </button>
                  </h3>
                </div>
                <div
                  id="health-info-collapse"
                  class="collapse"
                  aria-labelledby="health-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    <p>
                      <span class="text-muted">Medical History:</span>
                      {{ prescriptionDetails.observations.history }}
                    </p>
                    <p>
                      <span class="text-muted">Known Allergies:</span>
                      {{ prescriptionDetails.observations.allergies }}
                    </p>
                    <p>
                      <span class="text-muted">Symptoms / Reason :</span>
                      {{ prescriptionDetails.observations.symptoms }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- Payment Details -->
              <div class="card mb-0" v-if="prescriptionDetails.payment">
                <div class="card-header" id="payment-info-accordion">
                  <h3 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#payment-info-collapse"
                      aria-expanded="false"
                      aria-controls="payment-info-collapse"
                    >
                      <i class="fas fa-dollar mr-1"></i> Payment Information
                    </button>
                  </h3>
                </div>
                <div
                  id="payment-info-collapse"
                  class="collapse"
                  aria-labelledby="payment-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    <p>
                      <span class="text-muted">Provider Fees:</span>
                      ${{ prescriptionDetails.payment.amount.toFixed(2) }}
                      {{ prescriptionDetails.payment.currency }}
                    </p>
                    <p>
                      <span class="text-muted">Payment Status: </span
                      >{{ prescriptionDetails.payment.status }}
                    </p>
                    <p>
                      <span class="text-muted">Transaction ID: </span
                      >{{ prescriptionDetails.payment.trans_id }}
                    </p>
                    <p>
                      <span class="text-muted">Date / Time: </span
                      >{{
                        new Date(
                          prescriptionDetails.payment.created_at
                        ).toLocaleString()
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- Patient Details -->
              <div class="card mb-0">
                <div class="card-header" id="patient-info-accordion">
                  <h3 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      data-target="#patient-info-collapse"
                      aria-expanded="true"
                      aria-controls="patient-info-collapse"
                    >
                      <i class="fas fa-user mr-1"></i> Your Information
                    </button>
                  </h3>
                </div>

                <div
                  id="patient-info-collapse"
                  class="collapse show"
                  aria-labelledby="patient-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    <p>
                      <span class="text-muted">Name: </span
                      >{{ $store.getters.user.first_name }}
                      {{ $store.getters.user.last_name }}
                    </p>
                    <p>
                      <span class="text-muted">Email:</span>
                      {{ $store.getters.user.email }}
                    </p>
                    <p>
                      <span class="text-muted">Mobile:</span>
                      {{ $store.getters.user.primary_phone }}
                    </p>
                    <p v-if="$store.getters.user.address1">
                      <span class="text-muted">Address: </span
                      >{{ $store.getters.user.address1 }},
                      {{ $store.getters.user.address2 }},
                      {{ $store.getters.user.city }},
                      {{ $store.getters.user.state }} -
                      {{ $store.getters.user.zip_code }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-9 col-9">
            <div class="card mb-2">
              <div class="card-header">
                <div class="row">
                  <div class="col-6">
                    <h4>Medications</h4>
                  </div>
                  <div class="col-6">
                    <button
                      @click="openChat"
                      class="btn btn-sm btn-outline-info float-right"
                    >
                      <i class="fa fa-comment mr-2"></i>Chat with provider
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Medicines Prescribed -->
            <div v-if="prescriptionDetails.medicines">
              <div
                class="card mb-2"
                v-for="(medicine, i) in prescriptionDetails.medicines"
                :key="i"
              >
                <div class="card-header">
                  <h5>{{ i + 1 }} . {{ medicine.medicine_name }}</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <h5>
                        Rx Reference ID: {{ medicine.dsp_prescription_id }}
                      </h5>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <h5>Refill Status: {{ medicine.refill_status }}</h5>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <h5>
                        Quantity: {{ parseFloat(medicine.quantity).toFixed(3) }}
                        {{ medicine.unit_abbreviation }}
                      </h5>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <h5>Refills: {{ medicine.refills }}</h5>
                    </div>
                    <div
                      v-if="medicine.supply_days"
                      class="col-12 col-md-4 col-lg-4 col-xl-4 mb-4"
                    >
                      <h5>Supply Days: {{ medicine.supply_days }}</h5>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                      <h5>Directions: {{ medicine.directions }}</h5>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                      <h5>
                        Pharmacy Note:
                        {{
                          medicine.pharmacy_note
                            ? medicine.pharmacy_note
                            : "Not Available"
                        }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Waiting -->
            <div class="text-center" v-else>
              <img
                class="mt-4"
                src="../../assets/img/waiting.png"
                alt="waiting"
                width="350"
                style="border-radius: 20px"
              />
              <h3 class="mt-3 mb-5">Waiting for provider's response...</h3>
            </div>
          </div>

          <!-- Chatbox  -->
          <div
            v-if="prescriptionDetails.provider"
            data-backdrop="static"
            id="chat-modal"
            class="slideout"
          >
            <chatbox
              class="mb-3"
              :providerImage="prescriptionDetails.provider.profile_image_url"
              isCallingEnabled="true"
              :providerName="`${prescriptionDetails.provider.first_name} ${prescriptionDetails.provider.last_name}`"
              :prescriptionId="prescriptionId"
              :status="prescriptionDetails.status"
              @closechat="closeChat"
            ></chatbox>
          </div>
        </div>
      </div>
    </div>

    <layout-footer></layout-footer>
  </div>
</template>
<script>
import axios from "axios";
import config from "../../constants/config";
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
import chatbox from "./Chat.vue";

export default {
  props: {
    prescriptionId: String,
  },
  mixins: [commonMixin],
  components: {
    chatbox,
  },
  data() {
    return {
      prescriptionDetails: null,
      isChatOpen: false,
    };
  },
  async created() {
    this.showLoader(true);

    const res = await axios.get(
      `${config.BASE_URL}${config.HTTP_API.patientGetPrescriptionById}/${this.prescriptionId}`
    );

    this.prescriptionDetails = res.data;
    this.showLoader(false);
  },
  methods: {
    ...mapActions(["showLoader"]),
    closeChat() {
      this.isChatOpen = false;
      $(".slideout").removeClass("on");
    },
    openChat() {
      // this.$socket.emit("REQUEST", {
      //   command: "POST:CHAT_MESSAGES_MARK_READ",
      //   userId: this.$store.getters.user.id,
      //   prescriptionId: this.prescriptionId,
      // });

      this.isChatOpen = true;
      $(".slideout").addClass("on");
    },
    pharmacyStatus(status) {
      switch (status) {
        case "Sent":
          return "Sent To Pharmacy";

        case "PharmacyVerified":
          return "Pharmacy Verified";
      }

      return status;
    },
    getStatusColor(status) {
      // console.log(status);
      switch (status) {
        case "Pending":
          return "btn bg-secondary btn-block";

        case "In Progress":
          return "btn bg-warning btn-block";

        case "Completed":
        case "Accepted":
          return "btn bg-success btn-block";

        case "Cancelled":
          return "btn bg-danger btn-block";

        default:
          return "btn bg-warning btn-block";
      }
    },
  },
};
</script>

<style scoped>
#first-row {
  order: 1;
}

#second-row {
  order: 1;
}

.slideout {
  background: linear-gradient(to bottom, #15558d 0%, #15558d 100%);
  color: #fff;
  height: 100%;
  padding-left: 2px;
  position: fixed;
  right: -500px;
  top: 0;
  transition-duration: 0.2s;
  width: 500px;
  z-index: 9999;
}

.slideout.on {
  right: 0;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .slideout {
    background: linear-gradient(to bottom, #15558d 0%, #15558d 100%);
    color: #fff;
    height: 100%;
    padding-left: 2px;
    position: fixed;
    right: -440px;
    top: 0;
    transition-duration: 0.2s;
    width: 440px;
    z-index: 9999;
  }

  .chat-cont-right {
    height: 84% !important;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .slideout {
    background: linear-gradient(to bottom, #15558d 0%, #15558d 100%);
    color: #fff;
    height: 100%;
    padding-left: 2px;
    position: fixed;
    right: -500px;
    top: 0;
    transition-duration: 0.2s;
    width: 500px;
    z-index: 9999;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .slideout {
    background: linear-gradient(to bottom, #15558d 0%, #15558d 100%);
    color: #fff;
    height: 100%;
    padding-left: 2px;
    position: fixed;
    right: -500px;
    top: 0;
    transition-duration: 0.2s;
    width: 500px;
    z-index: 9999;
  }
}
</style>
