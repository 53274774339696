<template>
  <!-- Header -->
  <header class="header header-trans">
    <nav class="navbar navbar-expand-lg header-nav">
      <div class="navbar-header">
        <a id="mobile_btn" href="javascript:void(0);">
          <span class="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
        <router-link :to="logoLink" class="navbar-brand logo">
          <img src="../../assets/img/logo.png" class="img-fluid" alt="Logo" />
        </router-link>

        <div class="mobile-item nav-item dropdown has-arrow">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
            <img :src="currentFlag" alt="" width="12" /> {{ currentText }}
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <span class="dropdown-item" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
              @click="setLanguage(entry.language, entry.title, entry.flag)"
              :class="{ active: currentLanguage === entry.language }">
              <img :src="`${entry.flag}`" alt="flag-image" class="mr-2" width="12" />
              <span class="align-middle">{{ entry.title }}</span>
            </span>
          </div>
        </div>

        <a v-if="$store.getters.user" class="logoutBtn" href="javascript:void();" @click="logout"><i
            class="fas fa-sign-out-alt"></i></a>
      </div>
      <div class="main-menu-wrapper">
        <div class="menu-header">
          <router-link :to="logoLink" class="menu-logo">
            <img src="../../assets/img/logo.png" class="img-fluid" alt="Logo" />
          </router-link>
          <a id="menu_close" class="menu-close" href="javascript:void(0);">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <ul class="main-nav">
          <li class="has-submenu white-text active" v-show="!$store.getters.user">
            <router-link to="/">
              <a href="javascript:void();" id="menu_close">{{ $t('headerSection.home') }}</a>
            </router-link>
          </li>
          <li class="has-submenu white-text active" v-show="$store.getters.user">
            <router-link :to="dashboardLink">
              <a href="javascript:void();" id="menu_close">Dashboard</a>
            </router-link>
          </li>
          <li class="has-submenu white-text notactive" v-show="!$store.getters.user">
            <router-link to="/about">
              <a href="javascript:void();" id="menu_close">{{ $t('headerSection.aboutUs') }}</a>
            </router-link>
          </li>
          <!-- <li class="has-submenu white-text notactive">
            <a href="javascript:void();" @click="openSupportCenter"
              >Help Center</a
            >
          </li> -->
          <li>
            <router-link to="/contact">{{ $t('headerSection.contactUs') }}</router-link>
          </li>

          <!-- <li>
            <a href="javascript:void();" @click="openSupportCenter">{{ $t('headerSection.helpCenter') }}</a>
          </li> -->
          <!-- <li class="has-submenu">
            <a href="#">Support <i class="fas fa-chevron-down"></i></a>
            <ul class="submenu">
              <li>
                <router-link to="/contact">Contact Us</router-link>
              </li>
              <li>
                <a href="javascript:void();" @click="openSupportCenter"
                  >Help Center</a
                >
              </li>
            </ul>
          </li> -->
          <!-- <li
            v-show="
              !$store.getters.user ||
              ($store.getters.user && $store.getters.user.role == 'patient')
            "
            class="has-submenu white-text"
          >
            <router-link to="/patient/index">
              <a href="javascript:void();" id="menu_close">Patient's Area</a>
            </router-link>
          </li>
          <li
            v-show="
              !$store.getters.user ||
              ($store.getters.user && $store.getters.user.role == 'provider')
            "
            class="has-submenu white-text"
          >
            <router-link to="/provider/index">
              <a href="javascript:void();" id="menu_close">Provider's Area</a>
            </router-link>
          </li> -->
          <!-- <li class="has-submenu white-text notactive">
            <router-link to="/covid19">
              <a href="javascript:void();">Covid19 Stories</a>
            </router-link>
          </li> -->

          <li class="login-link" v-show="!$store.getters.user ||
            ($store.getters.user &&
              $store.getters.user.role == 'provider' &&
              'patient')
            ">
            <router-link to="/login" id="menu_close">{{ $t('headerSection.loginButton') }}</router-link>
          </li>
        </ul>
      </div>
      <ul class="nav header-navbar-rht">
        <li class="nav-item contact-item">
          <div class="header-contact-img">
            <i class="fas fa-phone-alt"></i>
          </div>
          <div class="header-contact-detail">
            <p class="contact-info-header">{{ $t('headerSection.contactNumber') }}</p>
          </div>
        </li>

        <li class="nav-item dropdown has-arrow">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
            <img :src="currentFlag" alt="" width="12" /> {{ currentText }}
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <span class="dropdown-item" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
              @click="setLanguage(entry.language, entry.title, entry.flag)"
              :class="{ active: currentLanguage === entry.language }">
              <img :src="`${entry.flag}`" alt="flag-image" class="mr-2" width="12" />
              <span class="align-middle">{{ entry.title }}</span>
            </span>
          </div>
        </li>

        <!-- User Menu -->
        <li v-if="$store.getters.user && $store.getters.user.is_registration_complete
          " class="nav-item dropdown has-arrow logged-item">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
            <span class="user-img">
              <img class="rounded-circle" :src="profileImage" width="31" alt="User Image" />
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <div class="user-header">
              <div class="avatar avatar-sm">
                <img :src="profileImage" class="avatar-img rounded-circle" />
              </div>
              <div class="user-text" v-if="user">
                <h6>{{ user.first_name }} {{ user.last_name }}</h6>
                <p v-if="user.role === 'patient'" class="text-muted mb-0">
                  Patient
                </p>
                <p v-if="user.role === 'provider'" class="text-muted mb-0">
                  Provider
                </p>
              </div>
            </div>
            <router-link class="dropdown-item" :to="dashboardLink">Dashboard</router-link>
            <router-link class="dropdown-item" :to="profileLink">Profile</router-link>
            <a class="dropdown-item" href="javascript:void();" @click="logout">Logout</a>
          </div>
        </li>
        <!-- /User Menu -->

        <!-- Login Button -->
        <li class="nav-item" v-else>
          <router-link class="nav-link header-login" to="/login"><img src="../../assets/img/login-btn.png" alt=""
              class="img-fluid mr-2" />{{ $t('headerSection.loginButton') }}
          </router-link>
        </li>
        <!-- Login Button -->
      </ul>
    </nav>
  </header>
  <!-- /Header -->
</template>
<script>
import { mapActions } from "vuex";
const images = require.context("../../assets/img", false, /\.png$|\.jpg$/);

export default {
  data () {
    return {
      languages: [
        {
          flag: '/img/flags/united-states.svg',
          language: "en",
          title: "English",
        },
        {
          flag: '/img/flags/spain.svg',
          language: "es",
          title: "Español",
        },
      ],
      currentLanguage: 'en',
      currentText: "English",
      currentFlag: '/img/flags/united-states.svg',
      currentValue: "en",
    }
  },
  mounted () {

    const currentLang = this.languages.find((x) => x.language === this.$i18n.locale);
    this.currentText = currentLang.title;
    this.currentFlag = currentLang.flag;

    var $wrapper = $(".main-wrapper");
    $(".main-nav a").on("click", function (e) {
      if ($(this).parent().hasClass("has-submenu white-text")) {
        e.preventDefault();
      }
      if (!$(this).hasClass("submenu")) {
        $("ul", $(this).parents("ul:first")).slideUp(350);
        $("a", $(this).parents("ul:first")).removeClass("submenu");
        $(this).next("ul").slideDown(350);
        $(this).addClass("submenu");
      } else if ($(this).hasClass("submenu")) {
        $(this).removeClass("submenu");
        $(this).next("ul").slideUp(350);
      }
    });

    $("body").append('<div class="sidebar-overlay"></div>');
    $(document).on("click", "#mobile_btn", function () {
      $wrapper.toggleClass("slide-nav");
      $(".sidebar-overlay").toggleClass("opened");
      $("html").addClass("menu-opened");
      $("#task_window").removeClass("opened");
      return false;
    });

    $(document).on("click", "#menu_close", function () {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
      $("main-wrapper").removeClass("slide-nav");
    });

    $(".main-nav ul li:not(.submenu) a").click(function () {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
    });
    //header-fixed

    if ($(".header-trans").length > 0) {
      $(document).ready(function () {
        $(window).scroll(function () {
          var scroll = $(window).scrollTop();
          if (scroll > 95) {
            $(".header-trans").css("background", "#FFFFFF");
          } else {
            $(".header-trans").css("background", "transparent");
          }
        });
      });
    }
    if ($(".header-trans.custom").length > 0) {
      $(document).ready(function () {
        $(window).scroll(function () {
          var scroll = $(window).scrollTop();
          if (scroll > 95) {
            $(".header-trans").css("background", "#2b6ccb");
          } else {
            $(".header-trans").css("background", "transparent");
          }
        });
      });
    }
  },
  computed: {
    profileImage () {
      return this.user.profile_image_url
        ? this.user.profile_image_url
        : images("./blank.png").default;
    },

    dashboardLink () {
      if (this.user) {
        return this.user && this.user.role === "patient"
          ? "/patient/index"
          : "/provider/index";
      } else {
        return "/";
      }
    },

    profileLink () {
      if (this.user) {
        return this.user && this.user.role === "patient"
          ? "/patient/profile"
          : "/provider/settings";
      } else {
        return "/";
      }
    },

    user () {
      return this.$store.getters.user;
    },

    logoLink () {
      if (this.user) {
        switch (this.user.role) {
          case "patient":
            return "/patient/index";

          case "provider":
            return "/provider/index";

          default:
            return "/";
        }
      }

      return "/";
    },
  },
  methods: {
    ...mapActions(["logOut"]),
    setLanguage (locale, country, flag) {
      this.currentLanguage = locale;
      this.currentText = country;
      this.currentFlag = flag;
      this.$i18n.locale = locale;
    },
    openSupportCenter () {
      window.open("https://stepaheadhealthinc.freshdesk.com/", "_self");
    },
    async logout () {
      try {
        await this.logOut({
          id: this.$store.getters.user.id,
        });

        this.$router.push("/login");
        this.$router.go();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
.logoutBtn {
  display: none;
}

.mobile-item {
  display: none;
}

@media only screen and (max-width: 576px) {

  .mobile-item {
    display: block;
  }

  .logoutBtn {
    color: #2e6a9b;
    display: block;
    font-size: 25px;
  }

  .logoutBtn:hover {
    color: #10defd;
  }

  .main-nav li a {
    color: white !important;
  }
}
</style>
