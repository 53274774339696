export default {
    state: {
        connectionId: null,
        prescriptions: [],
        chatMessages: []
    },

    getters: {
        connectionId: state => state.connectionId,
        prescriptions: state => state.prescriptions,
        chatMessages: state => state.chatMessages,
    },

    mutations: {
        SOCKET_CONNECTION_ID (state, id) {
            state.connectionId = id;
        },
        SOCKET_PRESCRIPTIONS (state, data) {
            state.prescriptions = data;
        },
        SOCKET_CHAT_MESSAGES (state, data) {
            state.chatMessages = data;
        },
    },

    actions: {
        SOCKET_CONNECTION_ID ({ commit }, id) {
            commit('SOCKET_CONNECTION_ID', id);
        },
        SOCKET_PRESCRIPTIONS ({ commit }, data) {
            commit('SOCKET_PRESCRIPTIONS', data);
        },
        SOCKET_CHAT_MESSAGES ({ commit }, data) {
            commit('SOCKET_CHAT_MESSAGES', data);
        },
    }
}



