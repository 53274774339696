import { createRouter, createWebHistory } from "vue-router";

/** For Home Pages **/
import NotFound from "../components/404";
import Index from "../components/Index";

/** For Doctor **/
import DoctorDashboard from "../components/provider/Index";
import Appointments from "../components/provider/Appointments";
import MyPatients from "../components/provider/MyPatients";
import PatientProfile from "../components/provider/PatientProfile";
import PatientChangePassword from "../components/patient/ChangePassword";
import PatientMeet from "../components/patient/Meet";
import ProviderMeet from "../components/provider/Meet";
import PatientChangePharmacy from "../components/patient/ChangePharmacy";
import ProviderSettings from "../components/provider/Settings";
import DoctorRegister from "../components/provider/Register";
import DoctorRegisterStep1 from "../components/provider/DoctorRegisterStep1";
import DoctorRegisterStep2 from "../components/provider/DoctorRegisterStep2";
import DoctorRegisterStep3 from "../components/provider/DoctorRegisterStep3";
import DoctorRegisterStep4 from "../components/provider/DoctorRegisterStep4";
import DoctorRegisterStep5 from "../components/provider/DoctorRegisterStep5";
import DoctorRegisterStep6 from "../components/provider/DoctorRegisterStep6";
import DoctorRegisterStep7 from "../components/provider/DoctorRegisterStep7";
import DoctorRegisterStep8 from "../components/provider/DoctorRegisterStep8";
import DoctorRegisterStep9 from "../components/provider/DoctorRegisterStep9";
import Agreements from "../components/provider/Agreements";
import ProviderPrescriptions from "../components/provider/Prescriptions";
import Finances from "../components/provider/Finances";
import ViewRefillDetails from '../components/provider/ViewRefillDetails'
import ViewPrescriptionDetails from '../components/provider/ViewPrescriptionDetails'

/** For Patient **/
import PatientDashboard from "../components/patient/Index";
import Booking from "../components/patient/Booking";
import RequestSuccess from "../components/patient/RequestSuccess";
import PaymentSuccess from "../components/patient/PaymentSuccess";
import ConfirmationBilling from "../components/patient/ConfirmationBilling";
import ProviderBilling from "../components/patient/ProviderBilling";
import PatientProfileSettings from "../components/patient/Profile";

import Login from "../components/Login";
import PatientRegister from "../components/patient/Register";
import PatientRegisterStep1 from "../components/patient/PatientRegisterStep1";
import PatientRegisterStep2 from "../components/patient/PatientRegisterStep2";
import PatientRegisterStep3 from "../components/patient/PatientRegisterStep3";
import PatientRegisterStep4 from "../components/patient/PatientRegisterStep4";
import PatientRegisterStep5 from "../components/patient/PatientRegisterStep5";
import PatientRegisterStep6 from "../components/patient/PatientRegisterStep6";
import PatientRegisterStep7 from "../components/patient/PatientRegisterStep7";
import PatientRegisterStep8 from "../components/patient/PatientRegisterStep8";
import Prescriptions from "../components/patient/Prescriptions";
import NewPrescription from "../components/patient/NewPrescription";
import FreePrescription from "../components/patient/FreePrescription";
import PatientPrescriptionDetails from '../components/patient/ViewPrescriptionDetails'
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";
import EmailVerification from "../components/EmailVerification";
import TermCondition from "../components/TermCondition";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Register from "../components/Register";
import Covid19 from "../components/Covid19";

/** FAQs */
import FAQRefill from "../components/FAQRefill";
import FAQPrice from "../components/FAQPrice";
import FAQPharmacy from "../components/FAQPharmacy";
import FAQBill from "../components/FAQBill";
import AboutUs from "../components/AboutUs";
import ContactUs from "../components/ContactUs";
import Diseases from "../components/Diseases"
import Medical_Conditions from "../components/Medical_Conditions";
import Medicines from "../components/Medicines";

const meta = {
  title: "StepAhead Health | ",
  auth: false,
  noPermissionOnAuth: false,
}

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      title: meta.title + "Online Prescription Refill",
    }
  },
  // {
  //   path: '/',
  //   name: 'coming-soon',
  //   component: ComingSoon,
  //   meta: {
  //     title: 'StepAhead Health | Online Prescription Refill',
  //     auth: false,
  //     noPermissionOnAuth: false
  //   }
  // },

  // Doctor Routes
  {
    path: "/provider/index",
    name: "doctor-index",
    component: DoctorDashboard,
    meta: {
      title: meta.title + "Dashboard",
      auth: true
    }
  },
  {
    path: "/provider/appointments",
    name: "doctor-appointments",
    component: Appointments,
    meta: {
      title: meta.title + "Appointments",
      auth: true
    }
  },
  {
    path: "/provider/patients",
    name: "my-patients",
    component: MyPatients,
    meta: {
      title: meta.title + "Patients",
      auth: true
    }
  },
  {
    path: "/provider/refill/:prescriptionId",
    name: "view-refill",
    component: ViewRefillDetails,
    props: true,
    meta: {
      title: meta.title + "Refill",
      auth: true
    }
  },
  {
    path: "/provider/request/:prescriptionId",
    name: "view-prescription",
    component: ViewPrescriptionDetails,
    props: true,
    meta: {
      title: meta.title + "Prescription",
      auth: true
    }
  },
  {
    path: "/provider/prescriptions",
    name: "provider-prescriptions",
    component: ProviderPrescriptions,
    meta: {
      title: meta.title + "Prescriptions",
      auth: true
    }
  },
  {
    path: "/provider/patient-profile",
    name: "patient-profile",
    component: PatientProfile,
    meta: {
      title: meta.title + "Patient Profile",
      auth: true
    }
  },
  {
    path: "/provider/finances",
    name: "finances",
    component: Finances,
    meta: {
      title: meta.title + "Finances",
      auth: true
    }
  },
  {
    path: "/provider/settings",
    name: "settings",
    component: ProviderSettings,
    meta: {
      title: meta.title + "Settings",
      auth: true
    }
  },
  {
    path: "/provider/register",
    name: "doctor-register",
    component: DoctorRegister,
    meta: {
      title: meta.title + "Create Provider Account",
    }
  },
  {
    path: "/provider/step-1",
    name: "provider-1",
    component: DoctorRegisterStep1,
    meta: {
      title: meta.title + "Create Provider Account",
      auth: true
    }
  },
  {
    path: "/provider/step-2",
    name: "provider-2",
    component: DoctorRegisterStep2,
    meta: {
      title: meta.title + "Create Provider Account",
      auth: true
    }
  },
  {
    path: "/provider/step-3",
    name: "provider-3",
    component: DoctorRegisterStep3,
    meta: {
      title: meta.title + "Create Provider Account",
      auth: true
    }
  },
  {
    path: "/provider/step-4",
    name: "provider-4",
    component: DoctorRegisterStep4,
    meta: {
      title: meta.title + "Create Provider Account",
      auth: true
    }
  },
  {
    path: "/provider/step-5",
    name: "provider-5",
    component: DoctorRegisterStep5,
    meta: {
      title: meta.title + "Create Provider Account",
      auth: true
    }
  },
  {
    path: "/provider/step-6",
    name: "provider-6",
    component: DoctorRegisterStep6,
    meta: {
      title: meta.title + "Create Provider Account",
      auth: true
    }
  },
  {
    path: "/provider/step-7",
    name: "provider-7",
    component: DoctorRegisterStep7,
    meta: {
      title: meta.title + "Create Provider Account",
      auth: true
    }
  },
  {
    path: "/provider/step-8",
    name: "provider-8",
    component: DoctorRegisterStep8,
    meta: {
      title: meta.title + "Create Provider Account",
      auth: true
    }
  },
  {
    path: "/provider/step-9",
    name: "provider-9",
    component: DoctorRegisterStep9,
    meta: {
      title: "StepAhead Health | Create Provider account",
      auth: true,
      noPermissionOnAuth: false,
    },
  },
  {
    path: "/provider/agreement",
    name: "agreement",
    component: Agreements,
    meta: {
      title: meta.title + "Agreement",
      auth: true
    }
  },

  // Patient Routes
  {
    path: "/patient/index",
    name: "patient-dashboard",
    component: PatientDashboard,
    meta: {
      title: meta.title + "Dashboard",
      auth: true
    }
  },
  {
    path: "/patient/request-success/:prescriptionNumber",
    name: "patient-request-success",
    props: true,
    component: RequestSuccess,
    meta: {
      title: meta.title + "New Prescription",
      auth: true
    }
  },
  {
    path: "/patient/payment-success/:prescriptionNumber",
    name: "patient-payment-success",
    props: true,
    component: PaymentSuccess,
    meta: {
      title: meta.title + "Payment Sucess",
      auth: true
    }
  },
  {
    path: "/patient/confirmation/:prescriptionId",
    name: "patient-confirmation",
    props: true,
    component: ConfirmationBilling,
    meta: {
      title: meta.title + "Confirmation",
      auth: true
    }
  },
  {
    path: "/patient/billing/:prescriptionId",
    name: "patient-billing",
    props: true,
    component: ProviderBilling,
    meta: {
      title: meta.title + "Billing",
      auth: true
    }
  },
  {
    path: "/patient/profile",
    name: "patient-profile-settings",
    component: PatientProfileSettings,
    meta: {
      title: meta.title + "Profile",
      auth: true
    }
  },
  {
    path: "/patient/change-pharmacy",
    name: "patient-change-pharmacy",
    component: PatientChangePharmacy,
    meta: {
      title: meta.title + "Change Pharmacy",
      auth: true
    }
  },
  {
    path: "/patient/change-password",
    name: "patient-change-password",
    component: PatientChangePassword,
    meta: {
      title: meta.title + "Change Password",
      auth: true
    }
  },
  {
    path: "/patient/call/:requestId/:meetingCode",
    name: "patient-call",
    component: PatientMeet,
    props: true,
    meta: {
      title: meta.title + "Video Call",
      auth: true
    }
  },
  {
    path: "/provider/call/:requestId/:meetingCode",
    name: "provider-call",
    props: true,
    component: ProviderMeet,
    meta: {
      title: meta.title + "Video Call",
      auth: true
    }
  },

  // Auth Routes
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: meta.title + "Login",
    }
  },
  {
    path: "/patient/register/:code?",
    name: "patient-register",
    props: true,
    component: PatientRegister,
    meta: {
      title: meta.title + "Create New Account",
    }
  },
  {
    path: "/patient/step-1",
    name: "step-1",
    component: PatientRegisterStep1,
    meta: {
      title: meta.title + "Create New Account",
      auth: true
    }
  },
  {
    path: "/patient/step-2",
    name: "step-2",
    component: PatientRegisterStep2,
    meta: {
      title: meta.title + "Create New Account",
      auth: true
    }
  },
  {
    path: "/patient/step-3",
    name: "step-3",
    component: PatientRegisterStep3,
    meta: {
      title: meta.title + "Create New Account",
      auth: true
    }
  },
  {
    path: "/patient/step-4",
    name: "step-4",
    component: PatientRegisterStep4,
    meta: {
      title: meta.title + "Create New Account",
      auth: true
    }
  },
  {
    path: "/patient/step-5",
    name: "step-5",
    component: PatientRegisterStep5,
    meta: {
      title: meta.title + "Create New Account",
      auth: true
    }
  },
  {
    path: "/patient/step-6",
    name: "step-6",
    component: PatientRegisterStep6,
    meta: {
      title: meta.title + "Create New Account",
      auth: true
    }
  },
  {
    path: "/patient/step-7",
    name: "step-7",
    component: PatientRegisterStep7,
    meta: {
      title: meta.title + "Create New Account",
      auth: true
    }
  },
  {
    path: "/patient/step-8",
    name: "step-8",
    component: PatientRegisterStep8,
    meta: {
      title: meta.title + "Create New Account",
      auth: true
    }
  },
  {
    path: "/patient/prescriptions",
    name: "prescriptions",
    component: Prescriptions,
    meta: {
      title: meta.title + "Prescriptions",
      auth: true
    }
  },
  {
    path: "/patient/new-refill",
    name: "new-refill",
    component: NewPrescription,
    meta: {
      title: meta.title + "Request a Refill",
      auth: true
    }
  },
  {
    path: "/patient/new-prescription",
    name: "new-prescription",
    component: FreePrescription,
    meta: {
      title: meta.title + "Request New Prescription",
      auth: true
    }
  },
  {
    path: "/patient/request/:prescriptionId",
    name: "patient-prescription-details",
    component: PatientPrescriptionDetails,
    props: true,
    meta: {
      title: meta.title + "Request Details",
      auth: true
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      title: meta.title + "Forgot Password",
    }
  },
  {
    path: "/reset-password/:token",
    props: true,
    name: "reset-password",
    component: ResetPassword,
    meta: {
      title: "Password Reset",
    }
  },
  {
    path: "/verify-account",
    name: "verify-account",
    component: EmailVerification,
    meta: {
      title: "Account Verification",
    }
  },
  {
    path: "/term-condition",
    name: "term-condition",
    component: TermCondition,
    meta: {
      title: meta.title + "Terms & Conditions",
    }
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: meta.title + "Privacy Policy",
    }
  },
  {
    path: "/signup",
    name: "signup",
    component: Register,
    meta: {
      title: meta.title + "Registration",
    }
  },
  {
    path: "/covid19",
    name: "covid19",
    component: Covid19,
    meta: {
      title: meta.title + "Online Prescription Refill",
    }
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs,
    meta: {
      title: meta.title + "About Us",
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactUs,
    meta: {
      title: meta.title + "Contact Us",
    }
  },
  {
    path: "/patient/booking",
    name: "booking",
    component: Booking,
  },
  {
    path: "/faq-refill",
    name: "faq-refill",
    component: FAQRefill,
    meta: {
      title: meta.title + "FAQ",
    }
  },
  {
    path: "/faq-price",
    name: "faq-price",
    component: FAQPrice,
    meta: {
      title: meta.title + "FAQ",
    }
  },
  {
    path: "/faq-pharmacy",
    name: "faq-pharmacy",
    component: FAQPharmacy,
    meta: {
      title: meta.title + "FAQ",
    }
  },
  {
    path: "/faq-bill",
    name: "faq-bill",
    component: FAQBill,
    meta: {
      title: meta.title + "FAQ",
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFound,
    meta: {
      title: "Page not found",
    }
  }
];

Diseases.data().diseases.forEach(disease => {
  routes.push({
    path: disease.route,
    name: disease.name,
    component: () => import(`../components/diseases-pages/${disease.component}`),
    meta: {
      title: meta.title + "FAQ",
    }
  })
})

Medical_Conditions.data().conditions.forEach(condition => {
  routes.push({
    path: condition.route,
    name: condition.name,
    component: () => import(`../components/conditions-pages/${condition.component}`),
    meta: {
      title: meta.title + "FAQ",
    }
  })
})

Medicines.data().medicines.forEach(medicine => {
  routes.push({
    path: medicine.route,
    name: medicine.name,
    component: () => import(`../components/medicines-pages/${medicine.component}`),
    meta: {
      title: meta.title + "FAQ",
    }
  })
})
export const router = createRouter({
  history: createWebHistory("/app"),
  linkActiveClass: "active",
  routes,
});
