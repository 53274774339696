<template>
  <!-- FAQ Section -->
  <section class="section section-blogs">
    <div class="container-fluid">
      <!-- Section Header -->
      <div class="section-header text-center">
        <h2>
          {{ $t("homePage.faqSection.title") }}
        </h2>
        <p class="sub-title">
          {{ $t("homePage.faqSection.description") }}
        </p>
      </div>
      <!-- /Section Header -->
      <div class="row blog-grid-row">
        <div class="col-12 col-md-6 col-sm-12 col-lg-6">
          <div id="accordion" role="tablist" aria-multiselectable="true">
            <div class="card">
              <div class="card-header" role="tab" id="headingOne">
                <div class="mb-0">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false"
                    aria-controls="collapseOne" class="collapsed">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    <h3>
                      {{ $t("homePage.faqSection.faqRefill.refillQue1") }}
                    </h3>
                  </a>
                  <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                </div>
              </div>

              <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false"
                style="">
                <div class="card-block">
                  {{ $t("homePage.faqSection.faqRefill.refillAns1") }}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingTwo">
                <div class="mb-0">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false"
                    aria-controls="collapseOne" class="collapsed">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    <h3>
                      {{ $t("homePage.faqSection.faqRefill.refillQue2") }}
                    </h3>
                  </a>
                  <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                </div>
              </div>
              <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" aria-expanded="false">
                <div class="card-block">
                  {{ $t("homePage.faqSection.faqRefill.refillAns2") }}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingThree">
                <div class="mb-0">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false"
                    aria-controls="collapseOne" class="collapsed">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    <h3>
                      {{ $t("homePage.faqSection.faqRefill.refillQue3") }}
                    </h3>
                  </a>
                  <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                </div>
              </div>
              <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                aria-expanded="false">
                <div class="card-block">
                  {{ $t("homePage.faqSection.faqRefill.refillAns3") }}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingThree">
                <div class="mb-0">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false"
                    aria-controls="collapseOne" class="collapsed">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    <h3>
                      {{ $t("homePage.faqSection.faqRefill.refillQue4") }}
                    </h3>
                  </a>
                  <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                </div>
              </div>
              <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                aria-expanded="false">
                <div class="card-block">
                  {{ $t("homePage.faqSection.faqRefill.refillAns4") }}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingThree">
                <div class="mb-0">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false"
                    aria-controls="collapseOne" class="collapsed">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    <h3>
                      {{ $t("homePage.faqSection.faqRefill.refillQue5") }}
                    </h3>
                  </a>
                  <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                </div>
              </div>
              <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                aria-expanded="false">
                <div class="card-block">
                  {{ $t("homePage.faqSection.faqRefill.refillAns5") }}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" role="tab" id="headingThree">
                <div class="mb-0">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false"
                    aria-controls="collapseOne" class="collapsed">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    <h3>
                      {{ $t("homePage.faqSection.faqRefill.refillQue6") }}
                    </h3>
                  </a>
                  <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                </div>
              </div>
              <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingThree" aria-expanded="false">
                <div class="card-block">
                  {{ $t("homePage.faqSection.faqRefill.refillAns6") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-sm-12 col-lg-6">
          <div class="card">
            <div class="card-header" role="tab" id="headingThree">
              <div class="mb-0">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false"
                  aria-controls="collapseOne" class="collapsed">
                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  <h3>
                    {{ $t("homePage.faqSection.faqRefill.refillQue7") }}
                  </h3>
                </a>
                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
              </div>
            </div>
            <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingThree" aria-expanded="false">
              <div class="card-block">
                {{ $t("homePage.faqSection.faqRefill.refillAns7") }}
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" role="tab" id="headingOne">
              <div class="mb-0">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false"
                  aria-controls="collapseEight" class="collapsed">
                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  <h3>
                    {{ $t("homePage.faqSection.faqPrice.priceQue1") }}
                  </h3>
                </a>
                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
              </div>
            </div>

            <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false"
              style="">
              <div class="card-block">
                {{ $t("homePage.faqSection.faqPrice.priceAns1") }}
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" role="tab" id="headingOne">
              <div class="mb-0">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapseNone" aria-expanded="false"
                  aria-controls="collapseNone" class="collapsed">
                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  <h3>
                    {{
                      $t("homePage.faqSection.faqPharmacy.pharmacyQue1")
                    }}
                  </h3>
                </a>
                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
              </div>
            </div>

            <div id="collapseNone" class="collapse" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false"
              style="">
              <div class="card-block">
                {{ $t("homePage.faqSection.faqPharmacy.pharmacyAns1") }}
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" role="tab" id="headingOne">
              <div class="mb-0">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapseTen" aria-expanded="false"
                  aria-controls="collapseTen" class="collapsed">
                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  <h3>
                    {{ $t("homePage.faqSection.faqBill.billQue1") }}
                  </h3>
                </a>
                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
              </div>
            </div>

            <div id="collapseTen" class="collapse" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false"
              style="">
              <div class="card-block">
                {{ $t("homePage.faqSection.faqBill.billAns1.ans1")
                }}<br /><br />

                <strong>{{
                  $t("homePage.faqSection.faqBill.billAns1.ans2")
                }}</strong>

                <ol>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns1.ans3") }}
                  </li>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns1.ans4") }}
                  </li>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns1.ans5") }}
                  </li>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns1.ans6") }}
                  </li>
                </ol>

                {{ $t("homePage.faqSection.faqBill.billAns1.ans7") }}
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" role="tab" id="headingTwo">
              <div class="mb-0">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapseEleven" aria-expanded="false"
                  aria-controls="collapseOne" class="collapsed">
                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  <h3>
                    {{ $t("homePage.faqSection.faqBill.billQue2") }}
                  </h3>
                </a>
                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
              </div>
            </div>
            <div id="collapseEleven" class="collapse" role="tabpanel" aria-labelledby="headingTwo" aria-expanded="false">
              <div class="card-block">
                {{ $t("homePage.faqSection.faqBill.billAns2.ans1") }}
                <br />
                <br />{{
                  $t("homePage.faqSection.faqBill.billAns2.ans2")
                }}

                <ol>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns2.ans3") }}
                  </li>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns2.ans4") }}
                  </li>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns2.ans5") }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" role="tab" id="headingThree">
              <div class="mb-0">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwelve" aria-expanded="false"
                  aria-controls="collapseOne" class="collapsed">
                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  <h3>
                    {{ $t("homePage.faqSection.faqBill.billQue3") }}
                  </h3>
                </a>
                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
              </div>
            </div>
            <div id="collapseTwelve" class="collapse" role="tabpanel" aria-labelledby="headingThree"
              aria-expanded="false">
              <div class="card-block">
                {{ $t("homePage.faqSection.faqBill.billAns3.ans1")
                }}<br /><br />

                <ul>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns3.ans2") }}
                  </li>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns3.ans3") }}
                  </li>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns3.ans4") }}
                  </li>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns3.ans5") }}
                  </li>
                  <li>
                    {{ $t("homePage.faqSection.faqBill.billAns3.ans6") }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /FAQ Section -->
</template>

<script>
export default {
  methods: {
    moveUp () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style scoped>
.btn-text {
  color: white;
}

.icon-text {
  font-size: 65px;
  margin-bottom: 20px;
}
.card-block {
  padding: 20px;
}
</style>
