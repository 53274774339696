<template>
  <div class="card">
    <form @submit.prevent="rejectSubmit">
      <div class="card-body p-2">
        <div class="row">
          <div class="col-12">
            <h4>Prescription ID.: #{{ dspPrescriptionId }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h6>Are you sure you want to cancel the prescription request?</h6>
            <hr />

            <div class="form-group text-left">
              <label>Reasons for cancellation</label>
              <select class="form-control select" v-model="rejectionReason">
                <option
                  v-for="(reason, i) in reasons"
                  :key="i"
                  :value="reason.value"
                >
                  {{ reason.text }}
                </option>
              </select>
            </div>

            <hr />

            <h6>Important Notes</h6>
            <ul>
              <li>
                <small
                  >Cancelling pharmacy RxChange request will result in permenant
                  cancellation. It cannot be reversed.</small
                >
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <button type="submit" class="btn bg-danger-light mr-2 btn-block">
              <i class="fas fa-times"></i> Cancel RxChange Request
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import commonMixin from "../../mixins/common";

export default {
  props: {
    dspPrescriptionId: String,
    selectedRxChangeId: String,
  },
  data() {
    return {
      reasons: [
        { value: "DeniedChangeInappropriate", text: "Change Inappropriate" },
        { value: "DeniedNoPriorAuthAttempt", text: "No Prior Auth Attempt" },
        { value: "DeniedNewRx", text: "Denied New RX" },
        { value: "DeniedPatientUnknown", text: "Patient Unknown" },
        { value: "DeniedPatientNotUnderCare", text: "Patient Not Under Care" },
        {
          value: "DeniedPatientNoLongerUnderPatientCare",
          text: "Patient No Longer Under Care",
        },
        { value: "DeniedNeverPrescribed", text: "Never Prescribed" },
        { value: "DeniedNeedAppointment", text: "Need Appointment" },
      ],
      rejectionReason: "",
    };
  },
  methods: {
    rejectSubmit() {
      this.$emit(
        "submit",
        JSON.stringify({
          rxChangeId: this.selectedRxChangeId,
          reason: this.rejectionReason,
        })
      );
    },
  },
  mixins: [commonMixin],
};
</script>
