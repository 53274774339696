<template>
  <!-- FAQ Section -->
  <section class="section section-blogs">
    <div class="container-fluid">
      <!-- Section Header -->
      <div class="section-header text-center">
        <h2>
          {{ $t("homePage.diseasesSection.title") }}
        </h2>
        <p class="sub-title">
          {{ $t("homePage.diseasesSection.description") }}
        </p>
      </div>
      <!-- /Section Header -->
      <div class="row text-center">
        <div v-for="(start, last) in [0, 1, 2, 3]" :key="last" class="col-12 col-md-3 col-sm-6 col-lg-3 m-0 p-0">
          <ul class="list-group">
            <li class="list-group-item"
              v-for="(disease, index) in sortedDiseases.slice(start * (top_diseases.length / 4), (start + 1) * (top_diseases.length / 4))"
              :key="index">
              <router-link @click="moveUp" :to="disease.route" class="ml-1">{{ disease.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- /FAQ Section -->
</template>

<script>
export default {
  data() {
    const route_faq = '/faq-diseases'

    const top_diseases = [
      { route: route_faq + "/influenza", name: "Influenza (Flu)", component: 'Influenza' },
      { route: route_faq + "/common-cold", name: "Common Cold", component: 'Common-Cold' },
      { route: route_faq + "/allergies", name: "Allergies", component: 'Allergies' },
      { route: route_faq + "/sinusitis", name: "Sinusitis", component: 'Sinusitis' },
      { route: route_faq + "/gastroenteritis", name: "Gastroenteritis", component: 'Gastroenteritis' },
      { route: route_faq + "/urinary-tract-infection", name: "Urinary Tract Infection (UTI)", component: 'Urinary-tract-infection' },
      { route: route_faq + "/bronchitis", name: "Bronchitis", component: 'Bronchitis' },
      { route: route_faq + "/pneumonia", name: "Pneumonia", component: 'Pneumonia' },
      { route: route_faq + "/ear-infection", name: "Ear Infection", component: 'Ear-Infection' },
      { route: route_faq + "/arthritis", name: "Arthritis", component: 'Arthritis' },
      { route: route_faq + "/gerd", name: "Gastroesophageal Reflux Disease (GERD)", component: 'Gastroesophageal-Reflux-Disease' },
      { route: route_faq + "/gastrointestinal-issues", name: "Gastrointestinal Issues (e.g., indigestion, reflux)", component: 'Gastrointestinal' },
      { route: route_faq + "/eczema", name: "Eczema (Dermatitis)", component: 'Eczema' },
      { route: route_faq + "/thyroid-disorders", name: "Thyroid disorders", component: 'Thyroid_Disorders' },
      { route: route_faq + "/cardiovascular-diseases", name: "Cardiovascular Diseases", component: 'Cardiovascular_Diseases' },
      { route: route_faq + "/copd", name: "Chronic Obstructive Pulmonary Disease (COPD)", component: 'COPD' },
      { route: route_faq + "/conjunctivitis", name: "Conjunctivitis (Pink Eye)", component: 'Conjunctivitis' },
      { route: route_faq + "/osteoporosis", name: "Osteoporosis", component: 'Osteoporosis' },
      { route: route_faq + "/hypothyroidism", name: "Hypothyroidism", component: 'Hypothyroidism' },
      { route: route_faq + "/chronic-kidney-disease", name: "Chronic Kidney Disease", component: 'Chronic_Kidney_Disease' },
      { route: route_faq + "/fibromyalgia", name: "Fibromyalgia", component: 'Fibromyalgia' },
      { route: route_faq + "/peripheral-artery-disease", name: "Peripheral Artery Disease", component: 'Peripheral_Artery_Disease' },
      { route: route_faq + "/gout", name: "Gout", component: 'Gout' },
      { route: route_faq + "/hepatitis", name: "Hepatitis", component: 'Hepatitis' },
      { route: route_faq + "/ibd", name: "Inflammatory Bowel Disease (IBD)", component: 'IBD' },
      { route: route_faq + "/lyme", name: "Lyme Disease", component: 'Lyme' },
    ]

    return {
      top_diseases,
      diseases: [...top_diseases,
        // { route: route_faq + "/", name: "", component: '' }
      ]
    }
  },
  methods: {
    moveUp() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  computed: {
    sortedDiseases() {
      return this.top_diseases.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }
  }
};
</script>
<style scoped>
.btn-text {
  color: white;
}

.icon-text {
  font-size: 65px;
  margin-bottom: 20px;
}
</style>
