<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layout-header></layout-header>

      <!-- Page Content -->
      <div class="content bg-white">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Register Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">
                    <img src="../../assets/img/login-banner.png" class="img-fluid" alt="Doccure Register" />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right">
                    <div class="login-header">
                      <h3>{{ $t('patientRegister.title') }}</h3>
                    </div>
                    <hr />
                    <form @submit.prevent="createAnAccount">
                      <div class="row">
                        <div class="col-12">
                          <div :class="`form-group form-focus ${email ? 'focused' : ''}`">
                            <input type="email" name="email" class="form-control floating" v-model="email" required />
                            <label class="focus-label">Email Address</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div :class="`form-group form-focus ${mobileNumber ? 'focused' : ''}`">
                            <input type="text" name="mobile" v-model="mobileNumber" @keyup="formatMobileNumber"
                              class="form-control floating" required />
                            <label class="focus-label">Mobile Number</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div :class="`form-group form-focus ${password ? 'focused' : ''}`">
                            <input v-if="showPassword" type="text" v-model="password" class="form-control floating"
                              required />
                            <input v-else type="password" v-model="password" class="form-control floating" required />
                            <label class="focus-label">Password</label>
                            <span class="p-viewer2">
                              <i class="fa" :class="{
                        'fa-eye-slash': showPassword,
                        'fa-eye': !showPassword,
                      }" aria-hidden="true" @click="toggleShow">
                              </i>
                            </span>
                          </div>
                        </div>
                        <div class="col-12">
                          <div :class="`form-group form-focus ${repeatPassword ? 'focused' : ''}`">
                            <input type="password" v-model="repeatPassword" class="form-control floating" required />
                            <label class="focus-label">Repeat Password</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div :class="`form-group form-focus ${referralCode ? 'focused' : ''}`">
                            <input type="text" v-model="referralCode" class="form-control floating" />
                            <label class="focus-label">Referral Code (Optional)</label>
                          </div>
                        </div>
                      </div>
                      <button id="signup-btn" type="submit" class="btn btn-primary btn-block btn-lg login-btn">
                        {{ $t('patientRegister.createBtn') }}
                      </button>
                    </form>

                    <!-- <div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">or</span>
                    </div>
                    <div class="row form-row social-login">
                      <div class="col-6">
                        <button
                          disabled
                          class="btn btn-facebook btn-block"
                        >
                          <i class="fab fa-facebook-f mr-1"></i> Facebook
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          disabled
                          class="btn btn-google btn-block"
                        >
                          <i class="fab fa-google mr-1"></i> Google
                        </button>
                      </div>
                    </div> -->

                    <!-- /Register Form -->
                    <div class="text-center mt-4 dont-have">
                      {{ $t('patientRegister.loginLabel') }}
                      <router-link class="forgot-link" to="/login">{{ $t('patientRegister.loginLink') }}.</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Register Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <layout-footer></layout-footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Index from "../Index.vue";
import Swal from "sweetalert2";

export default {
  components: { Index },
  props: {
    code: String,
  },
  data() {
    return {
      email: "",
      mobileNumber: "",
      password: "",
      repeatPassword: "",
      referralCode: this.code,
      showPassword: "",
      selectedPhoneCode: "",
    };
  },
  methods: {
    ...mapActions([
      "registerUserDetails",
      "showLoader",
    ]),

    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    formatMobileNumber() {
      this.mobileNumber = this.mobileNumber.replace("-", "");
      this.mobileNumber = this.mobileNumber.replace(" ", "");
      this.mobileNumber = this.mobileNumber.replace("(", "");
      this.mobileNumber = this.mobileNumber.replace(")", "");
      this.mobileNumber = this.mobileNumber.replace(/\D/g, "").slice(-10);
    },

    async createAnAccount() {
      try {

        let mobNumber = this.mobileNumber;
        if (mobNumber.length > 11 || mobNumber < 10) {
          Swal.fire("Ooops!", "Invalid Phone Number", "error");
          return;
        }

        if (mobNumber.length === 10 && !mobNumber.includes("+")) {
          mobNumber = this.selectedPhoneCode + this.mobileNumber;
        }

        if (this.password !== this.repeatPassword) {
          Swal.fire("Password Mismatch", "", "error");
          return;
        }

        if (!this.validatePhoneNumber(this.mobileNumber)) {
          Swal.fire("Invalid Phone Number", "", "error");
          return;
        }

        this.showLoader(true);

        const body = {
          email: this.email,
          password: this.password,
          mobileNumber: mobNumber,
          role: "patient",
          referralCode: this.referralCode,
        };

        await this.registerUserDetails(body);

        Swal.fire({
          icon: "success",
          title: `Verification Needed!`,
          text: `We've sent a verification email. Please verify your email to activate the account.`,
        }).then(() => {
          this.email = "";
          this.password = "";
          this.repeatPassword = "";
          this.mobileNumber = ""
          this.codeHandler = null;
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        let msg = "";
        if (e.response) {
          msg = e.response.data.message;
        } else {
          msg = `Verification failed`;
        }

        Swal.fire("Ooops!", msg, "error");
        this.showLoader(false);
      }
    },
    validatePhoneNumber(mobileNumber) {
      return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        mobileNumber
      );
    },
  },
};
</script>

<style>
.p-viewer2 {
  float: right;
  margin-top: -35px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>
