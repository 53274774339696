<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <breadcrumb42></breadcrumb42>

    <!-- Process Sections-->
    <section class="facility-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 m-auto">
            <div class="section-header text-center">
              <h2>{{ $t("contactUs.title") }}</h2>
              <p class="sub-title">
                {{ $t("contactUs.description") }}
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-4 col-md-4">
            <div class="visit-doctor">
              <div class="inner-details mr-2">
                <div class="count">
                  <h1><i class="fas fa-phone"></i></h1>
                </div>
                <div class="info">
                  <h1>{{ $t("contactUs.phoneNumber.title") }}</h1>
                  <p>+1-(678)-820-2221</p>
                  <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-4">
            <div class="visit-doctor">
              <div class="inner-details lab">
                <div class="count">
                  <h1><i class="fas fa-phone-alt"></i></h1>
                </div>
                <div class="info">
                  <h1>{{ $t("contactUs.tollFreeNumber.title") }}</h1>
                  <p>+1-(855)-725-7629</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-4">
            <div class="visit-doctor">
              <div class="inner-details pharmacy">
                <div class="count">
                  <h1><i class="fas fa-envelope"></i></h1>
                </div>
                <div class="info">
                  <h1>{{ $t("contactUs.supportEmail.title") }}</h1>
                  <p>{{ $t("contactUs.supportEmail.email") }}</p>
                  <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Process Sections-->

    <section class="contact-form" style="padding: 30px">
      <div class="container">
        <div class="section-header text-center">
          <h2>{{ $t("contactUs.getInTouch.title") }}</h2>
        </div>
        <div class="row">
          <div class="col-md-12">
            <form action="">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("contactUs.getInTouch.yourNameLabel") }}
                      <span>*</span></label>
                    <input type="text" class="form-control" v-model="name" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("contactUs.getInTouch.yourEmailLabel") }}
                      <span>*</span></label>
                    <input type="text" class="form-control" v-model="email" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ $t("contactUs.getInTouch.subject") }}</label>
                    <input type="text" class="form-control" v-model="subject" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ $t("contactUs.getInTouch.comments") }}
                      <span>*</span></label>
                    <textarea class="form-control" v-model="comment">
                  </textarea>
                  </div>
                </div>
              </div>
              <button class="btn bg-primary" @click="submitForm">
                {{ $t("contactUs.getInTouch.button") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>

    <layout-footer class="footer"></layout-footer>
  </div>
</template>

<script>
import axios from "axios";
import config, { HTTP_API } from "../constants/config";

export default {
  data () {
    return {
      name: "",
      email: "",
      subject: "",
      comment: "",
    };
  },
  methods: {
    async submitForm () {
      // await axios.post(config.BASE_URL + HTTP_API.enquiry, {
      await axios.post(config.BASE_URL + HTTP_API.enquiry, {
        name: this.name,
        email: this.email,
        subject: this.subject,
        comment: this.comment,
      });
    },
  },
};
</script>

<style scoped>
.contact-form form {
  background: #ffffff;
  box-shadow: 0px 4px 5px 5px #e5f1fb;
  border-radius: 8px;
  padding: 30px;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

form {
  display: block;
}

.contact-section {
  padding: 50px 0 60px;
  background: linear-gradient(360deg,
      rgba(0, 113, 220, 0.06) 40.94%,
      rgba(229, 241, 251, 0) 100%);
}

.visit-doctor {
  height: 130px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}
</style>
