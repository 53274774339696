<template>
    <!-- Last Booking -->
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Last Booking</h4>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <div class="media align-items-center">
                    <div class="mr-3">
                        <img alt="Image placeholder" src="../../assets/img/doctors/doctor-thumb-02.jpg" class="avatar  rounded-circle">
                    </div>
                    <div class="media-body">
                        <h5 class="d-block mb-0">Dr. Darren Elder </h5>
                        <span class="d-block text-sm text-muted">Dentist</span>
                        <span class="d-block text-sm text-muted">14 Nov 2019 5.00 PM</span>
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="media align-items-center">
                    <div class="mr-3">
                        <img alt="Image placeholder" src="../../assets/img/doctors/doctor-thumb-02.jpg" class="avatar  rounded-circle">
                    </div>
                    <div class="media-body">
                        <h5 class="d-block mb-0">Dr. Darren Elder </h5>
                        <span class="d-block text-sm text-muted">Dentist</span>
                        <span class="d-block text-sm text-muted">12 Nov 2019 11.00 AM</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <!-- /Last Booking -->
</template>