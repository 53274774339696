<template>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-header">
          <h4>
            {{ $t("provider.sideBar.accountSetting.accountSettings.title") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <!-- Change Password Form -->
              <form @submit.prevent="changePassword">
                <div class="form-group">
                  <label>{{
                    $t(
                      "provider.sideBar.accountSetting.accountSettings.oldPassword"
                    )
                  }}</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="oldPassword"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>{{
                    $t(
                      "provider.sideBar.accountSetting.accountSettings.newPassword"
                    )
                  }}</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="newPassword"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>{{
                    $t(
                      "provider.sideBar.accountSetting.accountSettings.confirmPassword"
                    )
                  }}</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="repeatPassword"
                    required
                  />
                </div>
                <hr />
                <div class="submit-section text-center">
                  <button type="submit" class="btn btn-sm btn-primary">
                    {{
                      $t(
                        "provider.sideBar.accountSetting.accountSettings.button"
                      )
                    }}
                  </button>
                </div>
              </form>
              <!-- /Change Password Form -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
    };
  },
  methods: {
    ...mapActions(["changeUserPassword", "showAlert", "showLoader"]),
    async changePassword() {
      try {
        if (this.newPassword !== this.repeatPassword) {
          Swal.fire("Passwords do not match", "", "warning");
          return;
        }

        this.showLoader(true);

        await this.changeUserPassword({
          id: this.$store.getters.user.id,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          role: this.$store.getters.user.role,
        });

        Swal.fire("Password Changed!", "", "success");

        this.oldPassword = "";
        this.newPassword = "";
        this.repeatPassword = "";

        this.showLoader(false);
      } catch (e) {
        Swal.fire("Ooops!", e.response.data.message, "error");

        this.oldPassword = "";
        this.newPassword = "";
        this.repeatPassword = "";
        this.showLoader(false);
      }
    },
  },
};
</script>
