<template>
  <div>
    <form @submit.prevent="sendWithdrawRequest">
      <div class="card">
        <div class="card-header">
          <h4>Withdrawal Request</h4>
        </div>
        <div class="card-body p-3">
          <div class="row mt-3">
            <div class="col-8">
              <h5>Available Withdrawable Amount:</h5>
            </div>
            <div class="col-4">
              <h5>${{ availableAmount }} USD</h5>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-8">
              <h5>Enter Withdrawal Amount:</h5>
              <h6>Minimum withdrawal required: $10 USD</h6>
            </div>
            <div class="col-4">
              <input
                type="number"
                class="form-control"
                placeholder="10"
                :min="10"
                :max="availableAmount"
                v-model="withdrawalAmount"
                required
              />
            </div>
          </div>
          <hr />
          <div class="row mt-4 mb-4">
            <div class="col-4">
              <h5>PayPal Email:</h5>
            </div>
            <div class="col-8" style="font-size: 13px">
              {{ $store.getters.user.paypal_email }}
            </div>
          </div>
          <hr />
          <p>
            <small>
              Please verify your PayPal email. In case of correction or change, please go
              to Account Settings -> Payment Settings. All the transfers are executed
              based on the information provided by the account holder, hence no refunds
              will be provided for incorrect completed withdrawal requests.
            </small>
          </p>
          <p>
            <small>
              Your withdrawal request will be validated and verified by our team. The
              payment will be released within 24 hours from the withdrawal request time.
              You can cancel the withdrawal request any time before 2 hours after the
              request is sent.
            </small>
          </p>
          <p>
            <small>
              For any queries related to finances and withdrawals, please contact our
              support team or create a support ticket in the help desk.</small
            >
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="submit" class="btn bg-success-light mr-2 mb-4 btn-block">
            <i class="fas fa-check"></i> Send Withdrawal Request
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    availableAmount: Number,
  },
  data() {
    return {
      withdrawalAmount: 10,
    };
  },
  methods: {
    sendWithdrawRequest() {
      this.$emit("sendWithdrawRequest", this.withdrawalAmount);
      this.withdrawalAmount = 10;
    },
  },
};
</script>
