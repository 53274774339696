<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <breadcrumb41></breadcrumb41>

    <!-- Hero section -->
    <section class="section aboutus-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="left">
              <div class="section-header mt-5">
                <h2>{{ $t("aboutUs.title") }}</h2>
              </div>
              <p>{{ $t("aboutUs.description1") }}</p>
              <p>
                {{ $t("aboutUs.description2") }}
              </p>
              <p>
                {{ $t("aboutUs.description3") }}
              </p>
              <p>
                {{ $t("aboutUs.description4") }}
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="right">
              <img src="../assets/img/aboutus.PNG" alt="About us" style="border-radius: 50%" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Process section -->
    <process id="process"></process>

    <section class="section home-tile-section">
      <div class="container-fluid" style="
          background-color: #e5f1fb;
          padding-top: 30px;
          padding-bottom: 60px;
        ">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header text-center">
              <h1 style="margin-top: 40px; color: #005ba6">
                {{ $t("aboutUs.getPrescribed.title") }}
              </h1>
              <p>
                {{ $t("aboutUs.getPrescribed.description") }}
              </p>
            </div>
          </div>
        </div>
        <!-- Web View -->
        <div class="container">
          <div class="row text-center">
            <div class="col-10 col-lg-2">
              <div class="visit-doctor">
                <div class="inner-details">
                  <div class="count" style="margin-left: 48px">
                    <h1>01</h1>
                  </div>
                  <div class="info">
                    <p style="color: #1860ca">
                      {{ $t("aboutUs.getPrescribed.head1") }}
                      <!-- <i class="fas fa-long-arrow-alt-right"></i> -->
                    </p>
                    <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 col-lg-2 col-half-offset">
              <div class="visit-doctor">
                <div class="inner-details pharmacy">
                  <div class="count" style="margin-left: 48px">
                    <h1>02</h1>
                  </div>
                  <div class="info">
                    <p style="color: #0ce0ff">
                      {{ $t("aboutUs.getPrescribed.head2") }}
                      <!-- <i class="fas fa-long-arrow-alt-right"></i> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 col-lg-2 col-half-offset">
              <div class="visit-doctor">
                <div class="inner-details lab">
                  <div class="count" style="margin-left: 48px">
                    <h1>03</h1>
                  </div>
                  <div class="info">
                    <p style="color: #00bcd1">
                      {{ $t("aboutUs.getPrescribed.head3") }}
                      <!-- <i class="fas fa-long-arrow-alt-right"></i> -->
                    </p>
                    <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 col-lg-2 col-half-offset">
              <div class="visit-doctor">
                <div class="inner-details">
                  <div class="count" style="margin-left: 48px">
                    <h1>04</h1>
                  </div>
                  <div class="info">
                    <p style="color: #1860ca">
                      {{ $t("aboutUs.getPrescribed.head4") }}
                      <!-- <i class="fas fa-long-arrow-alt-right"></i> -->
                    </p>
                    <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 col-lg-2 col-half-offset">
              <div class="visit-doctor">
                <div class="inner-details pharmacy">
                  <div class="count" style="margin-left: 48px">
                    <h1>05</h1>
                  </div>
                  <div class="info">
                    <p style="color: #0ce0ff">
                      {{ $t("aboutUs.getPrescribed.head5") }}
                    </p>
                    <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- / Web View -->

        <!-- Mobile View -->

        <!-- /Mobile View -->
      </div>
    </section>

    <!-- surescript section -->
    <section class="facility-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 m-auto">
            <div class="section-header text-center">
              <h2>{{ $t("aboutUs.certifiedBySurescripts.title") }}</h2>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-6">
            <div class="section-header">
              <p>
                {{ $t("aboutUs.certifiedBySurescripts.description1") }}
              </p>
              <p>
                {{ $t("aboutUs.certifiedBySurescripts.description2") }}
              </p>
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <img src="../assets/img/Surescripts_certified-transparent.png" width="550" alt="" class="operat-img" />
          </div>
        </div>
      </div>
    </section>

    <section class="section home-tile-section">
      <div class="container-fluid" style="
          background-color: #e5f1fb;
          padding-top: 30px;
          padding-bottom: 60px;
        ">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header text-center">
              <h1 style="margin-top: 40px; color: #005ba6">
                {{ $t("aboutUs.refillRequest.title") }}
              </h1>
              <p>
                {{ $t("aboutUs.refillRequest.description") }}
              </p>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row text-center">
            <div class="col-10 col-lg-2">
              <div class="visit-doctor">
                <div class="inner-details">
                  <div class="count" style="margin-left: 48px">
                    <h1>01</h1>
                  </div>
                  <div class="info">
                    <p style="color: #1860ca">
                      {{ $t("aboutUs.refillRequest.head1") }}
                      <!-- <i class="fas fa-long-arrow-alt-right"></i> -->
                    </p>
                    <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 col-lg-2 col-half-offset">
              <div class="visit-doctor">
                <div class="inner-details pharmacy">
                  <div class="count" style="margin-left: 48px">
                    <h1>02</h1>
                  </div>
                  <div class="info">
                    <p style="color: #0ce0ff">
                      {{ $t("aboutUs.refillRequest.head2") }}
                      <!-- <i class="fas fa-long-arrow-alt-right"></i> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 col-lg-2 col-half-offset">
              <div class="visit-doctor">
                <div class="inner-details lab">
                  <div class="count" style="margin-left: 48px">
                    <h1>03</h1>
                  </div>
                  <div class="info">
                    <p style="color: #00bcd1">
                      {{ $t("aboutUs.refillRequest.head3") }}
                      <!-- <i class="fas fa-long-arrow-alt-right"></i> -->
                    </p>
                    <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 col-lg-2 col-half-offset">
              <div class="visit-doctor">
                <div class="inner-details">
                  <div class="count" style="margin-left: 48px">
                    <h1>04</h1>
                  </div>
                  <div class="info">
                    <p style="color: #1860ca">
                      {{ $t("aboutUs.refillRequest.head4") }}
                      <!-- <i class="fas fa-long-arrow-alt-right"></i> -->
                    </p>
                    <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-10 col-lg-2">
              <div class="visit-doctor">
                <div class="inner-details">
                  <div class="count">
                    <h1>04</h1>
                  </div>
                  <div class="info">
                    <p style="color: #1860ca">
                      Enter your dosage information
                      <i class="fas fa-long-arrow-alt-right"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-10 col-lg-2 col-half-offset">
              <div class="visit-doctor">
                <div class="inner-details pharmacy">
                  <div class="count" style="margin-left: 48px">
                    <h1>05</h1>
                  </div>
                  <div class="info">
                    <p style="color: #0ce0ff">
                      {{ $t("aboutUs.refillRequest.head5") }}
                    </p>
                    <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section
      class="section home-tile-section"
      style="padding:22px"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 features-img text-center">
            <img
              src="../assets/img/doctor-consult.jpg"
              style="height:300px; width:400px; margin-top:45px; border-radius: 25px;"
              alt="customers"
            />
          </div>
          <div
            class="col-md-6 "
            style="margin-top:120px"
          >
            <div class="section-header text-center">
              <h2
                class="mt-3"
                style="margin center"
              >
                Who your customers are
              </h2>
              <p>
                The app is for any patient seeking for immediate telehealth
                urgent primary care.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Terms Section-->
    <section class="doctor-divison">
      <div class="d-flex">
        <div class="doc-background" style="width: 100%;">
          <h3>{{ $t("aboutUs.termsCondition.title") }}</h3>
          <p>
            {{ $t("aboutUs.termsCondition.description") }}
          </p>
          <router-link @click="moveUp" to="/term-condition" class="doc-bok-btn" style="width: 100%">{{
            $t("aboutUs.termsCondition.button")
          }}</router-link>
        </div>
        <div class="pat-background" style="width: 100%;">
          <h3>{{ $t("aboutUs.privacyPolicy.title") }}</h3>
          <p>
            {{ $t("aboutUs.privacyPolicy.description") }}
          </p>
          <router-link @click="moveUp" to="/privacy-policy" class="doc-bok-btn" style="width: 100%; color: black">{{
            $t("aboutUs.privacyPolicy.button") }}</router-link>
        </div>
      </div>
    </section>
    <!-- /Terms Section-->

    <!-- Faq section -->
    <FaqHome></FaqHome>
    <!-- End of Faq section -->

    <layout-footer class="footer"></layout-footer>
  </div>
</template>

<script>
export default {
  methods: {
    moveUp() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  }
}
</script>

<style scoped>
h3 {
  margin-top: 20px;
}

.col-half-offset {
  margin-left: 4.166666667%;
}

@media only screen and (max-width: 600px) {
  img {
    display: none;
  }

  .webView {
    display: none;
  }

  .mobileView {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}
</style>
