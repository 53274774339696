<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <img width="300" src="../../assets/img/logo.png" alt="" />
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/patient/step-1" class="active-done">1</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-2" class="active-done">2</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-3" class="active-done">3</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-4" class="active-done">4</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-5" class="active-done">5</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-6" class="active-done">6</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-7" class="active-done">7</a>
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">8</a>
                        </li>
                      </ul>
                    </div>
                    <form @submit.prevent="completeRegistration">
                      <div class="profile-pic-col">
                        <h3>Profile Picture</h3>
                        <div class="profile-pic-upload">
                          <div class="cam-col">
                            <img src="../../assets/img/icons/camera.svg" id="prof-avatar" alt="" class="img-fluid" />
                          </div>
                          <span>Upload Profile Picture</span>
                          <input type="file" id="profile_image" name="profile_image" />
                        </div>
                      </div>
                      <!-- <div class="mt-3">
                        <button
                          type="submit"
                          class="btn btn-primary btn-block btn-lg login-btn step1_submit"
                        >Continue</button>
                      </div> -->
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-6">
                            <a href="javascript:void()" class="btn btn-primary btn-block btn-lg login-btn"
                              @click="goBack">
                              Back
                            </a>
                          </div>
                          <div class="col-6">
                            <button type="submit" class="btn btn-primary btn-block btn-lg login-btn step1_submit">
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span>© 2023. StepAhead Health Inc. All Rights Reserved.</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data () {
    return {
      photo: "../../assets/img/blank.png",
    };
  },
  mounted () {
    const self = this;

    if (this.$store.getters.user.is_registration_complete) {
      this.$router.push("/patient/index");
      return;
    }

    if (
      this.$store.getters.user &&
      this.$store.getters.user.profile_image_url
    ) {
      $("#prof-avatar").attr("src", this.$store.getters.user.profile_image_url);
    }

    function readURL (input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#prof-avatar").attr("src", e.target.result);
          self.savePhoto();
        };

        reader.readAsDataURL(input.files[0]);
        self.photo = input.files[0];
      }
    }

    $("#profile_image").change(function () {
      readURL(this);
    });
  },
  methods: {
    goBack () {
      this.$router.push("/patient/step-7");
    },
    ...mapActions([
      "saveUserProfilePhoto",
      "completeRegistrationProcess",
      "showLoader",
    ]),
    async savePhoto () {
      try {
        this.showLoader(true);
        let formData = new FormData();
        formData.append("photo", this.photo);
        formData.append("id", this.$store.getters.user.id);
        await this.saveUserProfilePhoto(formData);
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Ooops!", e.response ? e.response.data.message : e, "error");
        console.error(e);
      }
    },
    async completeRegistration () {
      try {
        this.showLoader(true);
        await this.completeRegistrationProcess({
          id: this.$store.getters.user.id,
        });

        this.$router.push("/patient/index");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Ooops!", e.response ? e.response.data.message : e, "error");
        console.error(e);
      }
    },
  },
};
</script>
