<template>
  <div>
    <div class="main-wrapper">
      <layout-header></layout-header>

      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <h2 class="breadcrumb-title">
                {{ $t("provider.sideBar.financialDashboard.title") }}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div class="stickyside">
                <sidebar></sidebar>
              </div>
            </div>

            <div class="col-md-7 col-lg-8 col-xl-9">
              <!-- Earnings summary -->
              <div class="row">
                <div class="col-lg-12 d-flex">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-4">
                          <h4>
                            {{
                              $t("provider.sideBar.financialDashboard.title2")
                            }}
                          </h4>
                        </div>
                        <div class="col-8">
                          <div class="text-right">
                            <button
                              class="btn btn-sm bg-danger-light mr-2"
                              @click="toggleModal('download-statement-modal')"
                            >
                              <i class="fas fa-file-pdf mr-1"></i>
                              {{
                                $t(
                                  "provider.sideBar.financialDashboard.requestButton1"
                                )
                              }}
                            </button>
                            <button
                              class="btn btn-sm bg-success-light"
                              @click="toggleModal('request-payout-modal')"
                            >
                              <i class="fas fa-dollar-sign mr-1"></i>
                              {{
                                $t(
                                  "provider.sideBar.financialDashboard.requestButton2"
                                )
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                          <div class="account-card bg-purple-light">
                            <span
                              >${{
                                overviewObj.totalGrossIncome
                                  ? parseFloat(
                                      overviewObj.totalGrossIncome
                                    ).toFixed(2)
                                  : 0
                              }}
                              <small> USD</small></span
                            >
                            <small>{{
                              $t(
                                "provider.sideBar.financialDashboard.statistics.stat1"
                              )
                            }}</small>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                          <div class="account-card bg-warning-light">
                            <span
                              >${{
                                overviewObj.totalDeductions
                                  ? parseFloat(
                                      overviewObj.totalDeductions
                                    ).toFixed(2)
                                  : 0
                              }}
                              <small> USD</small></span
                            >
                            <small>{{
                              $t(
                                "provider.sideBar.financialDashboard.statistics.stat2"
                              )
                            }}</small>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                          <div class="account-card bg-info-light">
                            <span
                              >${{
                                overviewObj.availableWithdrawal
                                  ? parseFloat(
                                      overviewObj.availableWithdrawal
                                    ).toFixed(2)
                                  : 0
                              }}
                              <small> USD</small></span
                            >
                            <small>{{
                              $t(
                                "provider.sideBar.financialDashboard.statistics.stat3"
                              )
                            }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                          <div class="account-card bg-success-light">
                            <span
                              >${{
                                overviewObj.totalNetIncome
                                  ? parseFloat(
                                      overviewObj.totalNetIncome
                                    ).toFixed(2)
                                  : 0
                              }}
                              <small> USD</small></span
                            >
                            <small>
                              {{
                                $t(
                                  "provider.sideBar.financialDashboard.statistics.stat4"
                                )
                              }}
                            </small>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                          <div class="account-card bg-warning-light">
                            <span
                              >${{
                                overviewObj.totalRefunded
                                  ? parseFloat(
                                      overviewObj.totalRefunded
                                    ).toFixed(2)
                                  : 0
                              }}
                              <small> USD</small></span
                            >
                            <small>
                              {{
                                $t(
                                  "provider.sideBar.financialDashboard.statistics.stat5"
                                )
                              }}
                            </small>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                          <div class="account-card bg-info-light">
                            <span
                              >${{
                                overviewObj.totalWithdrawn
                                  ? parseFloat(
                                      overviewObj.totalWithdrawn
                                    ).toFixed(2)
                                  : 0
                              }}
                              <small> USD</small></span
                            >
                            <small>{{
                              $t(
                                "provider.sideBar.financialDashboard.statistics.stat6"
                              )
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Transactions -->
              <div class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body pt-0">
                      <nav class="user-tabs mb-4">
                        <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              href="#recent-txns"
                              data-toggle="tab"
                              >{{
                                $t(
                                  "provider.sideBar.financialDashboard.lastTransactions.title"
                                )
                              }}</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              href="#refund-reqs"
                              data-toggle="tab"
                            >
                              {{
                                $t(
                                  "provider.sideBar.financialDashboard.refundRequest.title"
                                )
                              }}
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              href="#withdrawal-reqs"
                              data-toggle="tab"
                            >
                              {{
                                $t(
                                  "provider.sideBar.financialDashboard.withdrawalRequests.title"
                                )
                              }}
                            </a>
                          </li>
                        </ul>
                      </nav>

                      <div class="tab-content pt-0">
                        <!-- LAST 10 TRANSACTIONS -->
                        <div id="recent-txns" class="tab-pane fade show active">
                          <div class="card card-table mb-0">
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  v-if="last10Transactions.length > 0"
                                  class="table table-hover table-center mb-0"
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.lastTransactions.table.head1"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.lastTransactions.table.head2"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.lastTransactions.table.head3"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.lastTransactions.table.head4"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.lastTransactions.table.head5"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.lastTransactions.table.head6"
                                          )
                                        }}
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(txn, i) in last10Transactions"
                                      :key="i"
                                    >
                                      <td>
                                        {{ txn.prescription_id }}
                                      </td>
                                      <td>
                                        {{ txn.trans_id }}
                                      </td>
                                      <td>
                                        {{
                                          new Date(
                                            txn.created_at
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td>
                                        {{ txn.payer_name }}
                                      </td>
                                      <td>
                                        ${{ txn.total_amount }}
                                        {{ txn.currency }}
                                      </td>
                                      <td>
                                        <span
                                          :class="
                                            getPaymentStatusClass(txn.status)
                                          "
                                          >{{
                                            getPaymentStatus(txn.status)
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="p-5 text-center" v-else>
                                  <p>
                                    {{
                                      $t(
                                        "provider.sideBar.financialDashboard.lastTransactions.table.noTransactions"
                                      )
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- REFUND REQUESTS -->
                        <div class="tab-pane fade" id="refund-reqs">
                          <div class="card card-table mb-0">
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  class="table table-hover table-center mb-0"
                                  v-if="last10Refunds.length > 0"
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.refundRequest.table.head1"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.refundRequest.table.head2"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.refundRequest.table.head3"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.refundRequest.table.head4"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.refundRequest.table.head5"
                                          )
                                        }}
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(txn, i) in last10Refunds"
                                      :key="i"
                                    >
                                      <td>
                                        {{ txn.id }}
                                      </td>
                                      <td>
                                        {{ txn.paypal_email }}
                                      </td>
                                      <td>
                                        {{
                                          new Date(
                                            txn.created_at
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td>${{ txn.withdraw_amount }} USD</td>
                                      <td>
                                        <span
                                          :class="
                                            getWithdrawStatusClass(txn.status)
                                          "
                                          >{{
                                            getWithdrawStatus(txn.status)
                                          }}</span
                                        >
                                      </td>
                                      <td>
                                        <button
                                          v-if="txn.status !== 'CANCELLED'"
                                          class="btn btn-sm bg-danger-light btn-block"
                                        >
                                          {{
                                            $t(
                                              "provider.sideBar.financialDashboard.refundRequest.table.button"
                                            )
                                          }}
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="p-5 text-center" v-else>
                                  <p>
                                    {{
                                      $t(
                                        "provider.sideBar.financialDashboard.refundRequest.table.noRefundRequests"
                                      )
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- WITHDRAWAL REQUESTS -->
                        <div class="tab-pane fade" id="withdrawal-reqs">
                          <div class="card card-table mb-0">
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  v-if="last10Withdrawals.length > 0"
                                  class="table table-hover table-center mb-0"
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.withdrawalRequests.table.head1"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.withdrawalRequests.table.head2"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.withdrawalRequests.table.head3"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.withdrawalRequests.table.head4"
                                          )
                                        }}
                                      </th>
                                      <th>
                                        {{
                                          $t(
                                            "provider.sideBar.financialDashboard.withdrawalRequests.table.head5"
                                          )
                                        }}
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(txn, i) in last10Withdrawals"
                                      :key="i"
                                    >
                                      <td>
                                        {{ txn.id }}
                                      </td>
                                      <td>
                                        {{ txn.paypal_email }}
                                      </td>
                                      <td>
                                        {{
                                          new Date(
                                            txn.created_at
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td>${{ txn.withdraw_amount }} USD</td>
                                      <td>
                                        <span
                                          :class="
                                            getWithdrawStatusClass(txn.status)
                                          "
                                          >{{
                                            getWithdrawStatus(txn.status)
                                          }}</span
                                        >
                                      </td>
                                      <td>
                                        <button
                                          class="btn btn-sm bg-danger-light btn-block"
                                          @click="
                                            confirmCancelWithdrawal(txn.id)
                                          "
                                          :disabled="txn.status === 'CANCELLED'"
                                        >
                                          {{
                                            $t(
                                              "provider.sideBar.financialDashboard.withdrawalRequests.table.button"
                                            )
                                          }}
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="p-5 text-center" v-else>
                                  <p>
                                    {{
                                      $t(
                                        "provider.sideBar.financialDashboard.withdrawalRequests.table.noWithdrawal"
                                      )
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Download statement modal -->
      <div
        class="modal fade"
        id="download-statement-modal"
        style="display: none"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <DownloadStatement></DownloadStatement>
            </div>
          </div>
        </div>
      </div>

      <!-- Request payout modal -->
      <div class="modal fade" id="request-payout-modal" style="display: none">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <RequestPayout
                :availableAmount="overviewObj.availableWithdrawal"
                @sendWithdrawRequest="sendWithdrawRequest"
              >
              </RequestPayout>
            </div>
          </div>
        </div>
      </div>

      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "../../constants/config";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import RequestPayout from "./finances/RequestPayoutModal.vue";
import DownloadStatement from "./finances/DownloadStatement.vue";

export default {
  components: {
    RequestPayout,
    DownloadStatement,
  },
  data() {
    return {
      overviewObj: {
        totalGrossIncome: 0,
        totalNetIncome: 0,
        totalDeductions: 0,
        availableWithdrawal: 0,
        totalWithdrawn: 0,
        totalRefunded: 0,
      },
      last10Transactions: [],
      last10Withdrawals: [],
      last10Refunds: [],
    };
  },
  async mounted() {
    await this.getFinancialSummary();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    ...mapActions(["showLoader"]),
    toggleModal(modalName) {
      $("#" + modalName).modal("toggle");
    },

    async sendWithdrawRequest(amount) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSendWithdrawRequest}${this.user.id}`,
          {
            withdrawalAmount: amount,
            paypalEmail: this.user.paypal_email,
          }
        );

        this.toggleModal("request-payout-modal");
        this.showLoader(false);
        Swal.fire(
          "Request Sent!",
          "Withdrawal request has been sent. Our team will verify your request and release the payout within 24 hours.",
          "success"
        ).then(async () => {
          await this.getFinancialSummary();
        });
      } catch (e) {
        Swal.fire("Ooops!", e.response.data.message, "error");
        this.toggleModal("request-payout-modal");
        this.showLoader(false);
      }
    },

    async confirmCancelWithdrawal(withdrawalId) {
      try {
        Swal.fire({
          title: "Are you sure?",
          icon: "question",
          html: `Don't worry! You can still create a new withdrawal request.`,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Yes",
          confirmButtonAriaLabel: "Yes",
          cancelButtonText: "No",
          cancelButtonAriaLabel: "No",
        })
          .then(async () => {
            this.showLoader(true);
            const res = await axios.post(
              `${config.BASE_URL}${config.HTTP_API.providerCancelWithdrawRequest}/${this.user.id}`,
              {
                withdrawalId: withdrawalId,
              }
            );

            this.showLoader(false);
            Swal.fire("Withdrawal Request Cancelled", "", "success").then(
              async () => {
                await this.getFinancialSummary();
              }
            );
          })
          .catch((e) => {
            this.showLoader(false);
          });
      } catch (e) {
        Swal.fire("Ooops!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    getHistoricalStatement() {},

    async getFinancialSummary() {
      try {
        this.showLoader(true);
        const res = await axios.get(
          `${config.BASE_URL}${config.HTTP_API.providerGetFinanceOverview}${this.user.id}`
        );
        this.overviewObj = { ...this.overviewObj, ...res.data };
        this.last10Transactions = res.data.last10Txns;
        this.last10Withdrawals = res.data.last10Withdrawals;
        this.showLoader(false);
      } catch (e) {
        Swal.fire("Ooops!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    getPaymentStatus(status) {
      if (status === "COMPLETED") return "Completed";
      return "Pending";
    },
    getPaymentStatusClass(status) {
      if (status === "COMPLETED") return "bg-success-light p-2";
      return "bg-danger-light p-2";
    },

    getWithdrawStatus(status) {
      if (status === "ACTIVE") return "In Process";
      if (status === "CANCELLED") return "Cancelled";
      return "Pending";
    },
    getWithdrawStatusClass(status) {
      if (status === "ACTIVE") return "bg-info-light p-2";
      if (status === "CANCELLED") return "bg-danger-light p-2";
      return "bg-danger-light p-2";
    },
  },
};
</script>
