<template>
    <!-- FAQ Section -->
    <section class="section section-blogs">
        <div class="container-fluid">
            <!-- Section Header -->
            <div class="section-header text-center">
                <h2>
                    {{ $t("homePage.faqSection.title") }}
                </h2>
                <p class="sub-title">
                    {{ $t("homePage.faqSection.description") }}
                </p>
            </div>
            <!-- /Section Header -->
            <div class="row blog-grid-row">
                <div class="col-12 col-md-6 col-sm-12 col-lg-6">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <div class="mb-0">
                                <h3>
                                    {{ $t("providerRegister.faq1") }}
                                </h3>
                                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                            </div>
                        </div>
                        <div class="card-body">
                            <video class="img-fluid" src="../../assets/videos/provider-register.mp4"
                                alt="Stepahead Register" controls />
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <div class="mb-0">
                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeven"
                                    aria-expanded="false" aria-controls="collapseOne" class="collapsed">
                                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                    <h3>
                                        {{ $t("providerRegister.faq2") }}
                                    </h3>
                                </a>
                                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                            </div>
                        </div>
                        <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                            aria-expanded="false">
                            <div class="card-block">
                                <ul>
                                    <li>{{ $t("providerRegister.faq2Answer") }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <div class="mb-0">
                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseOne" class="collapsed">
                                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                    <h3>{{ $t("providerRegister.faq3") }} </h3>
                                </a>
                                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                            </div>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                            aria-expanded="false">
                            <div class="card-block">
                                <ul>
                                    <li>{{ $t("providerRegister.faq3Answer") }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-sm-12 col-lg-6">
                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <div class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                        aria-expanded="false" aria-controls="collapseOne" class="collapsed">
                                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                        <h3>{{ $t("providerRegister.faq4") }}</h3>
                                    </a>
                                    <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                                </div>
                            </div>

                            <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne"
                                aria-expanded="false" style="">
                                <div class="card-block">
                                    <ul>
                                        <li>{{ $t("providerRegister.faq4Answer") }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <div class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                                        aria-expanded="false" aria-controls="collapseOne" class="collapsed">
                                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                        <h3>{{ $t("providerRegister.faq5") }}</h3>
                                    </a>
                                    <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                                </div>
                            </div>
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                                aria-expanded="false">
                                <div class="card-block">
                                    <ul>
                                        <li>{{ $t("providerRegister.faq5Answer") }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <div class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                                        aria-expanded="false" aria-controls="collapseOne" class="collapsed">
                                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                        <h3>{{ $t("providerRegister.faq6") }}</h3>
                                    </a>
                                    <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                                </div>
                            </div>
                            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                                aria-expanded="false">
                                <div class="card-block">
                                    <ul>
                                        <li>{{ $t("providerRegister.faq6Answer") }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <div class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseSix"
                                        aria-expanded="false" aria-controls="collapseOne" class="collapsed">
                                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                        <h3>{{ $t("providerRegister.faq7") }}</h3>
                                    </a>
                                    <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                                </div>
                            </div>
                            <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                                aria-expanded="false">
                                <div class="card-block">
                                    <ul>
                                        <li>{{ $t("providerRegister.faq7Answer") }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <div class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseFive"
                                        aria-expanded="false" aria-controls="collapseOne" class="collapsed">
                                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                        <h3>{{ $t("providerRegister.faq8") }}</h3>
                                    </a>
                                    <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                                </div>
                            </div>
                            <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                                aria-expanded="false">
                                <div class="card-block">
                                    <ul>
                                        <li>{{ $t("providerRegister.faq8Answer") }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- /FAQ Section -->
</template>
  
<script>
export default {
    methods: {
        moveUp () {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
};
</script>
<style scoped>
.btn-text {
    color: white;
}

.icon-text {
    font-size: 65px;
    margin-bottom: 20px;
}

.card-block {
    padding: 20px;
}
</style>
  