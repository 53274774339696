<template>
  <section class="section home-tile-section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 m-auto">
          <div class="section-header text-center">
            <h2>{{ $t('homePage.serviceSection.title') }}</h2>
            <p class="sub-title">{{ $t('homePage.serviceSection.subTitle') }}</p>

          </div>

          <!-- Category Section -->
          <section class="category-sec">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <router-link to="/login" class="book-now" @click="moveUp">
                    <div class="set-category">
                      <div class="d-flex align-items-center">
                        <div class="img-holder">
                          <a href="javascript:void(0);"><img src="../assets/img/icons/set-cat-02.png" alt="" /></a>
                        </div>

                        <div class="info-holder">
                          <h3>{{ $t('homePage.serviceSection.refillTitle') }}</h3>
                          <p>{{ $t('homePage.serviceSection.refillDescription') }}</p>
                          <router-link to="/login" class="book-now" @click="moveUp">{{
                            $t('homePage.serviceSection.refillLink') }}<i
                              class="fas fa-long-arrow-alt-right"></i></router-link>
                        </div>

                      </div>
                      <h1>01</h1>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-6">
                  <router-link to="/login" class="book-now" @click="moveUp">
                    <div class="set-category light">
                      <div class="d-flex align-items-center">
                        <div class="img-holder">
                          <a href="javascript:void(0);"><img src="../assets/img/icons/doct-01.png" alt="" /></a>
                        </div>
                        <div class="info-holder">
                          <h3>{{ $t('homePage.serviceSection.consultationTitle') }}</h3>
                          <p>{{ $t('homePage.serviceSection.consultationDescription') }}</p>
                          <router-link to="/login" class="book-now" @click="moveUp">{{
                            $t('homePage.serviceSection.consultationLink') }} <i
                              class="fas fa-long-arrow-alt-right"></i></router-link>
                        </div>
                      </div>
                      <h1>02</h1>
                    </div>
                  </router-link>
                </div>
                <!-- <div class="col-md-4">
                  <div class="set-category lighter">
                    <div class="d-flex align-items-center">
                      <div class="img-holder">
                        <a href="javascript:void(0);"><img
                            src="../assets/img/icons/clinic-hover-09.png"
                            alt=""
                          /></a>
                      </div>
                      <div class="info-holder">
                        <h3>Lab Tests</h3>
                        <p>Need medical tests to be done? Book your slot now</p>
                        <router-link
                          to="/login"
                          class="book-now"
                          @click="moveUp"
                        >Book Online <i class="fas fa-long-arrow-alt-right"></i></router-link>
                      </div>
                    </div>
                    <h1>03</h1>
                  </div>
                </div> -->
              </div>
            </div>
          </section>
          <!-- /Category Section -->

          <!-- <div class="row">
                        <div class="col-lg-4 mb-3">
                            <div class="card text-center doctor-book-card">
                                <img src="../assets/img/doctors/doctor-07.jpg" alt="" class="img-fluid">
                                <div class="doctor-book-card-content tile-card-content-1">
                                    <div>
                                        <h3 class="card-title mb-0">Visit a Doctor</h3>
                                        <router-link to="/provider/search" class="btn book-btn1 px-3 py-2 mt-3" tabindex="0">Book Now</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                            <div class="card text-center doctor-book-card">
                                <img src="../assets/img/img-pharmacy1.jpg" alt="" class="img-fluid">
                                <div class="doctor-book-card-content tile-card-content-1">
                                    <div>
                                        <h3 class="card-title mb-0">Find a Pharmacy</h3>
                                        <router-link to="/pharmacy/search" class="btn book-btn1 px-3 py-2 mt-3" tabindex="0">Find Now</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                            <div class="card text-center doctor-book-card">
                                <img src="../assets/img/lab-image.jpg" alt="" class="img-fluid">
                                <div class="doctor-book-card-content tile-card-content-1">
                                    <div>
                                    <h3 class="card-title mb-0">Find a Lab</h3>
                                        <a href="javascript:void(0);" class="btn book-btn1 px-3 py-2 mt-3" tabindex="0">Coming Soon</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    moveUp () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>