<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layout-header></layout-header>

      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
            </div>
          </div>
        </div>
      </div>

      <!-- /Page Content -->
      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data () {
    return {
      token: null,
      email: null
    };
  },
  async mounted () {
    try {
      this.token = this.$route.query.token
      this.email = this.$route.query.email

      if (!this.token || !this.email) {
        Swal.fire('Ooops! Broken Link', 'It seems the link you are visiting is broken or expired.', 'error')
          .then(() => {
            this.$router.push('/')
          })
      }

      this.showLoader(true);

      await this.verifyEmail({
        token: String(this.token).replace(/\*/g, "."),
        email: this.email
      });

      Swal.fire(
        "Account Verified!",
        "Please login to continue",
        "success"
      ).then(() => {
        this.$router.push("/login");
      });

      this.showLoader(false);
    } catch (e) {
      this.showLoader(false);
      Swal.fire("Verification Failed!", e.response.data.message, "error");
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions(["verifyEmail", "showLoader"]),
  },
};
</script>
