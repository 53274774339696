<template>
  <div class="main-wrapper" v-if="prescriptionDetails">
    <layout-header></layout-header>
    <div class="breadcrumb-bar">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-12 col-12">
            <h2 class="breadcrumb-title">
              {{ $t("provider.prescriptionDetails.pageTitle") }}:
              {{ prescriptionDetails.id }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="card mb-2" style="font-size: 12px">
                  <div class="card-header">
                    <h4>
                      {{ $t("provider.prescriptionDetails.reqDate") }}:
                      {{
                        new Date(
                          prescriptionDetails.created_at
                        ).toLocaleDateString()
                      }}
                    </h4>
                  </div>
                </div>

                <div
                  class="accordion"
                  id="prescription-accordion"
                  style="font-size: 12px"
                >
                  <!-- PATIENT INFORMATION -->
                  <div class="card mb-0" v-if="prescriptionDetails.patient">
                    <div class="card-header" id="patient-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#patient-info-collapse"
                          aria-expanded="true"
                          aria-controls="patient-info-collapse"
                        >
                          <i class="fas fa-user mr-1"></i>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.title"
                            )
                          }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="patient-info-collapse"
                      class="collapse show"
                      aria-labelledby="patient-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.name"
                            )
                          }}
                          : {{ prescriptionDetails.patient.first_name }}
                          {{ prescriptionDetails.patient.last_name }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.dateOfBirth"
                            )
                          }}:
                          {{ prescriptionDetails.patient.dob }}
                          <!-- ({{
                          getAge(prescriptionDetails.patient.dob)
                          }}
                          Years) -->
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.gender"
                            )
                          }}:
                          {{ prescriptionDetails.patient.gender }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.height"
                            )
                          }}:
                          {{ String(prescriptionDetails.patient.height) }} IN
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.weight"
                            )
                          }}:
                          {{ String(prescriptionDetails.patient.weight) }} LB
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.mobile"
                            )
                          }}
                          :
                          {{ prescriptionDetails.patient.primary_phone }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.address"
                            )
                          }}
                          : {{ prescriptionDetails.patient.address1 }},
                          {{ prescriptionDetails.patient.address2 }},
                          {{ prescriptionDetails.patient.city }},
                          {{ prescriptionDetails.patient.state }} -
                          {{ prescriptionDetails.patient.zip_code }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- PHARMACY INFORMATION -->
                  <div class="card mb-0" v-if="prescriptionDetails.pharmacy">
                    <div class="card-header" id="pharmacy-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#pharmacy-info-collapse"
                          aria-expanded="false"
                          aria-controls="pharmacy-info-collapse"
                        >
                          <i class="fas fa-hospital mr-1"></i>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.title"
                            )
                          }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="pharmacy-info-collapse"
                      class="collapse"
                      aria-labelledby="pharmacy-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.storeId"
                            )
                          }}:
                          {{ prescriptionDetails.pharmacy.pharmacy_id }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.storeName"
                            )
                          }}:
                          {{ prescriptionDetails.pharmacy.pharmacy_name }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.phone"
                            )
                          }}: {{ prescriptionDetails.pharmacy.phone }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.address"
                            )
                          }}: {{ prescriptionDetails.pharmacy.address1 }},
                          {{ prescriptionDetails.pharmacy.address2 }},
                          {{ prescriptionDetails.pharmacy.city }},
                          {{ prescriptionDetails.pharmacy.state }} -
                          {{ prescriptionDetails.pharmacy.zip_code }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- HEALTH & SYMPTOMS INFORMATION -->
                  <div
                    class="card mb-0"
                    v-if="prescriptionDetails.observations"
                  >
                    <div class="card-header" id="symptoms-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#symptoms-info-collapse"
                          aria-expanded="false"
                          aria-controls="symptoms-info-collapse"
                        >
                          <i class="fas fa-notes-medical mr-1"></i>
                          {{ $t("provider.prescriptionDetails.description") }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="symptoms-info-collapse"
                      class="collapse"
                      aria-labelledby="symptoms-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        {{ prescriptionDetails.observations.symptoms }}
                      </div>
                    </div>
                  </div>

                  <!-- ALLERGIES INFORMATION -->
                  <div
                    class="card mb-0"
                    v-if="prescriptionDetails.observations.allergies"
                  >
                    <div class="card-header" id="allergies-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#allergies-info-collapse"
                          aria-expanded="false"
                          aria-controls="allergies-info-collapse"
                        >
                          <i class="fas fa-allergies mr-1"></i>
                          {{ $t("provider.prescriptionDetails.allergies") }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="allergies-info-collapse"
                      class="collapse"
                      aria-labelledby="allergies-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        {{ prescriptionDetails.observations.allergies }}
                      </div>
                    </div>
                  </div>

                  <!-- HISTORY INFORMATION -->
                  <div
                    class="card mb-0"
                    v-if="prescriptionDetails.observations.history"
                  >
                    <div class="card-header" id="history-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#history-info-collapse"
                          aria-expanded="false"
                          aria-controls="history-info-collapse"
                        >
                          <i class="fas fa-history mr-1"></i>
                          {{
                            $t("provider.prescriptionDetails.medicalHistory")
                          }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="history-info-collapse"
                      class="collapse"
                      aria-labelledby="history-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        {{ prescriptionDetails.observations.history }}
                      </div>
                    </div>
                  </div>

                  <!-- HEALTH INFORMATION -->
                  <div class="card" v-if="prescriptionDetails.dosageInfo">
                    <div class="card-header" id="health-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#health-info-collapse"
                          aria-expanded="false"
                          aria-controls="health-info-collapse"
                        >
                          <i class="fas fa-heart mr-1"></i>
                          {{
                            $t(
                              "provider.prescriptionDetails.healthInformation.title"
                            )
                          }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="health-info-collapse"
                      class="collapse"
                      aria-labelledby="health-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div
                        class="card-body"
                        v-if="prescriptionDetails.dosageInfo"
                      >
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.healthInformation.currentMedications"
                            )
                          }}:
                          {{
                            prescriptionDetails.dosageInfo.currentMedications
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.healthInformation.knownAllergies"
                            )
                          }}:
                          {{ prescriptionDetails.dosageInfo.knownAllergies }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.healthInformation.reasonForMedication"
                            )
                          }}:
                          {{
                            prescriptionDetails.dosageInfo.reasonForMedication
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- MANAGE MEDICINES & RX CHANGES -->
              <div
                class="col-md-9"
                v-if="
                  medicineDetails.length > 0 &&
                  prescriptionDetails.pharmacy &&
                  prescriptionDetails.status !== 'Cancelled'
                "
              >
                <!-- Title and Header -->
                <div class="card m-0" style="font-size: 12px">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <h4>
                          {{
                            $t("provider.prescriptionDetails.medications.title")
                          }}
                        </h4>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="text-right">
                          <button
                            class="btn btn-sm btn-outline-info mr-2"
                            @click="markChatRead"
                          >
                            <i class="fa fa-comment mr-1"></i>
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.chat"
                              )
                            }}
                            <span
                              v-if="unreadMessages > 0"
                              class="badge badge-pill bg-danger-light"
                            >
                              {{ unreadMessages }} New</span
                            >
                          </button>
                          <button
                            class="btn btn-sm btn-outline-info mr-2"
                            @click="showModal('select-medicine-modal')"
                            v-show="
                              prescriptionDetails.status === 'In Progress'
                            "
                          >
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.changeMedicine"
                              )
                            }}
                          </button>
                          <button
                            class="btn btn-sm btn-outline-info mr-2"
                            @click="showModal('select-pharmacy-modal')"
                            v-show="
                              prescriptionDetails.status === 'In Progress'
                            "
                          >
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.changePharmacy"
                              )
                            }}
                          </button>
                          <button
                            @click="showRejectPrescriptionModal"
                            v-if="
                              !isEditing &&
                              prescriptionDetails.status !== 'Cancelled'
                            "
                            class="btn btn-sm bg-danger-light mr-2"
                          >
                            <i class="fas fa-times"></i>
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.cancelRequest"
                              )
                            }}
                          </button>
                          <button
                            v-if="
                              !isEditing &&
                              !isError &&
                              prescriptionDetails.status === 'In Progress'
                            "
                            @click="showAcceptPrescriptionModal"
                            class="btn btn-sm bg-success-light mr-2"
                          >
                            <i class="fas fa-check"></i>
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.approveRequest"
                              )
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Medicines -->
                <div class="mt-2" style="font-size: 12px">
                  <div
                    class="accordion"
                    style="height: 600px; overflow: auto"
                    id="medicine-accordion"
                  >
                    <div
                      class="card mb-0"
                      v-for="(medicine, i) in medicineDetails"
                      :key="i"
                    >
                      <div
                        class="card-header"
                        :id="`medicine-accordion-${medicine.id}`"
                      >
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-sm btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            :data-target="`#medicine-collapse-${medicine.id}`"
                            aria-expanded="true"
                            :aria-controls="`medicine-collapse-${medicine.id}`"
                            @click="isEditingRequired(medicine)"
                          >
                            {{ i + 1 }}.
                            {{
                              !medicine.selected_refill
                                ? medicine.medicine_name
                                : medicine.selected_refill.medicine_name
                            }}
                            <div
                              class="float-right"
                              v-if="medicine.dsp_cancel_status == 'Completed'"
                            >
                              <span
                                class="badge bg-danger-light"
                                title="Prescription Cancelled"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.cancelled"
                                  )
                                }}</span
                              >
                            </div>
                            <div v-else class="float-right">
                              <span
                                v-if="
                                  !medicine.directions ||
                                  medicine.directions === ''
                                "
                                :id="`status-fail-${medicine.ndc}`"
                                class="badge bg-warning-light ml-1"
                                title="Incomplete details"
                              >
                                {{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.detailsIncomplete"
                                  )
                                }}
                              </span>
                              <span
                                v-if="
                                  medicine.is_refill_initiated &&
                                  medicine.dsp_status_id === 13
                                "
                                :id="`status-fail-${medicine.ndc}`"
                                class="badge bg-success-light ml-1"
                                title="Pharmacy verified details"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyVerified"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  !medicine.is_refill_initiated &&
                                  !medicine.is_rxchange_requested &&
                                  medicine.dsp_status_id === 13
                                "
                                :id="`status-fail-${medicine.ndc}`"
                                class="badge bg-warning-light ml-1"
                                title="WAITING FOR PHARMACY RESPONSE"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.waitingForPharmacyRes"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  medicine.is_refill_initiated &&
                                  medicine.selected_refill &&
                                  medicine.selected_refill.status ===
                                    'Completed'
                                "
                                :id="`status-refill-approved-${medicine.ndc}`"
                                class="badge bg-success-light ml-1"
                                title="Refill Completed"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRefillCompleted"
                                  )
                                }}</span
                              >
                              <span
                                v-if="medicine.rxchange_status === 'Completed'"
                                :id="`status-rxchange-${medicine.ndc}`"
                                class="badge bg-success-light ml-1"
                                title="Pharmacy RxChange Completed"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRxChangeCompleted"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  medicine.is_refill_initiated &&
                                  medicine.selected_refill &&
                                  medicine.refill_status === 'Ready'
                                "
                                :id="`status-refill-ready-${medicine.ndc}`"
                                class="badge bg-success-light ml-1"
                                title="Pharmacy Refill Ready"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRefillReady"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  medicine.is_refill_initiated &&
                                  medicine.selected_refill &&
                                  medicine.refill_status === 'Denied'
                                "
                                :id="`status-refill-ready-${medicine.ndc}`"
                                class="badge bg-danger-light ml-1"
                                title="Pharmacy Refill Denied"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRefillDenied"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  medicine.is_rxchange_requested &&
                                  medicine.selected_rxchange &&
                                  medicine.selected_rxchange.status ===
                                    'Pending'
                                "
                                :id="`status-fail-${medicine.ndc}`"
                                class="badge bg-danger-light ml-1"
                                title="Rx Change Requested"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRxChangeReq"
                                  )
                                }}</span
                              >
                            </div>
                            <br />
                            <span
                              v-if="medicine.pin"
                              class="m-2"
                              style="font-size: 11px"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.pin"
                                )
                              }}:
                              {{
                                medicine.pin ? medicine.pin : "Not Generated"
                              }}</span
                            >
                            <span
                              v-if="
                                medicine.selected_refill &&
                                prescriptionDetails.status == 'Completed'
                              "
                              class="m-2"
                              style="font-size: 11px"
                              >Approved Refill ID:
                              {{ medicine.selected_refill.refill_id }}</span
                            >
                            <span
                              v-if="
                                medicine.selected_refill &&
                                prescriptionDetails.status == 'Completed'
                              "
                              class="m-2"
                              style="font-size: 11px"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.writtenDate"
                                )
                              }}:
                              {{
                                new Date(
                                  medicine.selected_refill.requested_date
                                ).toLocaleString()
                              }}</span
                            >
                            <span
                              v-if="
                                (prescriptionDetails.status == 'Cancelled' ||
                                  prescriptionDetails.status == 'Accepted') &&
                                medicine.dsp_cancel_message
                              "
                              class="m-2"
                              style="font-size: 11px"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.pharmacyMessage"
                                )
                              }}:
                              <strong>{{
                                medicine.dsp_cancel_message
                                  ? medicine.dsp_cancel_message
                                  : "Unknown"
                              }}</strong></span
                            >
                            <span
                              v-if="
                                medicine.rxChange &&
                                medicine.is_rxchange_requested
                              "
                              class="m-2"
                              style="font-size: 11px"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.pharmacyMessage"
                                )
                              }}:
                              <span class="text-danger">
                                {{
                                  getRxChangeMessage(
                                    medicine.rxChange.rxChangeType
                                  )
                                }}</span
                              >
                            </span>
                          </button>
                        </h2>
                      </div>

                      <div
                        :id="`medicine-collapse-${medicine.id}`"
                        class="collapse"
                        :aria-labelledby="`medicine-accordion-${medicine.id}`"
                        data-parent="#medicine-accordion"
                      >
                        <div
                          class="row card-body"
                          style="height: 525px; overflow: auto"
                        >
                          <div class="col-3">
                            <nav class="user-tabs mb-4">
                              <ul
                                class="nav flex-column nav-pills"
                                aria-orientation="vertical"
                              >
                                <li class="nav-item">
                                  <a
                                    class="nav-link active"
                                    href="#original-prescriptions-tab"
                                    data-toggle="tab"
                                    >Active Prescription</a
                                  >
                                </li>
                                <li class="nav-item">
                                  <a
                                    class="nav-link"
                                    href="#pharmacy-refills-tab"
                                    data-toggle="tab"
                                    >Pharmacy Refills -
                                    <span
                                      v-if="
                                        medicine.pharmacy_refills &&
                                        medicine.pharmacy_refills.length > 0
                                      "
                                    >
                                      {{
                                        medicine.pharmacy_refills.length
                                      }}</span
                                    ><span v-else>0</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a
                                    class="nav-link"
                                    href="#rxchange-requests-tab"
                                    data-toggle="tab"
                                    >RxChange Requests -
                                    <span
                                      v-if="
                                        medicine.pharmacy_rxchanges &&
                                        medicine.pharmacy_rxchanges.length > 0
                                      "
                                    >
                                      {{
                                        medicine.pharmacy_rxchanges.length
                                      }}</span
                                    ><span v-else>0</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a
                                    class="nav-link"
                                    href="#old-prescriptions-tab"
                                    data-toggle="tab"
                                    >Prescription History -
                                    <span
                                      v-if="
                                        medicine.old_prescriptions &&
                                        medicine.old_prescriptions.length > 0
                                      "
                                    >
                                      {{
                                        medicine.old_prescriptions.length
                                      }}</span
                                    ><span v-else>0</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          <div class="col-9">
                            <div class="tab-content pt-0">
                              <div
                                class="tab-pane fade show active"
                                id="original-prescriptions-tab"
                              >
                                <OriginalPrescription
                                  :units="units"
                                  :medicine="medicine"
                                  :prescriptionDetails="prescriptionDetails"
                                ></OriginalPrescription>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="pharmacy-refills-tab"
                              >
                                <PharmacyRefills
                                  :units="units"
                                  :medicine="medicine"
                                  :prescriptionDetails="prescriptionDetails"
                                  @savePrescriptionDetails="
                                    savePrescriptionDetails
                                  "
                                  @showQuickDenyModal="showQuickDenyModal"
                                  @showQuickApproveModal="showQuickApproveModal"
                                ></PharmacyRefills>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="rxchange-requests-tab"
                              >
                                <RxChangeRequests
                                  :medicine="medicine"
                                  :units="units"
                                  :prescriptionDetails="prescriptionDetails"
                                  @showRejectRxChangeModal="
                                    showRejectRxChangeModal
                                  "
                                  @approveRxChangeRequest="
                                    approveRxChangeRequest
                                  "
                                  @reconcileRxChangeRequest="
                                    reconcilePrescription
                                  "
                                  @discardRxChangeRequest="
                                    discardRxChangeRequest
                                  "
                                ></RxChangeRequests>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="old-prescriptions-tab"
                              >
                                <OldPrescriptions
                                  :medicine="medicine"
                                  :units="units"
                                  :prescriptionDetails="prescriptionDetails"
                                ></OldPrescriptions>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Footer buttons -->
                  <div>
                    <div
                      id="alert-msg-box"
                      class="alert alert-warning alert-dismissible fade"
                      role="alert"
                    >
                      <span id="alert-msg"></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- PRESCRIBE NEW MEDICINES -->
              <div
                class="col-md-9"
                v-else-if="prescriptionDetails.status !== 'Cancelled'"
              >
                <div class="card m-0">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-8">
                        <h4>
                          {{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.prescribeMedicines"
                            )
                          }}
                        </h4>
                      </div>
                      <div class="col-4 text-right">
                        <button
                          class="btn btn-sm btn-outline-info mr-2"
                          @click="markChatRead"
                        >
                          <i class="fa fa-comment mr-1"></i>
                          {{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.chat"
                            )
                          }}
                          <span
                            v-if="unreadMessages > 0"
                            class="badge badge-pill bg-danger-light"
                          >
                            {{ unreadMessages }}
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.medicines.new"
                              )
                            }}</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div
                      class="accordion"
                      style="height: 300px; overflow: auto"
                      id="prescribing-accordion"
                    >
                      <!-- CHECK SYMPTOMS -->
                      <div class="card-header" id="symptoms-details-accordion">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-sm btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#symptoms-details-collapse"
                            aria-expanded="true"
                            aria-controls="symptoms-details-collapse"
                            @click="isSymptomsRead = true"
                          >
                            <span :class="isSymptomsRead ? 'text-success' : ''">
                              {{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.step1"
                                )
                              }}
                              :
                            </span>
                            <span
                              v-if="isSymptomsRead"
                              class="badge badge-pill bg-success inv-badge"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.completed"
                                )
                              }}</span
                            >
                          </button>
                        </h2>
                      </div>
                      <div
                        id="symptoms-details-collapse"
                        class="collapse"
                        aria-labelledby="symptoms-details-accordion"
                        data-parent="#prescribing-accordion"
                      >
                        <div class="card-body">
                          {{ prescriptionDetails.observations.symptoms }}
                        </div>
                      </div>

                      <!-- SELECT MEDICINES -->
                      <div class="card-header" id="medicine-details-accordion">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-sm btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#medicine-details-collapse"
                            aria-expanded="false"
                            aria-controls="medicine-details-collapse"
                            :disabled="
                              !isSymptomsRead && !prescriptionDetails.medicines
                            "
                            @click="showModal('select-medicine-modal')"
                          >
                            <span
                              :class="
                                prescriptionDetails.medicines
                                  ? 'text-success'
                                  : ''
                              "
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.step2"
                                )
                              }}
                              : </span
                            ><span
                              v-if="prescriptionDetails.medicines"
                              class="badge badge-pill bg-success inv-badge"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.completed"
                                )
                              }}</span
                            >
                          </button>
                        </h2>
                      </div>

                      <!-- SELECT PHARMACY -->
                      <div class="card-header" id="pharmacy-details-accordion">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-sm btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#pharmacy-details-collapse"
                            aria-expanded="false"
                            aria-controls="pharmacy-details-collapse"
                            @click="showModal('select-pharmacy-modal')"
                          >
                            <span
                              :class="
                                prescriptionDetails.pharmacy
                                  ? 'text-success'
                                  : ''
                              "
                            >
                              {{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.step3"
                                )
                              }}
                            </span>
                            <span
                              v-if="prescriptionDetails.pharmacy"
                              class="badge badge-pill bg-success inv-badge"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.completed"
                                )
                              }}</span
                            >
                          </button>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- REJECTED STATUS -->
              <div class="col-md-9" v-else>
                <div class="text-center p-5">
                  <img
                    class="mb-4"
                    src="../../assets/img/cancel.png"
                    alt="cancel"
                    width="300"
                  />
                  <h3 class="text-muted">
                    {{
                      $t(
                        "provider.prescriptionDetails.medications.medicines.prescriptionRequestCancelled"
                      )
                    }}
                  </h3>
                  <router-link to="/provider/index">
                    <button class="btn btn-sm bg-primary-light mt-4">
                      {{
                        $t(
                          "provider.prescriptionDetails.medications.medicines.backToDashboard"
                        )
                      }}
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Accept Modal -->
    <div
      class="modal fade"
      id="prescription-accept-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <AcceptModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="acceptPrescription"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Approve Modal -->
    <div
      class="modal fade"
      id="prescription-approve-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <ApproveModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="approveAllRefills"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Cancel Modal -->
    <div
      class="modal fade"
      id="prescription-cancel-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <RejectModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @cancel="rejectPrescription"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Cancel RX Change Modal -->
    <div
      class="modal fade"
      id="prescription-rxcancel-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <DenyRxChangeModal
              v-if="selectedDspPrescriptionId"
              :dspPrescriptionId="selectedDspPrescriptionId"
              :selectedRxChangeId="selectedRxChangeId"
              @submit="denyRxChangeRequest"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Refill Deny Modal -->
    <div class="modal fade" id="prescription-deny-modal" style="display: none">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <DenyModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="denyAllRefills"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Select Medicines Modal -->
    <div class="modal fade" id="select-medicine-modal" style="display: none">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <SelectMedicineModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="saveMedicines"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Chat Box Modal -->
    <div data-backdrop="static" id="chat-modal" class="slideout">
      <Chat
        v-if="prescriptionDetails"
        isCallingEnabled="true"
        :patientName="`${prescriptionDetails.patient.first_name} ${prescriptionDetails.patient.last_name}`"
        :patientImage="prescriptionDetails.patient.profile_image_url"
        :prescriptionId="prescriptionId"
        :status="prescriptionDetails.status"
        @closechat="closeChat"
      />
    </div>

    <!-- Select Pharmacy Modal -->
    <div class="modal fade" id="select-pharmacy-modal" style="display: none">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <SelectPharmacyModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="savePharmacy"
            />
          </div>
        </div>
      </div>
    </div>

    <layout-footer></layout-footer>
  </div>
</template>
<script>
import axios from "axios";
import config from "../../constants/config";
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
import AcceptModal from "./AcceptModal.vue";
import ApproveModal from "./ApproveModal.vue";
import RejectModal from "./RejectModal.vue";
import DenyRxChangeModal from "./DenyRxChangeModal.vue";
import DenyModal from "./DenyModal.vue";
import Chat from "./Chat.vue";
import SelectMedicineModal from "./SelectMedicineModal.vue";
import SelectPharmacyModal from "./SelectPharmacyModal.vue";
import OriginalPrescription from "./view-prescriptions/OriginalPrescription.vue";
import PharmacyRefills from "./view-prescriptions/PharmacyRefills.vue";
import RxChangeRequests from "./view-prescriptions/RxChangeRequests.vue";
import OldPrescriptions from "./view-prescriptions/OldPrescriptions.vue";
import Swal from "sweetalert2";

export default {
  props: {
    prescriptionId: String,
  },
  components: {
    AcceptModal: AcceptModal,
    RejectModal: RejectModal,
    ApproveModal: ApproveModal,
    DenyRxChangeModal: DenyRxChangeModal,
    DenyModal: DenyModal,
    SelectMedicineModal: SelectMedicineModal,
    SelectPharmacyModal: SelectPharmacyModal,
    Chat: Chat,
    OriginalPrescription,
    PharmacyRefills,
    RxChangeRequests,
    OldPrescriptions,
  },
  mixins: [commonMixin],
  computed: {
    chatMessages() {
      return this.$store.getters.chatMessages;
    },
    unreadMessages() {
      return this.$store.getters.chatMessages
        ? this.$store.getters.chatMessages
            .filter((d) => d.user_id !== this.$store.getters.user.id)
            .filter((c) => c.is_read === false).length
        : 0;
    },
    chatEnabled() {
      return this.prescriptionDetails.status === "Completed" ||
        this.prescriptionDetails.status === "Cancelled"
        ? false
        : true;
    },
    route() {
      return this.$route.name;
    },
  },
  data() {
    return {
      prescriptionDetails: null,
      medicineDetails: null,
      isError: true,
      isEditing: false,
      isPharmacyVerified: false,
      newPrescribedMedicine: null,
      rxChangeRequestObj: null,
      units: [],
      isEditingMode: false,
      is_rxchange_requested: false,
      selectedRxChangeId: null,
      selectedDspPrescriptionId: null,
      showFooterControls: false,
      isSymptomsRead: false,
      priorAuthNumber: "",
      interval: null,
      isChatOpen: false,
    };
  },
  async created() {
    this.showLoader(true);
    const r = await this.getDispenseUnits();
    this.units = r
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((e) => e.IsActive === true);

    const res = await axios.get(
      `${config.BASE_URL}${config.HTTP_API.providerGetPrescriptionById}/${this.prescriptionId}`
    );
    this.prescriptionDetails = res.data;

    this.medicineDetails = this.prescriptionDetails.medicines
      ? this.prescriptionDetails.medicines
      : [];
    const obj = this.medicineDetails.filter((m) => m.is_rxchange_requested);
    if (obj.length > 0) this.is_rxchange_requested = true;

    this.setEditModeState();

    // CHAT MESSAGE REQUESTS
    this.$socket.emit("REQUEST", {
      command: "GET:CHAT_MESSAGES",
      userId: this.$store.getters.user.id,
      prescriptionId: this.prescriptionId,
    });

    if (this.chatEnabled) {
      this.interval = setInterval(() => {
        if (!this.chatEnabled) {
          if (this.interval) clearInterval(this.interval);
          this.interval = null;
          return;
        }

        this.$socket.emit("REQUEST", {
          command: "GET:CHAT_MESSAGES",
          userId: this.$store.getters.user.id,
          prescriptionId: this.prescriptionId,
        });
      }, 2000);
    } else {
      if (this.interval) clearInterval(this.interval);
      this.interval = null;
    }
    this.showLoader(false);
  },
  watch: {
    medicineDetails: function () {
      this.updatePrescriptionRx();
    },
    route: function () {
      if (this.route !== "view-prescription") {
        if (this.interval) clearInterval(this.interval);
        this.interval = null;
        this.$socket.emit("REQUEST", {
          command: "GET:CLEAR_CHAT",
          userId: this.$store.getters.user.id,
        });
      }
    },
    chatMessages: function (newVal, oldVal) {
      if (newVal.length != oldVal.length && this.isChatOpen) {
        this.$socket.emit("REQUEST", {
          command: "POST:CHAT_MESSAGES_MARK_READ",
          userId: this.$store.getters.user.id,
          prescriptionId: this.prescriptionId,
        });
      }
    },
  },
  methods: {
    ...mapActions(["showLoader"]),

    closeChat() {
      this.isChatOpen = false;
      // this.hideModal("chat-modal");
      $(".slideout").removeClass("on");
    },

    markChatRead() {
      this.$socket.emit("REQUEST", {
        command: "POST:CHAT_MESSAGES_MARK_READ",
        userId: this.$store.getters.user.id,
        prescriptionId: this.prescriptionId,
      });

      this.isChatOpen = true;
      // this.showModal("chat-modal");
      $(".slideout").addClass("on");
    },

    isEditingAllowed(medicine) {
      if (medicine.pharmacy_refills && medicine.pharmacy_refills.length > 0) {
        if (
          medicine.selected_refill.status == "Completed" ||
          medicine.selected_refill.status == "Denied"
        )
          return true;
        else return false;
      }

      if (
        medicine.is_rxchange_requested &&
        medicine.selected_rxchange.rxchange_type === "Out Of Stock"
      )
        return true;

      return !this.isEditingMode;
    },

    isOutOfStock(selectedRxChange) {
      if (!selectedRxChange) return false;
      let type = String(selectedRxChange.rxchange_type).toLowerCase();
      if (type === "out of stock") return true;
      return false;
    },

    isSpecialRxChange(selectedRxChange) {
      if (!selectedRxChange) return false;
      let type = String(selectedRxChange.rxchange_type).toLowerCase();
      if (type === "out of stock") return true;
      return false;
    },

    showModal(modalName) {
      $("#" + modalName).modal("show");
    },

    hideModal(modalName) {
      $("#" + modalName).modal("hide");
    },

    setFooterControlDisplay(type) {
      if (type === "Script Clarification") this.showFooterControls = true;
      else this.showFooterControls = false;
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    async reconcilePrescription(data) {
      try {
        Swal.fire({
          icon: "info",
          titleText: "Reconcile Confirmation",
          text: `Are you sure you want to reconcile this prescription?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          customClass: {
            confirmButton: "bg-success-light",
            denyButton: "bg-danger-light",
          },
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.showLoader(true);
              await axios.post(
                `${config.BASE_URL}${config.HTTP_API.providerReconcilePrescription}`,
                {
                  rxChangeRequestId: data.rxChangeRequestId,
                  prescriptionId: data.prescriptionId,
                }
              );

              Swal.fire({
                icon: "success",
                title: "Prescription Reconciled!",
                text: "Prescription has been reconciled successfully",
                timer: 3000,
              }).then(() => {
                this.$router.go();
              });

              this.showLoader(false);
            }
          })
          .catch((e) => {
            console.error(e);
            Swal.fire("Reconcile Failed!", e.response.data.message, "error");
            this.showLoader(false);
          });
      } catch (e) {
        console.error(e);
        Swal.fire("Reconcile Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async discardRxChangeRequest(data) {
      try {
        Swal.fire({
          icon: "info",
          titleText: "Discarding Rx Change Request",
          text: `Are you sure you want to discard this change request?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          customClass: {
            confirmButton: "bg-success-light",
            denyButton: "bg-danger-light",
          },
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.showLoader(true);
              await axios.post(
                `${config.BASE_URL}${config.HTTP_API.providerDiscardRxChangeRequest}`,
                {
                  rxChangeRequestId: data.rxChangeRequestId,
                }
              );

              Swal.fire({
                icon: "success",
                title: "Request Discarded!",
                text: "RxChange request has been discarded successfully",
                timer: 3000,
              }).then(() => {
                this.$router.go();
              });
              this.showLoader(false);
            }
          })
          .catch((e) => {
            console.error(e);
            Swal.fire("Reconcile Failed!", e.response.data.message, "error");
            this.showLoader(false);
          });
      } catch (e) {
        console.error(e);
        Swal.fire("Reconcile Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async setPrintStatus(dspPatientId, prescriptionId) {
      Swal.fire({
        icon: "info",
        titleText: "Print Confirmation",
        text: `Do you want to set the prescription to printed?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success-light",
          denyButton: "bg-danger-light",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // SEND REFILL DENIAL
          try {
            this.showLoader(true);
            await axios.post(
              `${config.BASE_URL}${config.HTTP_API.providerPrintPrescription}`,
              {
                prescriptionId: prescriptionId,
                dspPatientId: dspPatientId,
              }
            );

            Swal.fire({
              icon: "success",
              title: "Status Updated",
              text: "Prescription status has been set to printed",
              timer: 3000,
            }).then(() => {
              this.$router.go();
            });
            this.showLoader(false);
          } catch (e) {
            console.error(e);
            Swal.fire("Print Failed!", e.response.data.message, "error");
            this.showLoader(false);
          }
        }
      });
    },

    selectNewMedicine(allMedicines, selectedKey) {
      // Change btn color
      for (let i = 0; i < allMedicines.length; i++) {
        if (i === selectedKey) {
          const btn = document.getElementById(`select-btn-${i}`);
          if (btn) btn.classList = "btn btn-sm btn-info btn-block";
        } else {
          const btn = document.getElementById(`select-btn-${i}`);
          if (btn) btn.classList = "btn btn-sm bg-info-light btn-block";
        }
      }

      this.newSelectedMedicine = allMedicines[selectedKey];
      this.showFooterControls = true;
    },

    isEditingRequired(medicine) {
      if (medicine.rxChange)
        this.setFooterControlDisplay(medicine.rxChange.rxChangeType);

      if (!medicine.is_rxchange_requested && medicine.dsp_status_id == 13) {
        this.isEditingMode = false;
      }

      if (
        medicine.is_rxchange_requested ||
        (medicine.is_refill_initiated &&
          this.prescriptionDetails.status !== "Completed")
      ) {
        this.isEditingMode = true;
      }
    },

    setEditModeState() {
      if (
        this.prescriptionDetails.status === "Cancelled" ||
        this.prescriptionDetails.status === "Accepted"
      ) {
        this.isEditingMode = false;
      } else {
        this.isEditingMode = true;
      }
    },

    async approveRxChangeRequest(data) {
      try {
        Swal.fire({
          icon: "question",
          text: "Are you sure you want to approve this change request?",
          title: "Are you sure?",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Yes",
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.showLoader(true);

              const {
                rxChangeRequestId,
                requestedPrescriptionId,
                originalPrescriptionId,
                priorAuthNumber,
                type,
              } = JSON.parse(data);

              // let type = "";
              // if (this.priorAuthNumber) type = "Prior";
              // else if (this.npiVerification) type = "Prescriber";
              // else type = "General";

              await axios.post(
                `${config.BASE_URL}${config.HTTP_API.providerApproveRxChangeRequest}${rxChangeRequestId}`,
                {
                  newPrescriptionId: requestedPrescriptionId,
                  originalPrescriptionId: originalPrescriptionId,
                  npiVerification: this.npiVerification,
                  priorAuthNumber: priorAuthNumber,
                  type: type,
                }
              );

              this.npiVerification = "";
              this.newSelectedMedicine = null;

              Swal.fire({
                icon: "success",
                title: "RxChange Complete!",
                text: "RxChange request has been approved",
                timer: 3000,
              }).then(() => {
                this.$router.go();
              });

              this.showLoader(false);
            }
          })
          .catch((e) => {
            console.error(e);
            this.showLoader(false);
            Swal.fire("Approval Failed!", e.response.data.message, "error");
          });
      } catch (e) {
        console.error(e);
        this.showLoader(false);
        Swal.fire("Approval Failed!", e.response.data.message, "error");
      }
    },

    async savePharmacy(pharmacyDetails) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            pharmacy: JSON.parse(pharmacyDetails),
          }
        );

        this.showLoader(false);
        this.isEditing = false;
        this.hideModal("select-pharmacy-modal");

        Swal.fire({
          icon: "success",
          title: "Saved!",
          text: "Pharmacy assigned to the prescription",
          timer: 3000,
        }).then(() => {
          this.$router.go();
        });
      } catch (e) {
        console.error(e.response.message);
        this.showLoader(false);
        Swal.fire("Failed!", e.response.data.message, "error");
      }
    },

    async saveMedicines(medicines) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            medicines: JSON.parse(medicines),
          }
        );

        this.showLoader(false);
        this.isEditing = false;
        this.hideModal("select-medicine-modal");

        Swal.fire({
          icon: "success",
          title: "Saved!",
          text: "Prescription has been saved",
          timer: 3000,
        }).then(() => {
          this.$router.go();
        });
      } catch (e) {
        console.error(e.response.message);
        this.showLoader(false);
        Swal.fire("Failed!", e.response.data.message, "error");
      }
    },

    async savePrescriptionDetails(medicineDetails) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            medicines: medicineDetails,
          }
        );

        this.medicineDetails = medicineDetails;
        this.showLoader(false);
        this.isEditing = false;
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Failed!", e.response.data.message, "error").then(() => {
          this.$router.go();
        });
      }
    },

    showRejectPrescriptionModal() {
      $("#prescription-cancel-modal").modal("toggle");
    },

    showDenyRefillRequestModal() {
      $("#prescription-deny-modal").modal("toggle");
    },

    showAcceptPrescriptionModal() {
      $("#prescription-accept-modal").modal("show");
    },

    showApprovePrescriptionModal() {
      $("#prescription-approve-modal").modal("toggle");
    },

    showRejectRxChangeModal(data) {
      const { dspPrescriptionId, selectedRxChangeId } = JSON.parse(data);
      this.selectedDspPrescriptionId = dspPrescriptionId;
      this.selectedRxChangeId = selectedRxChangeId;
      $("#prescription-rxcancel-modal").modal("toggle");
    },

    showQuickApproveModal(data) {
      const { prescriptionId, refillId } = data;
      Swal.fire({
        icon: "info",
        titleText: "Need Approval Confirmation",
        text: `Do you want to approve the refill request ID: ${refillId} ?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success",
          denyButton: "bg-danger-light",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // SEND REFILL APPROVAL
          this.approveSingleRefill(prescriptionId, refillId);
        }
      });
    },

    showQuickDenyModal(data) {
      const { prescriptionId, refillId } = data;
      Swal.fire({
        icon: "info",
        titleText: "Need Denial Confirmation",
        text: `Do you want to deny the refill request ID: ${refillId}?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success",
          denyButton: "bg-danger-light",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // SEND REFILL DENIAL
          this.denySingleRefill(prescriptionId, refillId);
        }
      });
    },

    updatePrescriptionRx() {
      const alertBox = document.getElementById("alert-msg-box");
      const alertMsg = document.getElementById("alert-msg");
      const directionsArray = this.medicineDetails.filter((m) => !m.directions);
      if (directionsArray.length > 0) {
        if (alertBox) {
          alertBox.classList =
            "alert alert-warning alert-dismissible fade show";
        }
        if (alertMsg)
          alertMsg.innerText =
            "Please enter Directions of use and pharmacy notes";
        this.isError = true;
        return;
      }

      const quantityArray = this.medicineDetails.filter(
        (m) => !m.quantity || m.quantity == 0
      );
      if (quantityArray.length > 0) {
        if (alertBox) {
          alertBox.classList =
            "alert alert-warning alert-dismissible fade show";
        }
        if (alertMsg)
          alertMsg.innerText =
            "Please enter Quantity for " +
            quantityArray.map((e) => e.name).join(", ");
        this.isError = true;
        return;
      }

      const refillsArray = this.medicineDetails.filter(
        (m) => !m.refills || m.refills < 0
      );
      if (refillsArray.length < 0) {
        if (alertBox) {
          alertBox.classList =
            "alert alert-warning alert-dismissible fade show";
        }
        if (alertMsg)
          alertMsg.innerText =
            "Please enter Refills for " +
            refillsArray.map((e) => e.name).join(", ");
        this.isError = true;
        return;
      }

      if (alertBox) {
        alertBox.classList = "alert alert-success alert-dismissible fade show";
      }
      if (alertMsg)
        alertMsg.innerText =
          "Great! Everything looks good. Please save the prescription.";
      this.isError = false;
    },

    getRxChangeMessage(message, extraDetail) {
      switch (message) {
        case "Prescriber Authorization":
          return `Need Provider's Authorization`;

        case "State License Status":
          return `Please verify state license number for state code: ${extraDetail}`;

        default:
          return message;
      }
    },

    async acceptPrescription(prescriptionId) {
      try {
        this.showLoader(true);
        $("#prescription-accept-modal").modal("hide");

        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerAcceptPrescriptions
          }/${prescriptionId}/${this.$store.getters.user.id}`
        );

        Swal.fire({
          icon: "success",
          title: "Rx Request Approved!",
          text: "The prescription has been sent to the pharmacy for approval",
          timer: 3000,
        }).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-accept-modal").modal("hide");
        Swal.fire("Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async approveSingleRefill(prescriptionId, refillId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerApproveSingleRefill
          }/${prescriptionId}/${refillId}/${this.$store.getters.user.id}`
        );

        Swal.fire({
          icon: "success",
          title: "Refill Completed!",
          text: "Refill request has been approved",
          timer: 3000,
        }).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire("Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async denySingleRefill(prescriptionId, refillId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerDenySingleRefill
          }/${prescriptionId}/${refillId}/${this.$store.getters.user.id}`
        );

        Swal.fire({
          icon: "success",
          title: "Refill Denied!",
          text: "Refill request denied successfully",
          timer: 3000,
        }).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        Swal.fire("Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async approveAllRefills(prescriptionId) {
      try {
        this.showLoader(true);
        $("#prescription-approve-modal").modal("hide");
        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerApproveAllRefills
          }/${prescriptionId}/${this.$store.getters.user.id}`
        );

        Swal.fire({
          icon: "success",
          title: "Refill Approved!",
          text: "Refill request has been approved",
          timer: 3000,
        }).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire("Failed!", e.response.data.message, "error");
        $("#prescription-approve-modal").modal("hide");
        this.showLoader(false);
      }
    },

    async denyAllRefills(prescriptionId) {
      try {
        const pres = JSON.parse(prescription);
        this.showLoader(true);

        $("#prescription-deny-modal").modal("hide");

        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerDenyAllRefills
          }/${prescriptionId}/${this.$store.getters.user.id}`,
          {
            reason: pres.reason,
          }
        );

        Swal.fire({
          icon: "success",
          title: "Refill Denied!",
          text: "Refill request has been denied",
          timer: 3000,
        }).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        $("#prescription-deny-modal").modal("hide");
        Swal.fire("Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async rejectPrescription(prescription) {
      try {
        const pres = JSON.parse(prescription);
        this.showLoader(true);
        $("#prescription-cancel-modal").modal("hide");

        const res = await axios.post(
          `${config.BASE_URL + config.HTTP_API.providerRejectPrescriptions}/${
            pres.id
          }/${this.$store.getters.user.id}`,
          {
            reason: pres.reason,
          }
        );

        Swal.fire({
          icon: "success",
          title: "Prescription Cancelled",
          text: "Prescription request rejected successfully",
          timer: 3000,
        }).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-cancel-modal").modal("hide");
        Swal.fire("Cancellation Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async denyRxChangeRequest(rejectObj) {
      try {
        this.showLoader(true);
        const data = JSON.parse(rejectObj);

        $("#prescription-rxcancel-modal").modal("hide");

        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerDenyRxChangeRequest}${data.rxChangeId}`,
          {
            rxChangeRequestId: data.rxChangeId,
            reason: data.reason,
          }
        );

        Swal.fire({
          icon: "success",
          title: "RxChange Denied!",
          text: "RxChange denied successfully",
          timer: 3000,
        }).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-rxcancel-modal").modal("hide");
        Swal.fire("Failed!", e.response.data.message, "error").then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      }
    },

    getStatusColor(status) {
      // console.log(status);
      switch (status) {
        case "Pending":
          return "btn bg-secondary-light ml-2";

        case "In Progress":
          return "btn bg-warning-light ml-2";

        case "Completed":
        case "Accepted":
          return "btn bg-success-light ml-2";

        case "Cancelled":
          return "btn bg-danger-light ml-2";

        default:
          return "btn bg-warning-light ml-2";
      }
    },

    getRxStatusColor(status) {
      switch (status) {
        case "Pending":
          return "fas text-warning fa-refresh mr-2";

        case "Completed":
          return "fas text-success fa-refresh mr-2";

        case "Denied":
          return "fas text-danger fa-refresh mr-2";

        default:
          return "fas text-warning fa-refresh mr-2";
      }
    },

    getStatusText(status) {
      switch (status) {
        case "Accepted":
          if (
            this.medicineDetails.find(
              (e) =>
                e.dsp_status_id === 0 ||
                e.dsp_status_id === 1 ||
                !e.is_refill_initiated
            )
          ) {
            this.isPharmacyVerified = false;
            return "WAITING FOR PHARMACY CONFIRMATION";
          }

          this.isPharmacyVerified = true;
          return "PHARMACY VERIFIED";

        case "Cancelled":
          return this.medicineDetails.filter(
            (i) => i.dspCancellationStatus == "In Process"
          ).length > 0
            ? "Cancellation In Process"
            : "Cancelled";

        case "Error":
          return "Error / Cancelled";

        default:
          return status;
      }
    },

    getRxChangeStatusColor(status) {
      switch (status) {
        case "Pending":
          return "badge badge-pill bg-warning-light";

        case "Completed":
          return "badge badge-pill bg-success-light";

        case "Denied":
          return "badge badge-pill bg-danger-light";

        case "Obsolete":
          return "badge badge-pill bg-secondary-light";

        default:
          return "";
      }
    },
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .webViewTable {
    display: none;
  }

  .mobileView {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

p {
  font: 1.2em sans-serif;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}

.mycontent-left {
  border-right: 1px solid rgb(194, 194, 194);
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

.slideout {
  background: linear-gradient(to bottom, #15558d 0%, #15558d 100%);
  color: #fff;
  height: 100%;
  padding-left: 2px;
  position: fixed;
  right: -500px;
  top: 0;
  transition-duration: 0.2s;
  width: 500px;
  z-index: 9999;
}

.slideout.on {
  right: 0;
}
</style>
