<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb43></breadcrumb43>

    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickyside">
              <sidebar></sidebar>
            </div>
          </div>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  href="#profile-settings"
                  data-toggle="tab"
                  >{{
                    $t("provider.sideBar.accountSetting.profileSettings.title")
                  }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#provider-settings"
                  data-toggle="tab"
                  >{{
                    $t("provider.sideBar.accountSetting.providerSettings.title")
                  }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#payment-settings"
                  data-toggle="tab"
                  >{{
                    $t("provider.sideBar.accountSetting.paymentSettings.title")
                  }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#account-settings"
                  data-toggle="tab"
                  >{{
                    $t("provider.sideBar.accountSetting.accountSettings.title")
                  }}</a
                >
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane show active" id="profile-settings">
                <ProfileSettings />
              </div>
              <div class="tab-pane" id="provider-settings">
                <ProviderSettings />
              </div>
              <div class="tab-pane" id="payment-settings">
                <PaymentSettings />
              </div>
              <div class="tab-pane" id="account-settings">
                <AccountSettings />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>
<script>
import ProfileSettings from "./settings/profile.vue";
import ProviderSettings from "./settings/provider.vue";
import PaymentSettings from "./settings/payment.vue";
import AccountSettings from "./settings/account.vue";

export default {
  components: {
    ProfileSettings,
    ProviderSettings,
    PaymentSettings,
    AccountSettings,
  },
};
</script>
