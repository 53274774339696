<template>
  <!-- Profile Sidebar -->
  <div class="profile-sidebar">
    <div class="widget-profile pro-widget-content">
      <div class="profile-info-widget" v-if="user">
        <a href="javascript:void();" class="booking-doc-img">
          <!-- <img
            src="../../../assets/img/doctors/doctor-thumb-02.jpg"
            alt="User Image"
          > -->
          <img :src="profileImage" alt="User Image" />
        </a>
        <div class="profile-det-info" v-if="user">
          <h3>{{ user.first_name }} {{ user.last_name }}</h3>
          <div class="patient-details">
            <h5 class="mb-0">{{ user.description }}, {{ user.state }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-widget">
      <nav class="dashboard-menu">
        <ul>
          <li>
            <a href="javascript:void();">
              <i class="fas fa-key"></i>
              <span>Account ID: {{ user.id }}-{{ user.dsp_user_id }}</span>
            </a>
          </li>
          <li :class="currentPath == 'doctor-index' ? 'active' : 'notactive'">
            <router-link to="/provider/index">
              <i class="fas fa-columns"></i>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li
            :class="
              currentPath == 'provider-prescriptions' ? 'active' : 'notactive'
            "
          >
            <router-link to="/provider/prescriptions">
              <i class="fas fa-prescription"></i>
              <span>All Prescriptions</span>
            </router-link>
          </li>
          <li :class="currentPath == 'finances' ? 'active' : 'notactive'">
            <router-link to="/provider/finances">
              <i class="fas fa-file-invoice-dollar"></i>
              <span>Financial Dashboard</span>
            </router-link>
          </li>
          <li :class="currentPath == 'settings' ? 'active' : 'notactive'">
            <router-link to="/provider/settings">
              <i class="fas fa-wrench"></i>
              <span>Account Settings</span>
            </router-link>
          </li>
          <li @click="$emit('openReferModal')">
            <a href="javascript:void();">
              <i class="fas fa-user-plus"></i>
              <span>Refer a Patient</span>
            </a>
          </li>
          <li class="notaactive" @click="logout">
            <a href="javascript:void();">
              <i class="fas fa-sign-out-alt"></i>
              <span>Log out</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
const images = require.context("../../../assets/img", false, /\.png$|\.jpg$/);

export default {
  methods: {
    ...mapActions(["logOut"]),
    async logout() {
      try {
        await this.logOut({
          id: this.$store.getters.user.id,
        });

        this.$router.push("/login");
        this.$router.go();
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    profileImage() {
      return this.user.profile_image_url
        ? this.user.profile_image_url
        : images("./blank.png").default;
    },
    user() {
      return this.$store.getters.user;
    },
    dob() {
      const dob = this.$store.getters.user.dob.split("/");
      let month = "";
      if (dob[0] == 1) month = "Jan";
      if (dob[0] == 2) month = "Feb";
      if (dob[0] == 3) month = "Mar";
      if (dob[0] == 4) month = "Apr";
      if (dob[0] == 5) month = "May";
      if (dob[0] == 6) month = "Jun";
      if (dob[0] == 7) month = "Jul";
      if (dob[0] == 8) month = "Aug";
      if (dob[0] == 9) month = "Sep";
      if (dob[0] == 10) month = "Oct";
      if (dob[0] == 11) month = "Nov";
      if (dob[0] == 12) month = "Dec";

      const age = new Date().getFullYear() - parseInt(dob[2]);
      return `${dob[1]} ${month} ${dob[2]}, ${age} years`;
    },
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
