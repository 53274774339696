<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Account Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-12 col-lg-6 login-right">
                    <div class="login-header">
                      <h3>Create New Password</h3>
                      <!-- <p class="small text-muted">Enter your email to get a password reset link</p> -->
                    </div>

                    <form @submit.prevent="changePassword">
                      <div :class="`form-group form-focus ${password ? 'focused' : ''}`">
                        <input
                          type="password"
                          v-model="password"
                          class="form-control floating"
                          required
                        />
                        <label class="focus-label">Password</label>
                      </div>

                      <div :class="`form-group form-focus ${repeatPassword ? 'focused' : ''}`">
                        <input
                          type="password"
                          v-model="repeatPassword"
                          class="form-control floating"
                          required
                        />
                        <label class="focus-label">Repeat Password</label>
                      </div>

                      <button
                        class="btn btn-primary btn-block btn-lg login-btn"
                        type="submit"
                      >
                        Reset Password
                      </button>
                      <div class="text-left">
                        <router-link class="forgot-link mt-3" to="/login"
                          >Login To Account</router-link
                        >
                      </div>
                    </form>
                  </div>

                  <!-- <div class="col-md-7 col-lg-6 login-left">
                    <img
                      src="../assets/img/login-banner.png"
                      class="img-fluid"
                      alt="Login Banner"
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /Page Content -->
      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  props: {
    token: String,
  },
  data() {
    return {
      password: "",
      repeatPassword: "",
    };
  },
  methods: {
    ...mapActions(["resetPassword", "showLoader"]),
    async changePassword() {
      try {
        if (this.password !== this.repeatPassword) {
          Swal.fire("Password Mismatch", "", "error");
          return;
        }
        this.showLoader(true);
        await this.resetPassword({
          password: this.password,
          token: String(this.token).replace(/\*/g, "."),
        });
        this.password = "";
        this.repeatPassword = "";

        Swal.fire(
          "Success!",
          "Your password has been changed. ",
          "success"
        ).then(() => {
          this.$router.push("/login");
        });

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Password Reset Failed", e.response.data.message, "error");
      }
    },
  },
};
</script>
