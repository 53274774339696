<template>
  <section class="section section-search">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="banner-wrapper">
            <div class="banner-header text-center">
              <h2>Refill Information</h2>
            </div>
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="confirmProceed">
                  <div class="row">
                    <div class="col-12">
                      <!-- web view -->
                      <div class="row webViewTable">
                        <div class="col-12">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Medication</th>
                                  <th class="text-center">Quantity</th>
                                  <th class="text-center">Unit</th>
                                  <th class="text-center">Refills</th>
                                  <th class="text-center">Supply Days</th>
                                </tr>
                              </thead>
                              <tbody style="font-size: 12px">
                                <tr v-for="(med, i) in selectedMedicines" :key="i">
                                  <td>{{ med.name }}</td>
                                  <td class="text-left">
                                    <input type="number" class="form-control" min="1" max="5000" step="any"
                                      v-model="med.quantity" @change="
                  quanityCorrection(
                    selectedMedicines,
                    med.quantity,
                    i
                  )
                  " required />
                                  </td>
                                  <td class="text-center">
                                    <div class="form-group">
                                      <select class="form-control select" aria-hidden="true" v-model="med.unit_id"
                                        required>
                                        <option v-for="(unit, i) in units" :key="i" :value="unit.StandardDispenseUnitTypeID
                  ">
                                          {{ unit.Plural }} ({{
                  unit.Abbreviation
                }})
                                        </option>
                                      </select>
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <input type="number" class="form-control" min="0" max="90" v-model="med.refills"
                                      required />
                                  </td>
                                  <td class="text-center">
                                    <input type="number" class="form-control" min="0" max="999"
                                      v-model="med.supply_days" required />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- /web view -->

                      <!-- Mobile view -->
                      <div class="mobileView">
                        <h4 class="text-center mb-2">Medication</h4>
                        <hr />
                        <div v-for="(med, i) in selectedMedicines" :key="i">
                          <h5>{{ med.name }}</h5>
                          <div class="row">
                            <label class="col-4" style="margin-top: 12px">Quantity:
                            </label>
                            <input type="number" class="form-control form-control-sm mb-1 col-8" min="1" max="5000"
                              step="any" v-model="med.quantity" required />
                          </div>

                          <div class="row">
                            <label class="col-4" style="margin-top: 12px">Unit:
                            </label>
                            <select class="form-control form-control-sm mb-1 col-8" aria-hidden="true"
                              v-model="med.unit_id" required>
                              <option v-for="(unit, i) in units" :key="i" :value="unit.StandardDispenseUnitTypeID">
                                {{ unit.Plural }} ({{ unit.Abbreviation }})
                              </option>
                            </select>
                          </div>

                          <div class="row">
                            <label class="col-4" style="margin-top: 12px">Refills:
                            </label>
                            <input type="number" class="form-control form-control-sm mb-1 col-8" min="0" max="90"
                              v-model="med.refills" required />
                          </div>

                          <!-- <div class="row">
                            <label class="col-4" style="margin-top: 12px"
                              >Days Supply:
                            </label>
                            <input
                              type="number"
                              class="form-control form-control-sm mb-1 col-8"
                              min="1"
                              max="999"
                              v-model="med.daysSupply"
                              required
                            />
                          </div> -->

                          <hr />
                        </div>
                      </div>
                      <!-- /Mobile view -->

                      <hr />
                      <div class="row">
                        <div class="col-12">
                          <div class="remember-me-col d-flex justify-content-between mt-3">
                            <span>Allow Substitutions from the Pharmacy</span>
                            <label class="custom_check">
                              <input v-model="allowSubstitutions" type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <!-- <div class="col-12 col-lg-3 order-2">
                      <button class="btn btn-primary btn-block mb-2">
                        <i class="fa fa-arrow-left"></i> Back
                      </button>
                    </div> -->
                    <div class="col-12 col-lg-12 order-1">
                      <button type="submit" class="btn btn-info btn-block mb-2">
                        Confirm & Proceed <i class="fa fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
export default {
  mixins: [commonMixin],
  props: {
    selectedMedicines: Array,
  },
  data() {
    return {
      desiredQuantity: "",

      allowSubstitutions: true,
      units: "",
    };
  },
  async mounted() {
    this.showLoader(true);
    const res = await this.getDispenseUnits();
    this.units = res
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((e) => e.IsActive === true);
    this.showLoader(false);
  },
  methods: {
    ...mapActions(["showLoader"]),
    quanityCorrection(selectedMedicines, quantity, medIndex) {
      let selectedMedicine = selectedMedicines[medIndex];

      // If input is a decimal with all zeros after the decimal point, change it to a whole number
      if (quantity % 1 === 0) {
        selectedMedicine.quantity = Math.round(quantity);
      }

      // Populate zero in front if input starts with a decimal point
      if (String(quantity).startsWith(".")) {
        selectedMedicine.quantity = 0 + quantity;
      }

      // Limit the total length to 10 digits including the decimal point
      if (String(quantity).length > 5 && String(quantity).indexOf(".") >= 0) {
        selectedMedicine.quantity = String(quantity).substr(
          0,
          String(quantity).indexOf(".") + 6
        );
      }

      // Limit the input to 5000
      if (quantity > 5000) {
        selectedMedicine.quantity = 5000;
      }
    },
    confirmProceed() {
      if (this.reasonForMedication === "") {
        alert("Reason for medication is strictly required");
        return;
      }

      this.$emit(
        "select",
        JSON.stringify({
          selectedMedicines: this.selectedMedicines,
          allowSubstitutions: this.allowSubstitutions,
        })
      );
    },
  },
};
</script>
<style scoped>
.section-search {
  min-height: 600px;
}

.search-box .search-info {
  flex: 0 0 400px;
  width: 450px;
}

@media only screen and (max-width: 600px) {
  .webViewTable {
    display: none;
  }

  .mobileView {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}
</style>