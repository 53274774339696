<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <img src="../../assets/img/logo.png" alt="" width="300" />
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/patient/step-1" class="active-done">1</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-2" class="active-done">2</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-3" class="active-done">3</a>
                        </li>
                        <li>
                          <a href="javascript:void()" class="active">4</a>
                        </li>
                        <li><a href="javascript:void();">5</a></li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <h3 class="my-4">Address</h3>
                      <div class="form-group">
                        <div class="form-group text-left">
                          <label>Address Line 1</label>
                          <input type="text" name="address1" class="form-control" v-model="addressLine1" maxlength="34"
                            required />
                        </div>
                        <div class="form-group text-left">
                          <label>Address Line 2 (Optional)</label>
                          <input type="text" name="address2" class="form-control" v-model="addressLine2"
                            maxlength="34" />
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label>State</label>

                              <vue-select class="form-control w-100" v-model="state" :options="statesFilteredData"
                                :value-by="(state) => state.state_code" label-by="state_name" searchable clear-on-close
                                close-on-select clear-on-select :loading="loading" @search:input="startSearchingState"
                                @search:change="fetchCities()" placeholder="Select State"
                                :disabled="statesFilteredData.length === 0"></vue-select>
                            </div>
                          </div>
                          <div class="col-6">
                            <label>City</label>
                            <vue-select class="form-control w-100" v-model="city" :options="citiesFilteredData"
                              :value-by="(city) => city.city_name" label-by="city_name" searchable clear-on-close
                              close-on-select clear-on-select :loading="loading" @search:input="startSearchingCity"
                              @search:change="resetCityFilter()" placeholder="Select City"
                              :disabled="citiesFilteredData.length === 0"></vue-select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Zip Code</label>
                          <input type="text" name="zipcode" class="form-control" v-model="zipCode" @keyup="findAddress"
                            required />
                        </div>
                      </div>
                      <!-- <div class="mt-3">
                        <button
                          type="submit"
                          class="btn btn-primary btn-block btn-lg login-btn step1_submit"
                        >Continue</button>
                      </div> -->
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-6">
                            <a href="javascript:void()" class="btn btn-primary btn-block btn-lg login-btn"
                              @click="goBack">
                              Back
                            </a>
                          </div>
                          <div class="col-6">
                            <button type="submit" class="btn btn-primary btn-block btn-lg login-btn step1_submit">
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span>© 2023. StepAhead Health Inc. All Rights Reserved.</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import common from "../../mixins/common";

export default {
  mixins: [common],
  data() {
    return {
      loading: false,
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      statesData: [],
      statesFilteredData: [],
      citiesData: [],
      citiesFilteredData: [],
    };
  },
  async mounted() {
    this.showLoader(true);
    this.statesData = this.statesFilteredData = await this.getStateList();
    this.addressLine1 = this.$store.getters.user.address1;
    this.addressLine2 = this.$store.getters.user.address2;
    this.zipCode = this.$store.getters.user.zip_code;

    this.state = this.$store.getters.user.state;
    if (this.state) await this.fetchCities(this.state);

    this.city = this.$store.getters.user.city;
    this.showLoader(false);
  },
  methods: {
    goBack() {
      this.$router.push("/patient/step-3");
    },
    ...mapActions(["saveUserDetails", "showLoader"]),
    startSearchingState(input) {
      try {
        if (
          input.target &&
          input.target.value &&
          input.target.value.length > 3
        ) {
          this.loading = true;

          this.statesFilteredData = this.statesData.filter((s) =>
            String(s.name).toLowerCase().includes(input.target.value)
          );

          this.loading = false;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    startSearchingCity(input) {
      try {
        if (
          input.target &&
          input.target.value &&
          input.target.value.length > 3
        ) {
          this.loading = true;

          this.citiesFilteredData = this.citiesData.filter((s) =>
            String(s.name).toLowerCase().includes(input.target.value)
          );

          this.loading = false;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    resetCityFilter() {
      this.citiesFilteredData = this.citiesData;
    },
    async fetchCities() {
      this.showLoader(true);
      this.citiesData = this.citiesFilteredData = await this.getCityList(
        this.state
      );
      this.statesFilteredData = this.statesData;
      this.showLoader(false);
    },

    async findAddress(e) {
      if (this.zipCode.length >= 5 && this.zipCode.length <= 6) {
        const address = await this.getAddressByPin(this.zipCode);
        // Set State
        for (let each of address) {
          const st = this.statesData.find(
            (e) =>
              String(e.name).toLowerCase() ===
              String(each.long_name).toLowerCase()
          );

          if (st) {
            this.state = st.iso2;
            await this.fetchCities();
          }
        }

        // Set City
        for (let each of address) {
          if (this.state !== "") {
            if (this.citiesData.length === 0) await this.fetchCities();
            const ct = this.citiesData.find(
              (e) =>
                String(e.name).toLowerCase() ===
                String(each.long_name).toLowerCase()
            );

            if (ct) {
              this.city = ct.name;
            }
          }
        }
      }
    },

    async saveStep() {
      try {
        this.showLoader(true);

        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          address1: this.addressLine1,
          address2: this.addressLine2,
          city: this.city,
          state: this.state,
          zip_code: this.zipCode,
          current_registration_step: 5,
        });

        this.$router.push("/patient/step-5");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        console.error(e);
      }
    },
  },
};
</script>
