<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb8></breadcrumb8>
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickyside">
              <sidebar1></sidebar1>
            </div>
          </div>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <!-- Change Password Form -->
                    <form @submit.prevent="changePassword">
                      <div class="form-group">
                        <label>
                          {{
                            $t("patient.changePassword.oldPasswordLabel")
                          }}</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          v-model="oldPassword"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label>{{
                          $t("patient.changePassword.newPasswordLabel")
                        }}</label>
                        <input
                          type="password"
                          class="form-control"
                          v-model="newPassword"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label>{{
                          $t("patient.changePassword.confirmPasswordLabel")
                        }}</label>
                        <input
                          type="password"
                          class="form-control"
                          v-model="repeatPassword"
                          required
                        />
                      </div>
                      <div class="submit-section">
                        <button
                          type="submit"
                          class="btn btn-primary submit-btn"
                        >
                          {{ $t("patient.changePassword.button") }}
                        </button>
                      </div>
                    </form>
                    <!-- /Change Password Form -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
    };
  },
  methods: {
    ...mapActions(["changeUserPassword", "showAlert", "showLoader"]),
    async changePassword() {
      try {
        if (this.newPassword !== this.repeatPassword) {
          this.showAlert({
            message: "Passwords do not match",
            type: "danger",
            time: 4000,
          });
          return;
        }

        this.showLoader(true);

        await this.changeUserPassword({
          id: this.$store.getters.user.id,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          role: this.$store.getters.user.role,
        });

        this.showAlert({
          message: "Password changed successfully",
          type: "success",
          time: 4000,
        });

        this.oldPassword = "";
        this.newPassword = "";
        this.repeatPassword = "";

        this.showLoader(false);
      } catch (e) {
        this.showAlert({
          message: e.response.data.message,
          type: "danger",
          time: 4000,
        });

        this.oldPassword = "";
        this.newPassword = "";
        this.repeatPassword = "";
        this.showLoader(false);
      }
    },
  },
};
</script>
