<template>
  <div class="row">
    <div class="col-4">
      <div class="card">
        <div class="card-header">
          <h5>
            {{
              $t(
                "provider.prescriptionDetails.medications.medicines.rxChangeRequests"
              )
            }}
          </h5>
        </div>
        <div
          style="overflow: auto; height: 550px"
          v-if="
            medicine &&
            medicine.pharmacy_rxchanges &&
            medicine.pharmacy_rxchanges.length > 0
          "
        >
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(rxchange, index) in medicine.pharmacy_rxchanges"
              :key="index"
              @click="selectRxChange(rxchange)"
              style="cursor: pointer"
              :class="
                selectedRxChangeObj && selectedRxChangeObj.id == rxchange.id
                  ? 'btn-info'
                  : ''
              "
            >
              <p class="mb-2">
                {{
                  $t(
                    "provider.prescriptionDetails.medications.medicines.rxChangeId"
                  )
                }}:
                <span> {{ rxchange.rxchange_request_id }}</span>
              </p>
              <!-- <p class="mb-2">
                Message ID:
                <span> {{ rxchange.requested_dsp_prescription_id }}</span>
              </p> -->
              <p class="mb-2">
                {{
                  $t("provider.prescriptionDetails.medications.medicines.date")
                }}:
                {{ new Date(rxchange.requested_date).toLocaleString() }}
              </p>
              <p class="mb-1">
                Status:
                <span :class="getRxChangeStatusColor(rxchange.status)">{{
                  String(rxchange.status).toUpperCase()
                }}</span>
                <span
                  class="ml-2"
                  :class="
                    rxchange.unmatched_change
                      ? 'badge badge-pill bg-danger text-light'
                      : 'badge badge-pill bg-success'
                  "
                  >{{ rxchange.unmatched_change ? "Unlinked" : "Linked" }}</span
                >
              </p>
            </li>
          </ul>
        </div>
        <div v-else class="text-center" style="overflow: auto; height: 550px">
          <p class="m-5">
            {{
              $t(
                "provider.prescriptionDetails.medications.medicines.nothingHere"
              )
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="card" v-if="selectedRxChangeObj">
        <div class="card-header">
          <div class="row">
            <div class="col-8">
              <h5>
                {{ selectedRxChangeObj.rxchange_request_id }}:
                {{ selectedRxChangeObj.rxchange_type }}
              </h5>
            </div>
            <div class="col-4 text-right">
              <small>{{
                new Date(selectedRxChangeObj.created_at).toLocaleString()
              }}</small>
            </div>
          </div>
        </div>
        <div class="card-body p-0" style="overflow: auto; height: 550px">
          <div
            v-if="selectedRxChangeObj"
            class="accordion"
            id="requested-prescription-accordion"
            style="font-size: 12px"
          >
            <!-- Reconcile prescription -->
            <div
              class="card m-3 p-3"
              v-if="
                selectedRxChangeObj.unmatched_change &&
                selectedRxChangeObj.linked_prescription_id != 1
              "
            >
              <h5 class="">
                It looks like this RxChange request does not have any
                prescription linked to it. Would you like to link the latest
                active prescription to this RxChange request?
              </h5>

              <hr />
              <div class="row">
                <div class="col-6">
                  <button
                    @click="
                      reconcileRxChangeRequest(
                        selectedRxChangeObj.rxchange_request_id
                      )
                    "
                    class="btn btn-sm bg-success-light btn-block"
                  >
                    Yes, Link it
                  </button>
                </div>
                <div class="col-6">
                  <button
                    @click="
                      discardRxChangeRequest(
                        selectedRxChangeObj.rxchange_request_id
                      )
                    "
                    class="btn btn-sm bg-danger-light btn-block"
                  >
                    Discard Request
                  </button>
                </div>
              </div>
            </div>
            <div
              class="card mb-0"
              style="font-size: 12px"
              v-for="(
                prescription, index
              ) of selectedRxChangeObj.requestedPrescriptions"
              :key="index"
            >
              <div
                class="card-header p-2"
                :id="`requested-rx-accordion-${index}`"
              >
                <h2 class="mb-0">
                  <button
                    style="font-size: 14px"
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    :data-target="`#requested-rx-collapse-${index}`"
                    :aria-controls="`requested-rx-collapse-${index}`"
                  >
                    {{ index + 1 }}. {{ prescription.medicine_name }}
                    <small
                      >(Rx ID: {{ prescription.dsp_prescription_id }})</small
                    >
                  </button>
                </h2>
              </div>
              <div
                :id="`requested-rx-collapse-${index}`"
                class="collapse"
                :aria-labelledby="`requested-rx-accordion-${index}`"
                data-parent="#requested-prescription-accordion"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group text-left">
                        <label
                          ><strong>{{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.quantity"
                            )
                          }}</strong></label
                        >
                        <input
                          type="number"
                          class="form-control form-control-xs"
                          v-model="prescription.quantity"
                          min="1"
                          max="5000"
                          step="any"
                          :disabled="true"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group text-left">
                        <label
                          ><strong>{{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.unit"
                            )
                          }}</strong></label
                        >
                        <select
                          class="form-control select form-control-xs"
                          tabindex="-1"
                          aria-hidden="true"
                          v-model="prescription.unit_id"
                          required
                          :disabled="true"
                        >
                          <option
                            v-for="(unit, i) in units"
                            :key="i"
                            :value="unit.StandardDispenseUnitTypeID"
                          >
                            {{ unit.Plural }} ({{ unit.Abbreviation }})
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group text-left">
                        <label
                          ><strong>{{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.refillsRequested"
                            )
                          }}</strong></label
                        >
                        <input
                          type="number"
                          class="form-control form-control-xs"
                          v-model="prescription.refills"
                          min="0"
                          max="99"
                          :disabled="true"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group text-left">
                        <label
                          ><strong>{{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.daysSupply"
                            )
                          }}</strong></label
                        >
                        <input
                          type="number"
                          class="form-control form-control-xs"
                          v-model="prescription.supply_days"
                          min="1"
                          max="999"
                          :disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group text-left">
                        <label
                          ><strong>{{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.description"
                            )
                          }}</strong></label
                        >
                        <textarea
                          class="form-control form-control-xs"
                          @keydown="
                            () => {
                              isEditing = true;
                            }
                          "
                          v-model="prescription.directions"
                          placeholder="Directions of use"
                          :disabled="false"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group text-left">
                        <label
                          ><strong>{{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.pharmacyNotes"
                            )
                          }}</strong></label
                        >
                        <textarea
                          class="form-control form-control-xs"
                          @keydown="
                            () => {
                              isEditing = true;
                            }
                          "
                          v-model="prescription.pharmacy_note"
                          placeholder="Notes to Pharmacy"
                          :disabled="true"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- RxChange Buttons -->
                  <div
                    class="row"
                    v-if="
                      selectedRxChangeObj.rxchange_type !==
                      'Prior Authorization'
                    "
                  >
                    <div
                      class="col-12"
                      v-if="selectedRxChangeObj.status !== 'Pending'"
                    >
                      <h5>Current Status: {{ selectedRxChangeObj.status }}</h5>
                    </div>
                    <div
                      class="col-6"
                      v-if="selectedRxChangeObj.status === 'Pending'"
                    >
                      <button
                        @click="
                          showRejectRxChangeModal(
                            prescription.dsp_prescription_id,
                            selectedRxChangeObj.rxchange_request_id
                          )
                        "
                        class="btn btn-sm bg-danger-light btn-block"
                      >
                        Deny
                      </button>
                    </div>
                    <div
                      class="col-6"
                      v-if="selectedRxChangeObj.status === 'Pending'"
                    >
                      <button
                        @click="
                          approveRxChangeRequest(
                            selectedRxChangeObj.rxchange_request_id,
                            selectedRxChangeObj.requested_dsp_prescription_id,
                            selectedRxChangeObj.linked_prescription_id
                          )
                        "
                        class="btn btn-sm bg-success-light btn-block"
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Prior authorization input -->
            <div
              class="card-body pt-0"
              v-if="selectedRxChangeObj.rxchange_type == 'Prior Authorization'"
            >
              <form
                @submit.prevent="
                  approveRxChangeRequest(
                    selectedRxChangeObj.rxchange_request_id,
                    selectedRxChangeObj.prescribed_dsp_prescription_id,
                    selectedRxChangeObj.linked_prescription_id
                  )
                "
              >
                <div class="form-group text-left">
                  <h5>Prior Authorization Number is required</h5>
                  <input
                    type="text"
                    class="form-control"
                    v-model="priorAuthNumber"
                    @keyup="trim35Chars"
                    placeholder="Enter prior authorization number"
                    required
                  />
                </div>

                <!-- RxChange Buttons -->
                <div class="row">
                  <div
                    class="col-12"
                    v-if="selectedRxChangeObj.status !== 'Pending'"
                  >
                    <h5>Current Status: {{ selectedRxChangeObj.status }}</h5>
                  </div>
                  <div
                    class="col-6"
                    v-if="selectedRxChangeObj.status === 'Pending'"
                  >
                    <button
                      type="button"
                      @click="
                        showRejectRxChangeModal(
                          prescription.dsp_prescription_id,
                          selectedRxChangeObj.rxchange_request_id
                        )
                      "
                      class="btn btn-sm bg-danger-light btn-block"
                    >
                      Deny
                    </button>
                  </div>
                  <div
                    class="col-6"
                    v-if="selectedRxChangeObj.status === 'Pending'"
                  >
                    <button
                      type="submit"
                      class="btn btn-sm bg-success-light btn-block"
                    >
                      Approve
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prescriptionDetails: Object,
    medicine: Object,
    units: Array,
  },
  watch: {
    prescriptionDetails: function (old, newVal) {
      //   console.log(this.prescriptionDetails);
    },
    medicine: function (old, newVal) {
      this.selectedRxChangeObj =
        this.medicine && this.medicine.pharmacy_rxchanges
          ? this.medicine.pharmacy_rxchanges[0]
          : null;
    },
  },
  data() {
    return {
      selectedRxChangeObj: null,
      priorAuthNumber: "",
    };
  },
  mounted() {
    this.selectedRxChangeObj =
      this.medicine && this.medicine.pharmacy_rxchanges
        ? this.medicine.pharmacy_rxchanges[0]
        : null;
  },
  methods: {
    trim35Chars(e) {
      if (String(this.priorAuthNumber).length > 35) {
        this.priorAuthNumber = String(this.priorAuthNumber).substring(0, 35);
      }
    },
    showRejectRxChangeModal(dspPrescriptionId, rxChangeId) {
      this.$emit(
        "showRejectRxChangeModal",
        JSON.stringify({
          dspPrescriptionId: dspPrescriptionId,
          selectedRxChangeId: rxChangeId,
        })
      );
    },
    approveRxChangeRequest(
      rxChangeRequestId,
      requestedPrescriptionId,
      originalPrescriptionId
    ) {
      let type = "General";
      if (this.selectedRxChangeObj.rxchange_type === "Prior Authorization")
        type = "Prior";

      this.$emit(
        "approveRxChangeRequest",
        JSON.stringify({
          rxChangeRequestId,
          requestedPrescriptionId,
          originalPrescriptionId,
          priorAuthNumber: this.priorAuthNumber,
          type: type,
        })
      );
    },
    reconcileRxChangeRequest(rxChangeRequestId) {
      this.$emit("reconcileRxChangeRequest", {
        rxChangeRequestId: rxChangeRequestId,
        prescriptionId: this.medicine.dsp_prescription_id,
      });
    },
    discardRxChangeRequest(rxChangeRequestId) {
      this.$emit("discardRxChangeRequest", { rxChangeRequestId });
    },
    selectRxChange(rxChange) {
      this.selectedRxChangeObj = rxChange;
    },
    getRxChangeStatusColor(status) {
      switch (status) {
        case "Pending":
          return "badge badge-pill bg-warning";

        case "Completed":
          return "badge badge-pill bg-success";

        case "Denied":
          return "badge badge-pill bg-danger";

        case "Obsolete":
          return "badge badge-pill bg-secondary";

        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
