<template>
  <div>
    <div class="main-wrapper">
      <layout-header></layout-header>

      <breadcrumb></breadcrumb>
      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- Profile Sidebar -->
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div class="stickysidebar">
                <sidebar1></sidebar1>
              </div>
            </div>
            <!-- / Profile Sidebar -->

            <div class="col-md-7 col-lg-8 col-xl-9">
              <div class="row">
                <div
                  class="col-12 col-md-4 col-lg-4 col-xl-4 patient-dashboard-top"
                >
                  <div class="card card-btn">
                    <router-link to="/patient/new-prescription">
                      <div class="card-body text-center">
                        <div class="mb-3">
                          <img
                            src="../../assets/icons/prescription.png"
                            alt=""
                            width="55"
                          />
                        </div>
                        <h5>{{ $t("patient.index.createNew.title") }}</h5>
                        <h6>{{ $t("patient.index.createNew.description") }}</h6>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div
                  class="col-12 col-md-4 col-lg-4 col-xl-4 patient-dashboard-top"
                >
                  <div class="card card-btn">
                    <router-link to="/patient/new-refill">
                      <div class="card-body text-center">
                        <div class="mb-3">
                          <img
                            src="../../assets/icons/refill.png"
                            alt=""
                            width="55"
                          />
                        </div>
                        <h5>
                          {{ $t("patient.index.alreadyPrescribed.title") }}
                        </h5>
                        <h6>
                          {{
                            $t("patient.index.alreadyPrescribed.description")
                          }}
                        </h6>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div
                  class="col-12 col-md-4 col-lg-4 col-xl-4 patient-dashboard-top"
                >
                  <div class="card card-btn">
                    <div class="card-body text-center">
                      <div class="mb-3">
                        <img
                          src="../../assets/img/appointments.png"
                          alt=""
                          width="55"
                        />
                      </div>
                      <h5>{{ $t("patient.index.onlineAppointment.title") }}</h5>
                      <h6>
                        {{ $t("patient.index.onlineAppointment.description") }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <!-- web view table -->
              <div class="card prescriptionTable">
                <div class="card-body pt-0">
                  <!-- Tab Menu -->
                  <nav class="user-tabs mb-4">
                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          href="#pat_prescriptions"
                          data-toggle="tab"
                        >
                          {{ $t("patient.index.rxRequests.title") }}</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#pat_refills"
                          data-toggle="tab"
                        >
                          {{ $t("patient.index.rxRefills.title") }}</a
                        >
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab">{{
                          $t("patient.index.appointment.title")
                        }}</a>
                      </li>
                    </ul>
                  </nav>
                  <!-- /Tab Menu -->

                  <!-- Tab Content -->
                  <div class="tab-content pt-0 prescription-table">
                    <!-- Prescription Tab -->
                    <div
                      class="tab-pane fade show active"
                      id="pat_prescriptions"
                    >
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table
                              class="table table-hover table-center mb-0"
                              v-if="freeTextPrescriptions.length > 0"
                            >
                              <thead>
                                <tr>
                                  <th>
                                    {{ $t("patient.index.rxRequests.head1") }}
                                  </th>
                                  <th>
                                    {{ $t("patient.index.rxRequests.head2") }}
                                  </th>
                                  <th>
                                    {{ $t("patient.index.rxRequests.head3") }}
                                  </th>
                                  <th>
                                    {{ $t("patient.index.rxRequests.head4") }}
                                  </th>
                                  <th>
                                    {{ $t("patient.index.rxRequests.head5") }}
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody style="font-size: 13px">
                                <tr
                                  v-for="(
                                    prescription, i
                                  ) in freeTextPrescriptions"
                                  :key="i"
                                >
                                  <!-- PRESCRIPTION ID -->
                                  <td>
                                    <strong>{{ prescription.id }}</strong>
                                  </td>

                                  <!-- SYMPTOMS -->
                                  <td>
                                    {{
                                      String(
                                        prescription.observations.symptoms
                                      ).substring(0, 15)
                                    }}...
                                  </td>

                                  <!-- DATE -->
                                  <td>
                                    {{
                                      new Date(
                                        prescription.created_at
                                      ).toDateString()
                                    }}
                                  </td>

                                  <!-- MEDICAL PROVIDER -->
                                  <td>
                                    <h2
                                      class="table-avatar"
                                      v-if="prescription.provider"
                                    >
                                      <a
                                        href="javascript:void();"
                                        class="avatar avatar-sm mr-2"
                                      >
                                        <img
                                          v-if="prescription.provider"
                                          class="avatar-img rounded-circle"
                                          :src="
                                            prescription.provider
                                              .profile_image_url
                                          "
                                          alt="User Image"
                                        />
                                      </a>
                                      <a
                                        href="javascript:void();"
                                        v-if="prescription.provider"
                                      >
                                        {{ prescription.provider.first_name }}
                                        {{ prescription.provider.last_name }}
                                        <span>{{
                                          prescription.provider.description
                                        }}</span>
                                      </a>
                                    </h2>
                                    <h2 class="table-avatar" v-else>
                                      <a href="javascript:void();"
                                        >Not Assigned Yet
                                        <span>Waiting for providers...</span>
                                      </a>
                                    </h2>
                                  </td>

                                  <!-- STATUS -->
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      :class="
                                        getStatusColor(prescription.status)
                                      "
                                    >
                                      <span
                                        v-if="
                                          prescription.status === 'Accepted' &&
                                          prescription.is_payment_completed
                                        "
                                      >
                                        Provider Approval Pending
                                      </span>
                                      <span v-else>
                                        {{
                                          pharmacyStatus(prescription.status)
                                        }}
                                      </span>
                                    </a>
                                  </td>
                                  <!-- VIEW BUTTON -->
                                  <td class="text-right">
                                    <div class="table-action">
                                      <router-link
                                        v-if="prescription.is_payment_completed"
                                        :to="`/patient/request/${prescription.id}`"
                                      >
                                        <a
                                          href="javascript:void()"
                                          data-toggle="modal"
                                          class="btn btn-sm bg-info-light mr-2 btn-block"
                                        >
                                          View Details
                                          <i class="fa fa-angle-right"></i>
                                        </a>
                                      </router-link>
                                      <a
                                        v-if="
                                          !prescription.is_payment_completed &&
                                          prescription.status !== 'Cancelled'
                                        "
                                        href="javascript:void();"
                                        @click="
                                          $router.push(
                                            `/patient/billing/${prescription.id}`
                                          )
                                        "
                                        class="btn btn-sm bg-primary-light mr-2 btn-block"
                                      >
                                        Pay Fees
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div v-else class="p-5 text-center">
                              <h5>You haven't created any Rx request yet</h5>
                              <router-link to="/patient/new-prescription">
                                <button class="btn btn-sm btn-info mt-2">
                                  Create New Rx Request
                                </button>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Prescription Tab -->

                    <!-- RX REFILLS Tab -->
                    <div class="tab-pane fade" id="pat_refills">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table
                              class="table table-hover table-center mb-0"
                              v-if="refillPrescriptions.length > 0"
                            >
                              <thead>
                                <tr>
                                  <th>Rx ID</th>
                                  <th>Symptoms</th>
                                  <th>Date | Time</th>
                                  <th>Medical Provider</th>
                                  <th>Status</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody style="font-size: 13px">
                                <tr
                                  v-for="(
                                    prescription, i
                                  ) in refillPrescriptions"
                                  :key="i"
                                >
                                  <!-- PRESCRIPTION ID -->
                                  <td>
                                    <strong>{{ prescription.id }}</strong>
                                  </td>

                                  <!-- SYMPTOMS -->
                                  <td>
                                    {{
                                      String(
                                        prescription.observations.symptoms
                                      ).substring(0, 15)
                                    }}...
                                  </td>

                                  <!-- DATE -->
                                  <td>
                                    {{
                                      new Date(
                                        prescription.created_at
                                      ).toDateString()
                                    }}
                                  </td>

                                  <!-- MEDICAL PROVIDER -->
                                  <td>
                                    <h2
                                      class="table-avatar"
                                      v-if="
                                        (prescription.provider &&
                                          prescription.status ===
                                            'In Progress') ||
                                        prescription.status === 'Accepted' ||
                                        prescription.status === 'Completed' ||
                                        prescription.status === 'Cancelled'
                                      "
                                    >
                                      <a
                                        href="javascript:void();"
                                        class="avatar avatar-sm mr-2"
                                      >
                                        <img
                                          v-if="prescription.provider"
                                          class="avatar-img rounded-circle"
                                          :src="
                                            prescription.provider
                                              .profile_image_url
                                          "
                                          alt="User Image"
                                        />
                                      </a>
                                      <a
                                        href="javascript:void();"
                                        v-if="prescription.provider"
                                        >{{ prescription.provider.first_name }}
                                        {{ prescription.provider.last_name }}
                                        <span>{{
                                          prescription.provider.description
                                        }}</span>
                                      </a>
                                    </h2>
                                    <h2 class="table-avatar" v-else>
                                      <a href="javascript:void();"
                                        >Not Assigned Yet
                                        <span>Waiting for providers...</span>
                                      </a>
                                    </h2>
                                  </td>

                                  <!-- STATUS -->
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      :class="
                                        getStatusColor(prescription.status)
                                      "
                                    >
                                      <span
                                        v-if="
                                          prescription.status === 'Accepted' &&
                                          prescription.is_payment_completed
                                        "
                                      >
                                        Provider Approval Pending
                                      </span>
                                      <span v-else>
                                        {{
                                          pharmacyStatus(prescription.status)
                                        }}
                                      </span>
                                    </a>
                                  </td>
                                  <!-- VIEW BUTTON -->
                                  <td class="text-right">
                                    <div class="table-action">
                                      <router-link
                                        v-if="prescription.is_payment_completed"
                                        :to="`/patient/request/${prescription.id}`"
                                      >
                                        <a
                                          href="javascript:void()"
                                          data-toggle="modal"
                                          class="btn btn-sm bg-info-light mr-2 btn-block"
                                        >
                                          View Details
                                          <i class="fa fa-angle-right"></i>
                                        </a>
                                      </router-link>
                                      <a
                                        v-if="
                                          !prescription.is_payment_completed &&
                                          prescription.status !== 'Cancelled'
                                        "
                                        href="javascript:void();"
                                        @click="
                                          $router.push(
                                            `/patient/billing/${prescription.id}`
                                          )
                                        "
                                        class="btn btn-sm bg-primary-light mr-2 btn-block"
                                      >
                                        Pay Fees
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div v-else class="p-5 text-center">
                              <h5>
                                {{ $t("patient.index.rxRefills.title") }}
                              </h5>
                              <router-link to="/patient/new-refill">
                                <button class="btn btn-sm btn-info mt-2">
                                  {{ $t("patient.index.rxRefills.button") }}
                                </button>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /RX REFILLS Tab -->

                    <!-- Appointment Tab -->
                    <div id="pat_appointments" class="tab-pane fade">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Doctor</th>
                                  <th>Appt Date</th>
                                  <th>Booking Date</th>
                                  <th>Amount</th>
                                  <th>Follow Up</th>
                                  <th>Status</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="item in appointments" :key="item.id">
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        to="/provider/profile"
                                        class="avatar avatar-sm mr-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="loadImg(item.doctor_image)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link to="/provider/profile"
                                        >{{ item.doctor_name
                                        }}<span>{{
                                          item.category
                                        }}</span></router-link
                                      >
                                    </h2>
                                  </td>
                                  <td>
                                    {{ item.appt_date
                                    }}<span class="d-block text-info">{{
                                      item.appt_time
                                    }}</span>
                                  </td>
                                  <td>{{ item.booking_date }}</td>
                                  <td>{{ item.amount }}</td>
                                  <td>{{ item.follow_up }}</td>
                                  <td>
                                    <span
                                      class="badge badge-pill bg-success-light"
                                      v-if="item.status == 'Confirm'"
                                      >Confirm</span
                                    >
                                    <span
                                      class="badge badge-pill bg-danger-light"
                                      v-if="item.status == 'Cancelled'"
                                      >Cancelled</span
                                    >
                                    <span
                                      class="badge badge-pill bg-warning-light"
                                      v-if="item.status == 'Pending'"
                                      >Pending</span
                                    >
                                  </td>
                                  <td class="text-right">
                                    <div class="table-action">
                                      <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-primary-light mr-1"
                                      >
                                        <i class="fas fa-print"></i> Print
                                      </a>
                                      <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-info-light"
                                      >
                                        View Details
                                        <i class="fa fa-angle-right"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Appointment Tab -->
                  </div>
                  <!-- Tab Content -->
                </div>
              </div>
              <!-- web view table close -->

              <!-- Mobile View -->
              <div class="prescription-card" v-if="prescriptions.length > 0">
                <div
                  class="card shadow-sm bg-white rounded mb-3"
                  style="min-width: 24rem"
                  v-for="(prescription, i) in prescriptions"
                  :key="i"
                >
                  <div class="card-header bg-transparent row">
                    <div class="col-7">
                      Presc. No. :
                      <strong>#{{ prescription.prescriptionNumber }}</strong>
                    </div>
                    <div class="col-5">
                      <a
                        v-if="prescription.is_payment_completed === true"
                        href="#prescription-details-modal"
                        data-toggle="modal"
                        class="btn btn-sm bg-info-light mr-2"
                        @click="setPrescriptionDetails(prescription)"
                      >
                        <i class="far fa-eye"></i> View
                      </a>
                      <a
                        v-if="prescription.is_payment_completed === false"
                        href="javascript:void();"
                        @click="
                          $router.push(
                            `/ patient / confirmation / ${prescription.id} `
                          )
                        "
                        class="btn btn-sm bg-primary-light"
                      >
                        Confirm & Pay Service Fees
                      </a>
                    </div>
                  </div>
                  <div class="card-body text-secondary p-2">
                    <p>
                      Date:
                      {{ new Date(prescription.created_at).toDateString() }}
                    </p>
                    <p
                      v-if="
                        (prescription.provider &&
                          prescription.status === 'InProgress') ||
                        prescription.status === 'Accepted' ||
                        prescription.status === 'Completed' ||
                        prescription.status === 'Cancelled'
                      "
                    >
                      <a href="javascript:void();" v-if="prescription.provider"
                        >Provider Name:
                        <strong
                          >{{ prescription.provider.first_name }}
                          {{ prescription.provider.last_name }}</strong
                        >
                      </a>
                    </p>
                    <p v-else>
                      <a href="javascript:void();"
                        ><strong
                          >Not Assigned Yet
                          <span>Waiting for providers...</span></strong
                        >
                      </a>
                    </p>

                    <p>
                      Status:
                      <span
                        v-if="
                          prescription.status === 'Accepted' &&
                          prescription.is_payment_completed
                        "
                        :class="getStatusColor(prescription.status)"
                      >
                        Provider Approval Pending
                      </span>
                      <span v-else :class="getStatusColor(prescription.status)">
                        {{ pharmacyStatus(prescription.status) }}
                      </span>
                      <a
                        v-if="
                          !prescription.is_payment_completed &&
                          prescription.status === 'Accepted'
                        "
                        href="javascript:void();"
                        @click="
                          $router.push(`/patient/billing/${prescription.id}`)
                        "
                        class="btn btn-sm bg-primary-light ml-5"
                      >
                        Pay Provider Fees
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <!-- Mobile View close -->
            </div>
          </div>
        </div>
      </div>

      <!-- /Page Content -->
      <layout-footer></layout-footer>
    </div>

    <!-- Prescription Details Modal -->
    <!-- <div
      class="modal fade"
      id="prescription-details-modal"
      style="display: none"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <PrescriptionDetails :prescription="selectedPrescription" />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, HTTP_API } from "../../constants/config";
import { mapActions } from "vuex";
import PrescriptionDetails from "./PrescriptionDetails.vue";
const images = require.context(
  "../../assets/img/doctors",
  false,
  /\.png$|\.jpg$/
);
export default {
  components: {
    PrescriptionDetails: PrescriptionDetails,
  },
  data: function () {
    return {
      appointments: [],
      prescriptions: [],
      selectedPharmacy: {},
      selectedMedicines: {},
      patientInformation: {},
      healthInformation: {},
      prescriptionMetaInfo: {},
      selectedPrescription: {},
    };
  },
  computed: {
    freeTextPrescriptions() {
      return this.prescriptions.filter((t) => t.type === "free");
    },
    refillPrescriptions() {
      return this.prescriptions.filter((t) => t.type === "refill");
    },
  },
  async mounted() {
    this.getPrescriptions();
    this.$socket.emit("GET:PATIENT_PRESCRIPTIONS", {
      patientId: this.$store.getters.user.id,
    });
  },
  methods: {
    ...mapActions(["showLoader"]),
    setPrescriptionDetails(prescriptionDetails) {
      this.selectedPrescription = prescriptionDetails;
    },

    getStatusColor(status) {
      switch (status) {
        case "Awaiting":
          return "btn btn-sm bg-warning-light mr-1";

        case "InProgress":
          return "btn btn-sm bg-primary-light mr-1";

        case "Completed":
          return "btn btn-sm bg-success-light mr-1";

        case "Accepted":
          return "btn btn-sm bg-info-light mr-1";

        case "Cancelled":
          return "btn btn-sm bg-danger-light mr-1";

        default:
          return "btn btn-sm bg-primary-light mr-1";
      }
    },
    pharmacyStatus(status) {
      switch (status) {
        case "Sent":
          return "Sent To Pharmacy";

        case "PharmacyVerified":
          return "Pharmacy Verified";
      }

      return status;
    },

    async getPrescriptions() {
      try {
        this.showLoader(true);
        const res = await axios.get(
          `${BASE_URL + HTTP_API.patientGetPrescriptions}/${
            this.$store.getters.user.id
          }`
        );
        this.prescriptions = res.data;
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
      }
    },
    loadImg(imgPath) {
      return images("./" + imgPath).default;
    },
  },
};
</script>
<style scoped>
.card-btn {
  cursor: pointer;
}

.card-btn:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 600px) {
  .prescriptionTable {
    display: none;
  }

  .card {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .prescription-card {
    display: none;
  }
}
</style>
