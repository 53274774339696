<template>
  <section class="section section-search">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="banner-wrapper">
            <div class="banner-header text-center">
              <h2>Patient Information</h2>
            </div>
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="confirmProceed">
                  <div class="row">
                    <div class="order-1 col-md-6 order-md-1">
                      <div class="accordion" id="prescription-accordion">
                        <div class="card mb-0">
                          <div class="card-header" id="patient-info-accordion">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#patient-info-collapse"
                                aria-expanded="false"
                                aria-controls="patient-info-collapse"
                              >
                                <i class="fas fa-user mr-1"></i> Personal
                                Details
                              </button>
                            </h2>
                          </div>
                          <div
                            id="patient-info-collapse"
                            class="collapse"
                            aria-labelledby="patient-info-accordion"
                            data-parent="#prescription-accordion"
                            style="font-size: 12px"
                          >
                            <div class="card-body">
                              <p>Name: {{ firstName }} {{ lastName }}</p>
                              <p>Email: {{ $store.getters.user.email }}</p>
                              <p>
                                Mobile:
                                {{ $store.getters.user.primary_phone }}
                              </p>
                              <p>Gender: {{ gender }}</p>
                              <p>Height: {{ height }} IN</p>
                              <p>Weight: {{ weight }} LB</p>
                              <p>DOB: {{ dob }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="card mb-0">
                          <div
                            class="card-header"
                            id="patient-address-accordion"
                          >
                            <h2 class="mb-0">
                              <button
                                class="
                                  btn btn-link btn-block
                                  text-left
                                  collapsed
                                "
                                type="button"
                                data-toggle="collapse"
                                data-target="#patient-address-collapse"
                                aria-expanded="false"
                                aria-controls="patient-address-collapse"
                              >
                                <i class="fas fa-hospital mr-1"></i> Address
                                Details
                              </button>
                            </h2>
                          </div>
                          <div
                            id="patient-address-collapse"
                            class="collapse"
                            aria-labelledby="patient-address-accordion"
                            data-parent="#prescription-accordion"
                            style="font-size: 12px"
                          >
                            <div class="card-body">
                              <p>Address Line 1: {{ addressLine1 }}</p>
                              <p>Address Line 2: {{ addressLine2 }}</p>
                              <p>City: {{ city }}</p>
                              <p>State: {{ state }}</p>
                              <p>Zip Code: {{ zipCode }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="order-2 col-md-6 order-md-2">
                      <div class="row">
                        <div class="order-1 col-md-12 order-md-1">
                          <div class="form-group text-left">
                            <label>Current Medications (If Any)</label>
                            <textarea
                              name="past"
                              class="form-control"
                              v-model="currentMedications"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="order-1 col-md-12 order-md-1">
                          <div class="form-group text-left">
                            <label>Known Allergies (If Any)</label>
                            <textarea
                              name="past"
                              class="form-control"
                              v-model="knownAllergies"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group text-left">
                            <label>Reason for Medication (Required)</label>
                            <textarea
                              name="past"
                              class="form-control"
                              v-model="reasonForMedication"
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <!-- <div class="col-12 col-lg-3 order-2">
                      <button class="btn btn-primary btn-block mb-2">
                        <i class="fa fa-arrow-left"></i> Back
                      </button>
                    </div> -->
                    <div class="col-12 col-lg-12 order-1">
                      <button type="submit" class="btn btn-info btn-block mb-2">
                        Confirm & Proceed <i class="fa fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      height: "",
      weight: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      reasonForMedication: "",
      currentMedications: "",
      knownAllergies: "",
    };
  },
  async mounted() {
    this.showLoader(true);

    // Personal details
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.gender = this.$store.getters.user.gender;
    this.dob = this.$store.getters.user.dob;
    this.height = this.$store.getters.user.height;
    this.weight = this.$store.getters.user.weight;

    // Address parsing
    this.addressLine1 = this.$store.getters.user.address1;
    this.addressLine2 = this.$store.getters.user.address2;
    this.zipCode = this.$store.getters.user.zip_code;
    this.state = this.$store.getters.user.state;
    this.city = this.$store.getters.user.city;

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["showLoader"]),
    confirmProceed() {
      this.$emit(
        "select",
        JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          gender: this.gender,
          dob: this.dob,
          email: this.$store.getters.user.email,
          mobileNumber: this.$store.getters.user.primary_phone,
          address: {
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            state: this.state,
            city: this.city,
            zipCode: this.zipCode,
          },
          currentMedications: this.currentMedications,
          knownAllergies: this.knownAllergies,
          reasonForMedication: this.reasonForMedication,
        })
      );
    },
  },
};
</script>
<style scoped>
.section-search {
  min-height: 600px;
}

.search-box .search-info {
  flex: 0 0 400px;
  width: 450px;
}
</style>