<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb39></breadcrumb39>
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="terms-content">
              <div class="terms-text">
                <p>
                  {{ $t("privacyPolicy.description") }}
                </p>
              </div>
              <div class="terms-text">
                <h4>{{ $t("privacyPolicy.informationCollected.title") }}</h4>
                <p>
                  {{ $t("privacyPolicy.informationCollected.description1") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.informationCollected.description2") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.informationCollected.description3") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.informationCollected.description4") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.informationCollected.description5") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.informationCollected.description6") }}
                </p>
              </div>
              <div class="terms-text">
                <h4>
                  {{
                    $t("privacyPolicy.utilityOfTheInformationWeGather.title")
                  }}
                </h4>
                <p>
                  {{
                    $t(
                      "privacyPolicy.utilityOfTheInformationWeGather.description1"
                    )
                  }}
                </p>
                <ul>
                  <li>
                    {{
                      $t("privacyPolicy.utilityOfTheInformationWeGather.point1")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.utilityOfTheInformationWeGather.point2")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.utilityOfTheInformationWeGather.point3")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.utilityOfTheInformationWeGather.point4")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.utilityOfTheInformationWeGather.point5")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.utilityOfTheInformationWeGather.point6")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.utilityOfTheInformationWeGather.point7")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.utilityOfTheInformationWeGather.point8")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.utilityOfTheInformationWeGather.point9")
                    }}
                  </li>
                </ul>
                <p>
                  {{
                    $t(
                      "privacyPolicy.utilityOfTheInformationWeGather.description2"
                    )
                  }}
                </p>
              </div>
              <div class="terms-text">
                <h4>
                  {{ $t("privacyPolicy.sharingOfPersonalInformation.title") }}
                </h4>
                <p>
                  {{
                    $t(
                      "privacyPolicy.sharingOfPersonalInformation.description1"
                    )
                  }}
                </p>
                <ul>
                  <li>
                    {{
                      $t("privacyPolicy.sharingOfPersonalInformation.point1")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.sharingOfPersonalInformation.point2")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.sharingOfPersonalInformation.point3")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.sharingOfPersonalInformation.point4")
                    }}
                  </li>
                  <li>
                    {{
                      $t("privacyPolicy.sharingOfPersonalInformation.point5")
                    }}
                  </li>
                </ul>
                <p>
                  {{
                    $t(
                      "privacyPolicy.sharingOfPersonalInformation.description2"
                    )
                  }}
                </p>
              </div>
              <div class="terms-text">
                <h4>
                  {{
                    $t("privacyPolicy.securityOfYourPersonalInformation.title")
                  }}
                </h4>
                <p>
                  {{
                    $t(
                      "privacyPolicy.securityOfYourPersonalInformation.description"
                    )
                  }}
                </p>
              </div>
              <div class="terms-text">
                <h4>
                  {{
                    $t(
                      "privacyPolicy.yourInformationPreferencesAndChanges.title"
                    )
                  }}
                </h4>
                <p>
                  {{
                    $t(
                      "privacyPolicy.yourInformationPreferencesAndChanges.description1"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.yourInformationPreferencesAndChanges.description2"
                    )
                  }}
                </p>
              </div>
              <div class="terms-text">
                <h4>{{ $t("privacyPolicy.privacyNotice.title") }}</h4>
                <p>
                  {{ $t("privacyPolicy.privacyNotice.description") }}
                </p>
              </div>
              <div class="terms-text">
                <h4>
                  {{
                    $t(
                      "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.title"
                    )
                  }}
                </h4>
                <p>
                  {{
                    $t(
                      "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.description1"
                    )
                  }}
                </p>
                <ul>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point1"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point2"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point3"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point4"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point5"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point6"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point7"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point8"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point9"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point10"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point11"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point12"
                      )
                    }}
                  </li>
                </ul>
                <p>
                  {{
                    $t(
                      "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.description2"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.description3"
                    )
                  }}
                </p>
                <ul>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point13"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point14"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.point15"
                      )
                    }}
                  </li>
                </ul>
                <p>
                  {{
                    $t(
                      "privacyPolicy.useAndDisclosureOfYourPersonalHealthData.description4"
                    )
                  }}
                </p>
              </div>
              <div class="terms-text">
                <h4>{{ $t("privacyPolicy.communication.title") }}</h4>
                <p>
                  {{ $t("privacyPolicy.communication.description1") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.communication.description2") }}
                </p>
                <ul>
                  <li>
                    {{ $t("privacyPolicy.communication.point1") }}
                  </li>
                  <li>
                    {{ $t("privacyPolicy.communication.point2") }}
                  </li>
                  <li>
                    {{ $t("privacyPolicy.communication.point3") }}
                  </li>
                  <li>
                    {{ $t("privacyPolicy.communication.point4") }}
                  </li>
                  <li>
                    {{ $t("privacyPolicy.communication.point5") }}
                  </li>
                </ul>
              </div>
              <div class="terms-text">
                <h4>{{ $t("privacyPolicy.expiration.title") }}</h4>
                <p>
                  {{ $t("privacyPolicy.expiration.description1") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.expiration.description2") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.expiration.description3") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.expiration.description4") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.expiration.description5") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.expiration.description6") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.expiration.description7") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.expiration.description8") }}
                </p>
                <p>
                  {{ $t("privacyPolicy.expiration.description9") }}
                </p>
              </div>
              <div class="terms-text">
                <h4>{{ $t("privacyPolicy.complaints.title") }}</h4>
                <p>
                  {{ $t("privacyPolicy.complaints.description") }}
                </p>
              </div>
              <div class="terms-text">
                <h4>{{ $t("privacyPolicy.refusal.title") }}</h4>
                <p>
                  {{ $t("privacyPolicy.refusal.description") }}
                </p>
              </div>
              <div class="terms-text">
                <h4>{{ $t("privacyPolicy.policyChanges.title") }}</h4>
                <p>
                  {{ $t("privacyPolicy.policyChanges.description") }}
                </p>
              </div>
              <div class="terms-text">
                <h4>{{ $t("privacyPolicy.question.title") }}</h4>
                <p>{{ $t("privacyPolicy.question.description1") }}</p>
                <br />
                <p>{{ $t("privacyPolicy.question.description2") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>
