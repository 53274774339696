<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <img src="../../assets/img/logo.png" alt="" width="300" />
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/patient/step-1" class="active-done">1</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-2" class="active-done">2</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-3" class="active-done">3</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-4" class="active-done">4</a>
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">5</a>
                        </li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <h3 class="my-4">Pharmacy Details</h3>

                      <!-- Select Pharmacy -->
                      <label>Select Pharmacy (Based on your address)</label>
                      <vue-select class="form-control w-100" v-model="pharmacy" :options="pharmacyResults"
                        label-by="pharmacyName" searchable clear-on-close close-on-select clear-on-select
                        :loading="loading" @search:input="startSearchingPharmacy" @search:change="resetPharmacyFilter()"
                        placeholder="Select Pharmacy" :disabled="pharmacyResults.length === 0"></vue-select>
                      <div class="text-center mt-3">
                        <h3>OR</h3>
                      </div>
                      <!-- Search Pharmacy -->
                      <div class="form-group mt-2">
                        <label>Cannot see your pharmacy in the list? </label>
                        <button data-toggle="modal" data-target=".bd-example-modal-xl" type="button"
                          class="btn btn-warning mt-0 btn-block" @click="pharmacy = null">
                          <i class="fas fa-search mr-2"></i>
                          <span>Search Pharmacy</span>
                        </button>
                      </div>
                      <hr v-if="pharmacy && pharmacy.pharmacyName" />
                      <div v-if="pharmacy && pharmacy.pharmacyName">
                        <h5>Pharmacy Details:</h5>
                        <p>Name: {{ pharmacy.pharmacyName }}</p>
                        <p>Address: {{ getStoreAddress(pharmacy.address) }}</p>
                        <p>Phone: {{ pharmacy.phone }}</p>
                      </div>
                      <hr />
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-6">
                            <a href="javascript:void()" class="btn btn-primary btn-block btn-lg login-btn"
                              @click="goBack">
                              Back
                            </a>
                          </div>
                          <div class="col-6">
                            <button type="submit" class="btn btn-primary btn-block btn-lg login-btn step1_submit"
                              :disabled="!pharmacy">
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span>© 2023. StepAhead Health Inc. All Rights Reserved.</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>

    <!-- Select Pharmacy Modal -->
    <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search Pharmacy Near You</h5>
            <button type="button" class="btn btn-info" data-dismiss="modal" id="close">
              Close
            </button>
          </div>

          <div class="modal-body">
            <ChoosePharmacy @select="selectPharmacy" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import common from "../../mixins/common";
import Swal from "sweetalert2";
import ChoosePharmacy from "./ChoosePharmacy.vue";
const images = require.context(
  "../../assets/img/stores/",
  false,
  /\.png$|\.jpg$/
);

export default {
  mixins: [common],
  components: {
    ChoosePharmacy,
  },
  data () {
    return {
      pharmacy: null,
      searchName: "",
      searchCity: "",
      searchState: "",
      searchZipCode: "",
      searchSpecialty: "",
      loading: false,
      pharmacyResults: [],
      filteredPharmacyResults: [],
    };
  },
  async mounted () {
    this.showLoader(true);
    // console.log(this.$store.getters.user.pharmacy);
    this.pharmacy = this.$store.getters.user.pharmacy;
    this.searchCity = this.$store.getters.user.city;
    this.searchState = this.$store.getters.user.state;
    this.searchZipCode = this.$store.getters.user.zip_code;
    if (this.searchCity && this.searchState && this.searchZipCode) {
      await this.startSearching();
    }

    this.showLoader(false);
  },
  methods: {
    goBack () {
      this.$router.push("/patient/step-4");
    },
    ...mapActions(["saveUserDetails", "showLoader"]),

    async saveStep () {
      try {
        this.showLoader(true);
        if (!this.pharmacy) {
          Swal.fire("Select a pharmacy", "", "error");
          return;
        }

        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          current_registration_step: 6,
          pharmacy: this.pharmacy,
        });

        this.pharmacy = null;
        this.$router.push("/patient/step-6");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        console.error(e);
      }
    },

    restartSearch () {
      this.searchName = "";
      this.searchCity = "";
      this.searchState = "";
      this.searchZipCode = "";
      this.pharmacyResults = [];
      this.filteredPharmacyResults = [];
    },
    async startSearching () {
      try {
        if (
          String(this.searchZipCode).trim().length === 0 &&
          String(this.searchCity).trim().length === 0 &&
          String(this.searchState).trim().length === 0 &&
          String(this.searchName).trim().length === 0 &&
          String(this.searchSpecialty).trim().length === 0
        ) {
          Swal.fire(
            "Oops!",
            "Please enter a name, city, state, zip or specialty to find the pharmacies",
            "error"
          );
          return;
        }

        this.showLoader(true);

        let query = "?";
        if (String(this.searchName).trim().length > 0)
          query += `name=${this.searchName}&`;
        if (String(this.searchCity).trim().length > 0)
          query += `city=${this.searchCity}&`;
        if (String(this.searchState).trim().length > 0)
          query += `state=${this.searchState}&`;
        if (String(this.searchZipCode).trim().length > 0)
          query += `zip=${this.searchZipCode}&`;
        if (String(this.searchSpecialty).trim().length > 0)
          query += `specialty[0]=${this.searchSpecialty}&`;

        query = query.slice(0, -1);

        const results = await this.searchPharmacy(query);

        for (let each of results) {
          this.pharmacyResults.push({
            pharmacyId: each.PharmacyId,
            pharmacyName: each.StoreName,
            address: {
              addressLine1: each.Address1,
              addressLine2: each.Address2,
              city: each.City,
              state: each.State,
              zipCode: each.ZipCode,
            },
            phone: each.PrimaryPhone,
          });
        }

        if (this.pharmacyResults.length === 0) {
          Swal.fire(
            "No Pharmacies Found",
            "We did not find any pharmacies in your search criteria.",
            "warning"
          );
        }

        this.filteredPharmacyResults = this.pharmacyResults;
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire("Ooops!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    startSearchingPharmacy (input) {
      try {
        if (
          input.target &&
          input.target.value &&
          input.target.value.length > 3
        ) {
          this.loading = true;

          this.filteredPharmacyResults = this.pharmacyResults.filter((s) =>
            String(s.name).toLowerCase().includes(input.target.value)
          );

          this.loading = false;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    selectPharmacy (pharmacyDetails) {
      try {
        this.pharmacy = JSON.parse(pharmacyDetails);
        document.getElementById("close").click();
      } catch (e) { }
    },

    getStoreAddress (addressObject) {
      return `${addressObject.addressLine1}, ${addressObject.addressLine2}, ${addressObject.city}, ${addressObject.state} - ${addressObject.zipCode}`;
    },

    resetPharmacyFilter () {
      this.filteredPharmacyResults = this.pharmacyResults;
    },

    getImage (storeBrand) {
      switch (String(storeBrand).toLowerCase()) {
        case "walgreens":
          return images("./walgreens.jpg").default;

        default:
          return images("./notavailable.png").default;
      }
    },
  },
};
</script>
