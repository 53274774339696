<template>
  <div class="card" v-if="medicine">
    <div class="card-header">
      <div class="row">
        <div class="col-8">
          <h5>
            Prescription ID:
            {{
              medicine.dsp_prescription_id
                ? medicine.dsp_prescription_id
                : "Not Available Yet"
            }}
          </h5>
          <h6>
            Created Date: {{ new Date(medicine.created_at).toLocaleString() }}
          </h6>
        </div>
        <!-- <div class="col-4 text-right">
          <button
            v-if="medicine.dsp_prescription_id"
            class="btn btn-sm bg-info-light mr-2"
            @click="
              setPrintStatus(
                prescriptionDetails.patient.dsp_user_id,
                medicine.dsp_prescription_id
              )
            "
          >
            <i class="fa fa-print"></i> Print
          </button>
          <button
            v-if="medicine.dsp_prescription_id"
            class="btn btn-sm bg-warning"
            @click="reconcilePrescription(medicine.prescription_id)"
          >
            <i class="fa fa-refresh"></i> Reconcile
          </button>
        </div> -->
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <div class="form-group text-left">
            <label
              ><strong>{{
                $t(
                  "provider.prescriptionDetails.medications.medicines.quantity"
                )
              }}</strong></label
            >
            <input
              type="number"
              class="form-control form-control-xs"
              v-model="medicine.quantity"
              min="1"
              max="5000"
              step="any"
              :disabled="true"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group text-left">
            <label
              ><strong>{{
                $t("provider.prescriptionDetails.medications.medicines.unit")
              }}</strong></label
            >
            <select
              class="form-control select form-control-xs"
              tabindex="-1"
              aria-hidden="true"
              v-model="medicine.unit_id"
              required
              :disabled="true"
            >
              <option
                v-for="(unit, i) in units"
                :key="i"
                :value="unit.StandardDispenseUnitTypeID"
              >
                {{ unit.Plural }} ({{ unit.Abbreviation }})
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group text-left">
            <label
              ><strong>{{
                $t(
                  "provider.prescriptionDetails.medications.medicines.refillsRequested"
                )
              }}</strong></label
            >
            <input
              type="number"
              class="form-control form-control-xs"
              v-model="medicine.refills"
              min="0"
              max="99"
              :disabled="true"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group text-left">
            <label
              ><strong>{{
                $t(
                  "provider.prescriptionDetails.medications.medicines.daysSupply"
                )
              }}</strong></label
            >
            <input
              type="number"
              class="form-control form-control-xs"
              v-model="medicine.supply_days"
              min="1"
              max="999"
              :disabled="true"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group text-left">
            <label
              ><strong>{{
                $t(
                  "provider.prescriptionDetails.medications.medicines.description"
                )
              }}</strong></label
            >
            <textarea
              class="form-control form-control-xs"
              @keydown="
                () => {
                  isEditing = true;
                }
              "
              v-model="medicine.directions"
              placeholder="Directions of use"
              :disabled="true"
            ></textarea>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group text-left">
            <label
              ><strong>{{
                $t(
                  "provider.prescriptionDetails.medications.medicines.pharmacyNotes"
                )
              }}</strong></label
            >
            <textarea
              class="form-control form-control-xs"
              @keydown="
                () => {
                  isEditing = true;
                }
              "
              v-model="medicine.pharmacy_note"
              placeholder="Notes to Pharmacy"
              :disabled="true"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row" v-if="isEditing">
        <div class="col-12">
          <button
            @click="savePrescriptionDetails"
            class="btn btn-sm bg-info-light btn-block"
          >
            {{
              $t(
                "provider.prescriptionDetails.medications.medicines.savePrescription"
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../../constants/config";
import { mapActions } from "vuex";

export default {
  props: {
    prescriptionDetails: Object,
    medicine: Object,
    units: Array,
  },
  watch: {
    prescriptionDetails: function (old, newVal) {
      // console.log(this.prescriptionDetails);
    },
    medicine: function (old, newVal) {
      // console.log(this.medicine);
    },
  },
  data() {},
  methods: {
    ...mapActions(["showLoader"]),

    async reconcilePrescription(prescriptionId) {
      try {
        Swal.fire({
          icon: "info",
          titleText: "Reconcile Confirmation",
          text: `Are you sure you want to reconcile this prescription?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          customClass: {
            confirmButton: "bg-success-light",
            denyButton: "bg-danger-light",
          },
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.showLoader(true);
              await axios.post(
                `${config.BASE_URL}${config.HTTP_API.providerReconcilePrescription}`,
                {
                  prescriptionId: prescriptionId,
                }
              );

              Swal.fire(
                "Prescription Reconciled!",
                "Prescription has been reconciled successfully",
                "success"
              ).then(() => {
                this.$router.go();
              });
              this.showLoader(false);
            }
          })
          .catch((e) => {
            console.error(e);
            Swal.fire("Reconcile Failed!", e.response.data.message, "error");
            this.showLoader(false);
          });
      } catch (e) {
        console.error(e);
        Swal.fire("Reconcile Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async setPrintStatus(dspPatientId, prescriptionId) {
      Swal.fire({
        icon: "info",
        titleText: "Print Confirmation",
        text: `Do you want to set the prescription to printed?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success-light",
          denyButton: "bg-danger-light",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // SEND REFILL DENIAL
          try {
            this.showLoader(true);
            await axios.post(
              `${config.BASE_URL}${config.HTTP_API.providerPrintPrescription}`,
              {
                prescriptionId: prescriptionId,
                dspPatientId: dspPatientId,
              }
            );

            Swal.fire(
              "Status Updated",
              "Prescription status has been set to printed",
              "success"
            ).then(() => {
              this.$router.go();
            });

            this.showLoader(false);
          } catch (e) {
            console.error(e);
            Swal.fire("Print Failed!", e.response.data.message, "error");
            this.showLoader(false);
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
