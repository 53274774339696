<template>
    <!-- Incoming Call -->
    <div class="call-box incoming-box">
        <div class="call-wrapper">
            <div class="call-inner">
                <div class="call-user">
                    <!-- <video id="preview-video" class="video-poster" autoplay /> -->
                    <div class="text-center">
                        <h3>Incoming Call</h3>
                        <div class="call-content-wrap">
                            <div class="voice-call-avatar">
                                <img :src="userImage" alt="User Image" class="call-avatar">
                                <span class="username">{{ userName }}</span>
                                <span class="text-muted">Prescription ID: {{ prescriptionId }}</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="text-left">
                        <div class="row">
                            <div class="col-9">
                                <div class="form-group">
                                    <label>Camera</label>
                                    <vue-select class="form-control w-100" v-model="selectedDefaultCamera"
                                        :options="cameraDevices" placeholder="Select Camera" label-by="label"
                                        @selected="changeCamera()" close-on-select
                                        :disabled="cameraDevices.length === 0 || !isCameraEnabled"></vue-select>
                                </div>
                            </div>
                            <div class="col-3 text-center">
                                <button v-if="callType === 'video'" @click="ToggleCamera"
                                    :class="`mt-3 btn meet-control-btn ${isCameraEnabled ? 'btn-secondary' : 'btn-danger'}`">
                                    <i v-if="isCameraEnabled" class="material-icons">videocam</i>
                                    <i v-else class="material-icons">videocam_off</i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="text-left">
                        <div class="row">
                            <div class="col-9">
                                <div class="form-group">
                                    <label>Microphone</label>
                                    <vue-select class="form-control w-100" v-model="selectedDefaultMicrophone"
                                        :options="microphoneDevices" placeholder="Select Microphone" label-by="label"
                                        close-on-select @selected="changeMicrophone()"
                                        :disabled="microphoneDevices.length === 0 || !isMicrophoneEnabled"></vue-select>
                                </div>
                            </div>
                            <div class="col-3 text-center">
                                <button @click="ToggleMicrophone"
                                    :class="`mt-3 btn meet-control-btn ${isMicrophoneEnabled ? 'btn-secondary' : 'btn-danger'}`">
                                    <i v-if="isMicrophoneEnabled" class="material-icons">mic</i>
                                    <i v-else class="material-icons">mic_off</i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="text-left">
                        <div class="form-group">
                            <label>Audio Output</label>
                            <vue-select class="form-control w-100" v-model="selectedDefaultSpeaker"
                                :options="speakerDevices" placeholder="Select Audio Output" label-by="label"
                                @selected="changeSpeaker()" close-on-select
                                :disabled="speakerDevices.length === 0"></vue-select>
                        </div>
                    </div>
                </div>
                <!-- <img class="call-avatar" src="../../assets/img/doctors/doctor-thumb-02.jpg" alt="User Image">
                    <h4>Darren Elder</h4>
                    <span>Calling ...</span> -->
            </div>
            <div class="row">
                <div class="col-6">
                    <button @click="declineCall" type="button" class="btn bg-danger text-light w-100 h-100">
                        <i class="fa fa-close mr-2"></i>
                        <span>Decline</span>
                    </button>
                </div>
                <div class="col-6">
                    <button @click="acceptCall" type="button" class="btn bg-success text-light w-100 h-100">
                        <i class="fa fa-phone mr-2"></i>
                        <span>Accept</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- /Incoming Call -->
</template>
<script>
import { mapActions } from 'vuex'
import Swal from 'sweetalert2';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from 'axios'
import config from '../constants/config'
import ringtoneSound from '../assets/sounds/call.mp3'

export default {
    props: {
        prescriptionId: String,
        userName: String,
        userImage: String,
        callType: String,
        requestId: Number
    },
    data () {
        return {

            storage: {},
            isCameraEnabled: true,
            isMicrophoneEnabled: true,

            // Default hardware devices
            selectedDefaultCamera: "default",
            selectedDefaultMicrophone: "default",
            selectedDefaultSpeaker: "default",
            cameraDevices: [],
            microphoneDevices: [],
            speakerDevices: [],

            callId: '',
            isSessionHost: false,
            meetingName: '',
            isCallRequested: false,
            ringtone: new Audio(ringtoneSound)
        };
    },
    computed: {
        connectionId () {
            return this.$store.getters.connectionId
        }
    },
    mounted () {
        this.initiateCalling()
    },
    methods: {
        ...mapActions(['showLoader']),
        async declineCall () {
            this.$meet.LeaveMeeting();
            if (this.requestId) this.cancelCallRequest()
            $('#incoming_call').modal('hide');
            this.ringtone.pause()
        },
        async initiateCalling () {

            // Get meeting storage
            this.storage = this.$meet.getMeetingStore();

            // Get available hardware devices
            await this.LoadHardwareDevices();

            // Get meeting code
            const res = await axios.post(`${config.BASE_URL}${config.HTTP_API.getCallDetailsByPrescId}`, {
                prescriptionId: this.prescriptionId,
                userId: this.$store.getters.user.id
            })

            this.callId = res.data.id
            this.isSessionHost = res.data.isSessionHost
            this.meetingName = res.data.meetingName

            $('#incoming_call').modal('show');
            this.ringtone.play();

            // Init camera preview
            if (this.callType === 'video') {
                await this.$meet.StartCameraPreview({
                    videoTagId: "preview-video",
                    deviceId: this.selectedDefaultCamera.deviceId
                });

                this.isCameraEnabled = true

            } else {
                this.$meet.StopCameraPreview()
                this.isCameraEnabled = false
            }

            // Init microphone preview
            await this.$meet.StartMicrophonePreview({
                deviceId: this.selectedDefaultMicrophone.deviceId
            });

            this.showLoader(false)
        },

        async cancelCallRequest () {
            try {
                this.showLoader(true)

                this.$socket.emit('REQUEST', {
                    command: 'MEET:DECLINE_CALL',
                    userId: this.$store.getters.user.id,
                    requestId: this.requestId,
                    callId: this.callId
                })

                this.isCallRequested = false
                this.showLoader(false)
            } catch (e) {
                console.error(e.message)
                this.ringtone.pause();
                $('#incoming_call').modal('hide');
                Swal.fire('Call Failed!', e.message, 'error')
                this.showLoader(false)
                this.isCallRequested = false
            }
        },

        async acceptCall () {
            try {
                this.isCallRequested = true

                this.$socket.emit('REQUEST', {
                    command: 'MEET:ACCEPT_CALL',
                    userId: this.$store.getters.user.id,
                    requestId: this.requestId,
                    callId: this.callId
                })

                this.ringtone.pause();
                $('#incoming_call').modal('hide');
                this.$router.push(`/${this.$store.getters.user.role}/call/${this.requestId}/${this.callId}`);
                this.isCallRequested = false

            } catch (e) {
                this.ringtone.pause();
                console.error(e.message)
                this.isCallRequested = false
                $('#incoming_call').modal('hide');
                Swal.fire('Call Failed!', e.message, 'error')
            }
        },

        // Toggle function on checkbox button
        async ToggleCamera () {
            this.isCameraEnabled ? this.$meet.StopCameraPreview() : this.$meet.StartCameraPreview(this.selectedDefaultCamera)
            this.isCameraEnabled = !this.isCameraEnabled
        },

        // Toggle function on checkbox button
        async ToggleMicrophone () {
            this.isMicrophoneEnabled ? this.$meet.StopMicrophonePreview() : this.$meet.StartMicrophonePreview(this.selectedDefaultMicrophone)
            this.isMicrophoneEnabled = !this.isMicrophoneEnabled
        },

        async changeCamera () {
            try {
                await this.$meet.SetDefaultCamera(this.selectedDefaultCamera.deviceId);
            } catch (e) {
                toast.error('Failed to change camera', {
                    duration: 2000,
                    position: "top-right",
                    theme: "auto",
                });
            }
        },

        async changeMicrophone () {
            try {
                await this.$meet.SetDefaultMicrophone(this.selectedDefaultMicrophone.deviceId);
            } catch (e) {
                toast.error('Failed to change microphone', {
                    duration: 2000,
                    position: "top-right",
                    theme: "auto",
                });
            }
        },

        async changeSpeaker () {
            try {
                await this.$meet.SetDefaultSpeaker(this.selectedDefaultSpeaker.deviceId);
            } catch (e) {
                toast.error('Failed to change audio output', {
                    duration: 2000,
                    position: "top-right",
                    theme: "auto",
                });
            }
        },

        async LoadHardwareDevices () {
            this.cameraDevices = [];
            this.microphoneDevices = [];
            this.speakerDevices = [];

            try {

                if (this.callType === 'video') this.cameraDevices = await this.$meet.GetAvailableCameras();
                this.microphoneDevices = await this.$meet.GetAvailableMicrophones();
                this.speakerDevices = await this.$meet.GetAvailableSpeakers();

                if (this.callType === 'video' && this.cameraDevices.length > 0) this.selectedDefaultCamera = this.cameraDevices[0];
                if (this.microphoneDevices.length > 0) this.selectedDefaultMicrophone = this.microphoneDevices[0];
                if (this.speakerDevices.length > 0) this.selectedDefaultSpeaker = this.speakerDevices[0];

            } catch (e) {
                console.log("Load device error : ", e);
            }
        },
    },
};
</script>
<style scoped>
.video-poster {
    width: 100%;
    height: 350px;
    background-repeat: no-repeat;
    background-image: url('../assets/img/meet/user-screen.jpg');
    background-size: cover;
}

.meet-control-btn {
    border-radius: 50% !important;
    height: 60px !important;
    width: 60px !important;
    font-size: 30px !important;
    color: white !important;
}
</style>