<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Account Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">
                    <img src="../assets/img/login-banner.png" class="img-fluid" alt="Login Banner" />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right">
                    <div class="login-header">
                      <h3>{{ $t('forgotPassword.title') }}</h3>
                      <p class="small text-muted">
                        {{ $t('forgotPassword.subTitle') }}
                      </p>
                    </div>

                    <!-- Forgot Password Form -->
                    <form @submit.prevent="sendVerificationLink">
                      <div :class="`form-group form-focus ${email ? 'focused' : ''}`">
                        <input v-model="email" type="email" class="form-control floating" required />
                        <label class="focus-label">Registered Email</label>
                      </div>
                      <button class="btn btn-primary btn-block btn-lg login-btn" type="submit">
                        {{ $t('forgotPassword.resetBtn') }}
                      </button>
                      <div class="text-right">
                        <router-link class="forgot-link mt-3" to="/login">{{
                          $t('forgotPassword.loginLink') }}</router-link>
                      </div>
                    </form>
                    <!-- /Forgot Password Form -->
                  </div>
                </div>
              </div>
              <!-- /Account Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  data () {
    return {
      email: "",
    };
  },
  methods: {
    ...mapActions(["sendForgotPasswordLink", "showLoader"]),
    async sendVerificationLink () {
      try {
        this.showLoader(true);
        await this.sendForgotPasswordLink({ email: this.email });
        this.email = "";
        Swal.fire(
          "Reset Link Sent",
          "Please check your mail box for reset link",
          "success"
        ).then(() => {
          this.$router.push("/login");
        });
        this.showLoader(false);
      } catch (e) {
        Swal.fire("Oops!", e.response.data.message, "error");
      }
    },
  },
};
</script>
