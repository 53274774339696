<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layout-header></layout-header>

      <!-- Page Content -->
      <div class="content bg-white">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Login Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">
                    <img src="../assets/img/login-banner.png" class="img-fluid" alt="Stepahead Register" />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right">
                    <div class="login-header">
                      <h3>{{ $t('loginPage.loginTitle') }}</h3>
                    </div>
                    <hr />
                    <form @submit.prevent="login">
                      <div class="row">
                        <div class="col-12">
                          <div :class="`form-group form-focus ${email ? 'focused' : ''}`">
                            <input type="email" class="form-control floating" v-model="email" required />
                            <label class="focus-label">{{ $t('loginPage.emailPlaceholder') }}</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div :class="`form-group form-focus ${password ? 'focused' : ''}`">
                            <input v-if="showPassword" type="text" v-model="password" class="form-control floating"
                              required />
                            <input v-else type="password" v-model="password" class="form-control floating" required />
                            <label class="focus-label">{{ $t('loginPage.passwordPlaceholder') }}</label>
                            <span class="p-viewer2">
                              <i class="fa" :class="{
                                'fa-eye-slash': showPassword,
                                'fa-eye': !showPassword,
                              }" aria-hidden="true" @click="toggleShow">
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <button type="submit" class="btn btn-primary btn-block btn-lg login-btn">
                        {{ $t('loginPage.loginBtn') }}
                      </button>
                    </form>
                    <!-- <div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">or</span>
                    </div> -->
                    <!-- <div class="row form-row social-login">
                      <div class="col-6">
                        <button
                          disabled
                          class="btn btn-facebook btn-block"
                        ><i class="fab fa-facebook-f mr-1"></i> Facebook</button>
                      </div>
                      <div class="col-6">
                        <button
                          disabled
                          class="btn btn-google btn-block"
                        ><i class="fab fa-google mr-1"></i> Google</button>
                      </div>
                    </div> -->

                    <!-- /Register Form -->
                    <div class="text-center mt-5 dont-have">
                      <div class="row">
                        <div class="col-8 float-left">
                          {{ $t('loginPage.signUpLabel') }}
                          <router-link class="forgot-link" to="/signup">{{ $t('loginPage.signUpLink') }}.</router-link>
                        </div>

                        <div class="col-4 float-right">
                          <router-link class="forgot-link" to="/forgot-password">{{ $t('loginPage.forgotPasswordLink')
                          }}</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Login Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <layout-footer></layout-footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data () {
    return {
      email: "",
      password: "",
      showPassword: "",
    };
  },

  computed: {
    alertState () {
      return this.$store.getters.alert.state;
    },
  },

  mounted () {
    if (this.$store.getters.user) {
      this.routeUser();
    }
  },

  methods: {
    ...mapActions(["Login", "showLoader", "showAlert"]),

    routeUser () {

      this.$socket.emit("REQUEST", {
        command: "SET:USER_ID",
        userId: this.$store.getters.user.id,
      });

      switch (this.$store.getters.user.role) {
        case "patient":
          let routePath1 = `/patient/step-${this.$store.getters.user.current_registration_step}`;
          if (
            !this.$route.path.includes("/patient/step-") &&
            !this.$store.getters.user.is_registration_complete
          ) {
            this.$router.push(routePath1);
          } else {
            this.$router.push("/patient/index");
          }
          break;

        case "provider":
          let routePath2 = `/provider/step-${this.$store.getters.user.current_registration_step}`;
          if (
            !this.$route.path.includes("/provider/step-") &&
            !this.$store.getters.user.is_registration_complete
          ) {
            this.$router.push(routePath2);
          } else {
            this.$router.push("/provider/index");
          }
          break;
      }
    },

    async login () {
      try {
        const body = {
          email: this.email,
          password: this.password,
        };

        await this.Login(body);

        setTimeout(() => {
          this.showLoader(false);
        }, 1000);

        this.routeUser();
        this.email = "";
        this.password = "";
      } catch (e) {
        Swal.fire("Ooops!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },
    toggleShow () {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style>
.p-viewer2 {
  float: right;
  margin-top: -35px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>