export default {
    "video": true,
    "audio": {
        "echoCancellation": true,
        "noiseSuppression": true,
        "autoGainControl": false,
        "googEchoCancellation": true,
        "googAutoGainControl": false,
        "googExperimentalAutoGainControl": false,
        "googNoiseSuppression": true,
        "googExperimentalNoiseSuppression": true,
        "googHighpassFilter": true,
        "googTypingNoiseDetection": true,
        "googBeamforming": false,
        "googArrayGeometry": false,
        "googAudioMirroring": false,
        "googNoiseReduction": true,
        "mozNoiseSuppression": true,
        "mozAutoGainControl": false
    },
    "videoSimulcastEncodings": [
        {
            "scaleResolutionDownBy": 4,
            "maxBitrate": 128000,
            "maxFramerate": 20
        },
        {
            "scaleResolutionDownBy": 2,
            "maxBitrate": 500000,
            "maxFramerate": 20
        },
        {
            "scaleResolutionDownBy": 1,
            "maxBitrate": 700000,
            "maxFramerate": 20
        }
    ],
    "screenSimulcastEncodings": [
        {
            "scaleResolutionDownBy": 1,
            "dtx": true,
            "maxBitrate": 756000
        },
        {
            "scaleResolutionDownBy": 1,
            "dtx": true,
            "maxBitrate": 1500000
        }
    ]
}